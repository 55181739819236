// @flow
import React from 'react';
import { Calendar, Callout, ContextualMenuItemType, DayOfWeek,
            DefaultButton, DirectionalHint, SpinButton } from 'office-ui-fabric-react';

const DayPickerStrings = {
	months: [
		'January',
		'February',
		'March',
		'April',
		'May',
		'June',
		'July',
		'August',
		'September',
		'October',
		'November',
		'December'
	],

	shortMonths: [
		'Jan',
		'Feb',
		'Mar',
		'Apr',
		'May',
		'Jun',
		'Jul',
		'Aug',
		'Sep',
		'Oct',
		'Nov',
		'Dec'
	],

	days: [
		'Sunday',
		'Monday',
		'Tuesday',
		'Wednesday',
		'Thursday',
		'Friday',
		'Saturday'
	],

	shortDays: [
		'S',
		'M',
		'T',
		'W',
		'T',
		'F',
		'S'
	],

	goToToday: 'Go to today'
};

type DateTimePickerProps = {
	onChanged: (date: ?Date) => void,
	textNotFinalizing: ?string,
	date: ?Date,
}

type DateTimePickerState = {
	finalizeDate: ?Date,
	showCalendar: boolean,
	showTimePicker: boolean,
}

export class DateTimePicker extends React.Component<DateTimePickerProps, DateTimePickerState> {
	_InputHour: ?SpinButton;
	_InputMinute: ?SpinButton;
	_calendarButtonElement: ?HTMLDivElement;
    state = {
		finalizeDate: this.props.date,
		showCalendar: false,
		showTimePicker: false,
    }
	onDismissCalendar = () => {
		this.setState({ showCalendar: false });
	}
	onSelectFinalizeDate = (date: Date) => {
		if(this.state.finalizeDate) {
			let d = this.state.finalizeDate;
			date.setHours(d.getHours());
			date.setMinutes(d.getMinutes());
		}
		this.setState({ showCalendar: false });
		this.setDate(date);
	}
	onDismissTimePicker = () => {
		var date = this.state.finalizeDate ? new Date(this.state.finalizeDate) : new Date();
		if(this._InputHour) {
			date.setHours(this._InputHour.state.value);
			if(this._InputMinute) {
				date.setMinutes(this._InputMinute.state.value);
			}
		}
		date.setSeconds(0);
		this.setState({ showTimePicker: false });
		this.setDate(date);
	}
    setDate(date: ?Date) {
		this.setState({ finalizeDate: date });
        this.props.onChanged(date);
    }
	render() {
        const { finalizeDate, showCalendar, showTimePicker } = this.state;

        let calendar = null;
        if(showCalendar) {
            calendar = (
                <Callout isBeakVisible={ false } className='ms-DatePicker-callout' gapSpace={ 0 } doNotLayer={ false }
                    target={ this._calendarButtonElement } directionalHint={ DirectionalHint.bottomLeftEdge }
                    onDismiss={ this.onDismissCalendar } setInitialFocus={ false }
                >
                    <Calendar onSelectDate={ this.onSelectFinalizeDate } onDismiss={ this.onDismissCalendar }
                        autoNavigateOnSelection={ true } showGoToToday={ true } value={ finalizeDate }
                        firstDayOfWeek={ DayOfWeek.Monday } strings={ DayPickerStrings }
                        highlightCurrentMonth={ true } minDate={ new Date() }
                    />
                </Callout>
            );
        }

        let timePicker = null;
        if(showTimePicker) {
            timePicker = (
                <Callout isBeakVisible={ false } className='ms-DatePicker-callout' gapSpace={ 0 } doNotLayer={ false }
                    target={ this._calendarButtonElement } directionalHint={ DirectionalHint.bottomLeftEdge }
                    onDismiss={ this.onDismissTimePicker } setInitialFocus={ false }
                >
                    <SpinButton componentRef={ ref => { this._InputHour = ref; } }
                        defaultValue={ finalizeDate ? finalizeDate.getHours() : 0 }
                        label={ 'Hour:' } min={ 0 } max={ 23 } step={ 1 }/>
                    <SpinButton componentRef={ ref => { this._InputMinute = ref; } }
                        defaultValue={ finalizeDate ? finalizeDate.getMinutes() : 0 }
                        label={ 'Minute:' } min={ 0 } max={ 59 } step={ 1 }/>
                </Callout>
            );
        }

		const notFinalizing = this.props.textNotFinalizing ? this.props.textNotFinalizing : 'Not finalizing';
		const finalizeText = finalizeDate ? `Finalize on ${finalizeDate.toLocaleString()}` : notFinalizing;

        return (
            <>
                <div ref={ (calendarBtn) => this._calendarButtonElement = calendarBtn }>
                    <DefaultButton
                        text={ finalizeText }
                        split={ true }
                        onClick={ () => { } }
                        iconProps={ { iconName: 'EventDate' } }
                        menuProps={ {
                            items: [{
                                key: 'date',
                                name: 'Select date',
                                iconProps: { iconName: 'EventDate' },
                                onClick: () => { this.setState({ showCalendar: !showCalendar }); }
                            },{
                                key: 'time',
                                name: 'Select time',
                                iconProps: { iconName: 'DateTime2' },
                                onClick: () => { this.setState({ showTimePicker: !showTimePicker }); }
                            },{
                                key: 'divider',
                                itemType: ContextualMenuItemType.Divider
                            },{
                                key: 'reset',
                                name: 'Reset',
                                iconProps: { iconName: 'Delete' },
                                style: {
                                    color: 'red'
                                },
                                onClick: () => { this.setDate(null); }
                            }]
                        } }
                    />
                </div>
                { calendar }
                { timePicker }
            </>
        );
    }
}
