// @flow
import React from 'react';
import { Persona, IconButton, Label } from 'office-ui-fabric-react';
import PanelNotAuthenticated from './PanelNotAuthenticated';
import PanelAuthenticated from './PanelAuthenticated';
import PanelO365Apps from './PanelO365Apps';
import API from './API.js';
import './NavBar.css';

type NavBarProps = {
	authenticated: boolean,
	title: string,
	name: string,
	image: string,
	tenant: ?string,
	version: string,
};
type NavBarState = {
	hasProjects: boolean,
	serverVersion: string,
};

class NavBar extends React.Component<NavBarProps, NavBarState> {
	state = {
		hasProjects: false,
		serverVersion: ''
	};
	leftPanel: any;
	rightPanel: any;
	onShowLeftPanel = () => {
		if(this.leftPanel) {
			this.leftPanel.Show();
		}
	}
	onShowRightPanel = () => {
		if(this.rightPanel) {
			this.rightPanel.Show();
		}
	}
	componentDidMount() {
		API.config().then((response) => {
			this.setState({
				serverVersion: response.data.ServerVersion,
				hasProjects : response.data.HasProjects
			});
		}).catch(() => { });
	}
	render() {
		const leftPanel = (this.props.authenticated === true)
			? <PanelO365Apps tenant={this.props.tenant} ref={ c => { this.leftPanel = c; }}/>
			: null;
		const rightPanel = (this.props.authenticated === undefined)
			? null
			: this.props.authenticated
				? <PanelAuthenticated ref={ c => { this.rightPanel = c; }} hasProjects={ this.state.hasProjects === true } name={this.props.name}
										image={this.props.image} frontendVersion={this.props.version} serverVersion={this.state.serverVersion}/>
				: <PanelNotAuthenticated version={this.props.version} />;
    
		return (
			<div className="NavBar ms-Grid">
				{leftPanel}
				{rightPanel}

				<div className="ms-Grid-row">
					<div className="ms-Grid-col ms-sm8 ms-md8 ms-lg8">
						<div className="ms-font-l ms-fontWeight-semibold">
							<Label>
								<IconButton className="NavBarIcon" iconProps={ { iconName: 'WaffleOffice365' } } onClick={ this.onShowLeftPanel }/>
							</Label>
						</div>
						<div className="NavBarTitle ms-font-l ms-fontWeight-semibold">
							<span>{this.props.title}</span>
						</div>
					</div>
					<div className="ms-Grid-col ms-sm4 ms-md4 ms-lg4">
						<div className="NavBarPersonaIcon">
							<Persona text={this.props.name} imageUrl={this.props.image} onClick={ this.onShowRightPanel }
								onRenderPrimaryText={ item => { return (<Label style={ { color: 'white' } }>{item.text}</Label>); } }
							/>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default NavBar;
