import React from 'react';
import {
  ComboBox,
  Dialog,
  DialogType,
  DialogFooter,
} from 'office-ui-fabric-react';
import { PrimaryButton, Spinner, SpinnerSize } from 'office-ui-fabric-react';
import { TextField } from 'office-ui-fabric-react';
import { MessageBar, MessageBarType } from 'office-ui-fabric-react';
import API from './API.js';

export enum Permissions {
  NoPermission = 0,
  User,
  UserElevated,
  SelfAdmin,
  ViewOnlyAdmin,
  Admin,
  SuperAdmin,
}

type AddTokenDlgProps = {
  onTokenAdded: () => void;
};

type AddTokenDlgState = {
  hidden: boolean;
  error?: string;
  name: string;
  permission: number;
  expires: number;
  token: string | null;
  inProgress: boolean;
};

const permissionOptions = [
  { key: Permissions.ViewOnlyAdmin, text: 'View only' },
  { key: Permissions.Admin, text: 'Admin' },
  { key: Permissions.SuperAdmin, text: 'Superadmin' },
];

const expiryOptions = [
  { key: 30, text: '30 days' },
  { key: 90, text: '90 days' },
  { key: 180, text: '180 days' },
  { key: 365, text: '1 year' },
  { key: 730, text: '2 years' },
];

export class AddTokenDlg extends React.Component<
  AddTokenDlgProps,
  AddTokenDlgState
> {
  state = {
    hidden: true,
    error: undefined,
    name: '',
    permission: permissionOptions[0].key,
    expires: expiryOptions[0].key,
    token: null,
    inProgress: false,
  };
  Show = () => {
    this.setState({ hidden: false });
  };
  onDismiss = () => {
    this.setState({
      hidden: true,
      error: undefined,
      name: '',
      permission: permissionOptions[0].key,
      expires: expiryOptions[0].key,
      token: null,
      inProgress: false,
    });
  };
  onNameChanged = (ev: any, value: string) => {
    this.setState({ name: value });
  };
  onPermissionChanged = (ev: any, option: any) => {
    this.setState({ permission: option.key });
  };
  onExpiryChanged = (ev: any, option: any) => {
    this.setState({ expires: option.key });
  };
  onSave = () => {
    this.setState({ inProgress: true });

    API.generateToken(this.state.name, this.state.permission, this.state.expires)
      .then((response) => {
        this.setState({
          inProgress: false,
          error: null,
          token: response.data.token,
        });
      })
      .catch((error) => {
        this.setState({ inProgress: false, error: API.errorMessage(error) });
      });
  };
  onClose = () => {
    this.onDismiss();
    if (this.props.onTokenAdded) {
      this.props.onTokenAdded();
    }
  };
  render() {
    const { hidden, error, name, permission, expires, token, inProgress } = this.state;
    const contentProps = {
      type: DialogType.largeHeader,
      title: 'Create Token',
    };
    const modalProps = {
      isBlocking: false,
      containerClassName: 'ms-dialogMainOverride',
    };
    return (
      <Dialog
        hidden={hidden}
        onDismiss={this.onDismiss}
        dialogContentProps={contentProps}
        modalProps={modalProps}
      >
        {error ? (
          <MessageBar messageBarType={MessageBarType.error} isMultiline={true}>
            {error}
          </MessageBar>
        ) : null}
        <TextField
          label='Name'
          required={true}
          value={name}
          disabled={!!token}
          onChange={this.onNameChanged}
        />
        <ComboBox
          label='Permission'
          disabled={!!token}
          options={permissionOptions}
          selectedKey={permission}
          onChange={this.onPermissionChanged}
        />
        <ComboBox
          label='Expires in'
          disabled={!!token}
          options={expiryOptions}
          selectedKey={expires}
          onChange={this.onExpiryChanged}
        />
        {token && (
          <TextField
            label='Token'
            description='Please copy the token before closing this dialog. It is not possible to reveal the token afterwards.'
            multiline
            autoAdjustHeight
            value={token}
            readOnly={true}
          />
        )}
        <DialogFooter>
          {inProgress && (
            <Spinner
              label='Creating token ...'
              ariaLive='assertive'
              labelPosition='right'
              size={SpinnerSize.xSmall}
            />
          )}
          {!inProgress && (
            <PrimaryButton
              onClick={token ? this.onClose : this.onSave}
              text={token ? 'Close' : 'Save'}
            />
          )}
        </DialogFooter>
      </Dialog>
    );
  }
}
