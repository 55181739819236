// @flow
import React from 'react';
import {Nav} from 'office-ui-fabric-react';
import { withRouter } from 'react-router-dom';
import type { RouterHistory } from 'react-router-dom';
import { Permission } from './API';

const NavGroups = [{
	links: [{
		name: 'Binaries',
		key: '/binaries',
		icon: 'FileASPX',
		url: '/binaries'
	}, {
		name: 'Server Configuration',
		key: '/config',
		icon: 'Edit',
		url: '/config'
	}, {
		name: 'Modules',
		key: '/modules',
		url: '/modules',
		links: [{
			name: 'Inventory',
			key: '/modules/inventory',
			icon: 'HardDriveGroup',
			url: '/modules/inventory'
		}, {
			name: 'File',
			key: '/modules/file',
			icon: 'OpenFile',
			url: '/modules/file'
		}, {
			name: 'PST',
			key: '/modules/pst',
			icon: 'Mail',
			url: '/modules/pst'
		}, {
			name: 'Custom',
			key: '/modules/custom',
			icon: 'CustomList',
			url: '/modules/custom'
		}, {
			name: 'Server',
			key: '/modules/server',
			icon: 'Server',
			url: '/modules/server'
		}, {
			name: 'Upload Agent',
			key: '/modules/upload_agent',
			icon: 'CloudUpload',
			url: '/modules/upload_agent'
		}],
		isExpanded: true
	}, {
		name: 'Groups',
		key: '/groups',
		icon: 'EngineeringGroup',
		url: '/groups'
	}, {
		name: 'Clients',
		key: '/clients',
		icon: 'Group',
		url: '/clients'
	}, {
		name: 'Reporting',
		key: '/reporting',
		icon: 'AnalyticsReport',
		url: '/reporting'
	}, {
		name: 'Projects',
		key: '/projects',
		icon: 'ProjectCollection',
		url: '/projects'
	}, {
		name: 'Help',
		key: '/help',
		icon: 'Info',
		url: '/help'
	}]
}];
const NavGroupsSelfAdmin = [{
	links: [{
		name: 'Clients',
		key: '/clients',
		icon: 'Group',
		url: '/clients'
	}, {
		name: 'Help',
		key: '/help',
		icon: 'Info',
		url: '/help'
	}]
}];

type SidebarMenuProps = {
	history: RouterHistory,
	permission: number,
};

class SidebarMenu extends React.Component<SidebarMenuProps, {}> {
	onLinkClick = (event, element) => { 
		if(event) {
			if(event.ctrlKey || event.shiftKey || event.metaKey) {
				// do normal processing if control of shift key is clicked
				return;
			}
			event.nativeEvent.preventDefault();
		}
		if(element && element.url) {
			this.props.history.push(element.url);
		}
	}
	render() {
		const { permission } = this.props;

		let menu = null;
		if( permission === Permission.SelfAdmin ) {
			menu = NavGroupsSelfAdmin;
		} else {
			menu = NavGroups;
		}

		return (
			<div className='SidebarMenu'>
				<Nav groups={menu} onLinkClick={this.onLinkClick} />
			</div>
		);
	}
}

export default withRouter(SidebarMenu);
