import * as React from 'react';
//import { Link } from 'office-ui-fabric-react/lib/Link';
import {
  DetailsList,
  IGroup,
  IColumn,
  IGroupDividerProps,
  IDetailsGroupRenderProps,
  CheckboxVisibility,
} from 'office-ui-fabric-react/lib/DetailsList';

import { getTheme, mergeStyleSets } from 'office-ui-fabric-react/lib/Styling';
import { FontIcon } from 'office-ui-fabric-react/lib/Icon';
import { IconButton } from 'office-ui-fabric-react';
import { ProgressIndicator } from 'office-ui-fabric-react';

const ROW_HEIGHT: number = 42; // from DEFAULT_ROW_HEIGHTS in DetailsRow.styles.ts
const GROUP_HEADER_AND_FOOTER_SPACING: number = 8;
const GROUP_HEADER_AND_FOOTER_BORDER_WIDTH: number = 1;
const GROUP_HEADER_HEIGHT: number = 95;

interface ICatalogItem {
  key: string;
  path: string;
};

const theme = getTheme();
const classNames = mergeStyleSets({
  headerAndFooter: {
    borderTop: `${GROUP_HEADER_AND_FOOTER_BORDER_WIDTH}px solid ${theme.palette.neutralQuaternary}`,
    borderBottom: `${GROUP_HEADER_AND_FOOTER_BORDER_WIDTH}px solid ${theme.palette.neutralQuaternary}`,
    borderLeft: `${GROUP_HEADER_AND_FOOTER_BORDER_WIDTH}px solid ${theme.palette.neutralQuaternary}`,
    borderRight: `${GROUP_HEADER_AND_FOOTER_BORDER_WIDTH}px solid ${theme.palette.neutralQuaternary}`,
    padding: GROUP_HEADER_AND_FOOTER_SPACING,
    margin: `${GROUP_HEADER_AND_FOOTER_SPACING}px 0`,
    // Overlay the sizer bars
    position: 'relative',
    zIndex: 100,
    overflow: 'auto'
  },
  headerTitle: [
    theme.fonts.xLarge,
    {
      padding: '4px 0'
    }
  ],
  headerLinkSet: {
    margin: '4px -8px'
  },
  headerLink: {
    margin: '0 8px'
  },
  headerIcon: {
    fontSize: 50,
    height: 50,
    width: 50,
    margin: '0 25px',
    color: '#0078d7',
  },
  headerCol: {
    float: 'left',
  },
  statusCol: {
    float: 'left',
    width: 75
  },
  headerColPadding: {
    float: 'left',
    paddingLeft: '100px',
  },
  headerColRight: {
    float: 'right'
  },
  headerFooter: {
    margin: '0 25px',
    clear: 'both'
  },
});

interface CatalogsProps {
  catalogs: any,
  data: any,
  onEdit: (idx: number, item: any) => void,
  onDelete: (idx: number, item: any) => void,
};

export class Catalogs extends React.Component<CatalogsProps, {}> {
  private _progress: {}

  constructor(props: CatalogsProps) {
    super(props);

    this._progress = {};

    if (props.data && props.data.catalogs) {
      for (const key of Object.keys(props.data.catalogs)) {
        const cat = props.data.catalogs[key];
        if (cat.statistics && cat.destination) {
          const cat = props.data.catalogs[key];
          let hash = 0;
          hash = this._hashVal(hash, cat.name || '');
          hash = this._hashVal(hash, cat.destination.type || '');
          hash = this._hashVal(hash, cat.destination.rootPath || '');
          hash = this._hashVal(hash, cat.destination.oneDriveUpn || '');
//          hash = this._hashVal(hash, cat.destination.sharepointUrl || '');
  
          this._progress[hash] = props.data.catalogs[key].statistics;
        }
      }    
    }
  }

  private _columns: IColumn[] = [
    {
      key: 'info',
      fieldName: 'info',
      name: 'Info',
      minWidth: 100,
      maxWidth: 100,
    } as IColumn,
    {
      key: 'path',
      fieldName: 'path',
      name: 'Path',
    } as IColumn
  ];

  public render(): JSX.Element {
    let items = [];
    let groups = [];

    for (var i = 0; i < this.props.catalogs.length; ++i) {
      let count = 0;
      let start = items.length;

      if (this.props.catalogs[i].source.type === "local") {
        for (const key of Object.keys(this.props.catalogs[i].source.paths)) {
          items.push({
            info: ' ',
            path: this.props.catalogs[i].source.paths[key].path,
          });
          ++count;
        }

        if (!count) {
          items.push({
            info: 'no source paths',
            path: ' ',
          });
          ++count;
        }
      } else if (this.props.catalogs[i].source.type === "unc") {
        count = 1;
        
        items.push({
          info: ' ',
          path: this.props.catalogs[i].source.path,    
        });
      }

      groups.push({
        count: count,
        key: i.toString(),
        data: i,
        name: this.props.catalogs[i].name,
        startIndex: start,
        level: 0,
        isCollapsed: true,
        children: [],
      });  
    }
    
    return (
      <DetailsList
        items={items}
        compact={true}
        columns={this._columns}
        groups={groups}
        groupProps={{
          onRenderHeader: this._onRenderGroupHeader
        }}
        getGroupHeight={this._getGroupHeight}
        ariaLabelForSelectionColumn="Toggle selection"
        checkboxVisibility={CheckboxVisibility.hidden}
        disableSelectionZone={true}
        isHeaderVisible={false}
      />
    );
  }

  private _onRenderGroupHeader: IDetailsGroupRenderProps['onRenderHeader'] = props => {
    if (props) {
      let icon = '';
      let url = null;
      let chevron = null;
      let finalized = '';
      let footer = null;

      const cat = this.props.catalogs[props!.group!.data];

      if (cat.destination.type === "serverSharepoint") {
        icon = 'TeamsLogo';
        var groupId = /groupId=(.+)&/g.exec(cat.destination.sharepointUrl);
        url = <div>{`Team: ${groupId ? groupId[1] : cat.destination.sharepointUrl}`}</div>;
      } else if (cat.destination.type === "serverGraph") {
        icon = 'OneDrive';
        url = <div>{`OneDrive UPN: ${cat.destination.oneDriveUpn}`}</div>;
      } else if (cat.destination.type === "serverBlob") {
        icon = 'AzureLogo';
      }

      let hash = 0;
      if (icon) {
        hash = this._hashVal(hash, cat.name || '');
        hash = this._hashVal(hash, cat.destination.type || '');
        hash = this._hashVal(hash, cat.destination.rootPath || '');
        hash = this._hashVal(hash, cat.destination.oneDriveUpn || '');
//        hash = this._hashVal(hash, cat.destination.sharepointUrl || '');
      }

      if (hash && this._progress[hash]) {
        const synced = this._progress[hash].sizeSynced;
        const total = this._progress[hash].sizeTotal;
        const desc = `${(synced/(1000000)).toFixed()} of ${(total/(1000000)).toFixed()} MByte complete.`;
        let percentComplete = (total > 0) ? (synced / total) : 0;
        let label = '';
        if (total === 0) {
          label = 'Copy progress: Nothing to copy';
        } else if (synced === total) {
          label = 'Copy progress: Complete';
          percentComplete = 1.0;
        } else {
          label = `Copy progress: ${percentComplete.toFixed()}%`;
        }

        footer = <ProgressIndicator label={label} description={ desc } percentComplete={ percentComplete }/>
      } else {
        footer = <span>Copy progress: Not yet started</span>;
      }

      if (cat.finalize) {
        finalized = 'Finalized';
      }

      if (props.group!.isCollapsed) {
        chevron = <IconButton onClick={ this._onToggleCollapse(props)} iconProps={{ iconName: 'ChevronRight' }} title="Collapse" ariaLabel="Collapse"/>
      } else {
        chevron = <IconButton onClick={ this._onToggleCollapse(props)} iconProps={{ iconName: 'ChevronDown' }} title="Collapse" ariaLabel="Collapse"/>
      }

      return (
        <div className={classNames.headerAndFooter}>
          <div className={classNames.headerCol}>
            <FontIcon className={classNames.headerIcon} iconName={icon}  />
          </div>
          <div className={classNames.headerCol}>
            <div className={classNames.headerTitle}>{props.group!.name}</div>
            <div className={classNames.statusCol}>{finalized}&nbsp;</div>
            <div className={classNames.headerColPadding}>
             {url} 
              <div>{`Target path: ${cat.destination.rootPath}`}</div>
            </div>
          </div>
          <div className={classNames.headerColRight}>
            <div>
              {chevron}
              <IconButton onClick={ this._onEdit(props)} iconProps={{ iconName: 'Edit' }} title="Edit" ariaLabel="Edit"/>
              <IconButton onClick={ this._onDelete(props)} iconProps={{ iconName: 'Delete', style: {color: 'red'} }} title="Delete" ariaLabel="Delete"/>
            </div>
          </div>
          <div className={classNames.headerFooter}>
            {footer}
          </div>
        </div>
      );
    }

    return null;
  };

  private _getGroupTotalRowHeight = (group: IGroup): number => {
    return group.isCollapsed ? 0 : ROW_HEIGHT * group.count;
  };

  private _getGroupHeight = (group: IGroup, _groupIndex: number): number => {
    return GROUP_HEADER_HEIGHT + this._getGroupTotalRowHeight(group);
  };

  private _onToggleCollapse(props: IGroupDividerProps): () => void {
    return () => {
      props!.onToggleCollapse!(props!.group!);
    };
  }
  private _onEdit(props: IGroupDividerProps): () => void {
    return () => {
      this.props.onEdit!(props!.group!.data, this.props.catalogs[props!.group!.data]);
    };
  }
  private _onDelete(props: IGroupDividerProps): () => void {
    return () => {
      this.props.onDelete!(props!.group!.data, this.props.catalogs[props!.group!.data]);
    };
  }
  private _hashVal = (hash, str): number => {
    for (var i = 0; i < str.length; i++) {
        var char = str.charCodeAt(i);
        hash = ((hash<<5)-hash)+char;
        hash = hash & hash; // Convert to 32bit integer
    }
    return hash;
  }
}
