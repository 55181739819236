// @flow
import React from 'react';
import { Dialog, DialogType, DialogFooter } from 'office-ui-fabric-react';
import { DefaultButton, PrimaryButton } from 'office-ui-fabric-react';

type DeleteSelectedItemsDlgProps = {
	onDelete: () => void
};

type DeleteSelectedItemsDlgState = {
	hidden: boolean
};

export class DeleteSelectedItemsDlg extends React.Component<DeleteSelectedItemsDlgProps, DeleteSelectedItemsDlgState> {
	state = {
		hidden: true
	};
	Show = () => {
		this.setState({ hidden: false });
	}
	Dismiss = () => {
		this.setState({ hidden: true });
	}
	onDismiss = () => {
		this.Dismiss();
	}
	onDelete = () => {
        if(this.props.onDelete) {
            this.props.onDelete();
        }
    }
	render() {
		const { hidden } = this.state;
		const contentProps = {
			type: DialogType.normal,
            title: 'Delete Selected Items',
            subText: 'Do you really want to delete the selected items?'
		};
		const modalProps = {
			isBlocking: true,
			containerClassName: 'ms-dialogMainOverride'
		};
		return (
			<Dialog
				hidden={ hidden }
				onDismiss={ this.Dismiss }
				dialogContentProps={ contentProps }
				modalProps={ modalProps }
			>
				<DialogFooter>
                    <DefaultButton onClick={ this.onDismiss } text='No' />
					<PrimaryButton onClick={ this.onDelete } text='Yes' />
				</DialogFooter>
			</Dialog>
		);
	}
}
