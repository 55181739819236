// @flow
import React from 'react';
import { Helmet } from 'react-helmet';
import { ProgressIndicator, CommandBar } from 'office-ui-fabric-react';
import { ClientList } from './ClientList.js';
import { AssignGroupDlg } from './AssignGroupDlg';
import { DeleteSelectedItemsDlg } from './DeleteSelectedItemsDlg';
import API from './API.js';

type ClientsState = {
	items: Array<any>,
	selectedItems: Array<any>,
	loading: boolean,
	isModalSelection: boolean
};

class Clients extends React.Component<{}, ClientsState> {
	assignGroupDlg: ?AssignGroupDlg;
	deleteSelectedItemsDlg: ?DeleteSelectedItemsDlg;
	state = {
		items: [],
		selectedItems: [],
		loading: true,
		isModalSelection: false
	};
	loadItems = () => {
		this.setState({
			loading: true
		});
		API.clients().then((response) => {
			this.setState({
				loading: false,
				items: response.data
			});
		}).catch((error) => {
			this.setState({ loading: false });
		});
	}
	onSelectionChanged = (selection) => {
		const groups = selection.map(it => it.ObjectID);
		this.setState({ selectedItems: groups });
	}
	onAssignedSelected = () => {
		if(this.assignGroupDlg) {
			this.assignGroupDlg.Show();
		}
	}
	onGroupAssigned = (groupId: string) => {
		const items = this.state.selectedItems.slice();
		if(this.assignGroupDlg) {
			this.assignGroupDlg.Dismiss();
		}
		this.setState({
			loading: true,
			isModalSelection: false,
			selectedItems: []
		});
		API.assignClientsToGroup(groupId, items).then(() => {
			this.setState({ loading: false });
		}).catch((error) => { });
	}
	onDeleteSelected = () => {
		if(this.deleteSelectedItemsDlg) {
			this.deleteSelectedItemsDlg.Show();
		}
	}
	onDeleteSelectedItems = () => {
		const items = this.state.selectedItems.slice();
		if(this.deleteSelectedItemsDlg) {
			this.deleteSelectedItemsDlg.Dismiss();
		}
		this.setState({
			loading: true,
			isModalSelection: false,
			selectedItems: []
		});
		API.removeClients(items).then(() => {
			this.loadItems();
		}).catch((error) => { });
	}
	render() {
		const { loading, items, isModalSelection } = this.state;
		let commandBarItems = [{
			key: 'select',
			disabled: loading,
			name: isModalSelection ? 'Stop selection' : 'Start selection',
			className: 'ms-CommandBarItem',
			iconProps: { iconName: 'SelectAll' },
			onClick: () => { this.setState({ isModalSelection: !this.state.isModalSelection }) }
		}];
		if(isModalSelection) {
			commandBarItems.push({
				key: 'assign-group',
				name: 'Assign to group',
				disabled: loading || this.state.selectedItems.length === 0,
				className: 'ms-CommandBarItem',
				iconProps: { iconName: 'AddGroup' },
				onClick: this.onAssignedSelected
			});
		}
		const commandBarFarItems = isModalSelection ? [{
			key: 'delete',
			name: 'Delete',
			disabled: loading || this.state.selectedItems.length === 0,
			className: 'ms-CommandBarItem',
			iconProps: {
				iconName: 'Delete',
				style: {
					color: 'red'
				}
			},
			onClick: this.onDeleteSelected
		}] : [];

		return (
			<div className="ms-Grid">
				<Helmet>
					<title>realmigrator - Clients</title>
				</Helmet>        
				<div className="ms-Grid-row">
					<div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
						<h2>Clients {loading ? '' : `(Total ${items.length})`}</h2>
					</div>
				</div>
				<div className="ms-Grid-row">
					<div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
						<CommandBar isSearchBoxVisible={ false } items={ commandBarItems } farItems={ commandBarFarItems }/>
						<AssignGroupDlg
							ref={ c => { this.assignGroupDlg = c; }}
							onFinish={this.onGroupAssigned}
						/>
						<DeleteSelectedItemsDlg
							ref={ c => { this.deleteSelectedItemsDlg = c; }}
							onDelete={ this.onDeleteSelectedItems }
						/>
					</div>
				</div>
				<div className="ms-Grid-row">
					<div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
						{loading ? <ProgressIndicator label='Loading...'/> : null}
					</div>
				</div>
				<div className="ms-Grid-row">
					<div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
						<ClientList items={items} isModalSelection={isModalSelection} onSelectionChanged={this.onSelectionChanged}/>
					</div>
				</div>
			</div>
		);
	}
	componentDidMount() {
		this.loadItems();
	}
}

export default Clients;
