// @flow
import React from 'react';
import { Dialog, DialogType, DialogFooter, MessageBar, MessageBarType, PrimaryButton } from 'office-ui-fabric-react';
import { GroupSelector } from './GroupSelector';

type AssignGroupDlgProps = {
	onFinish: (groupId: string) => void
};

type AssignGroupDlgState = {
	hidden: boolean,
	error: ?string,
	groupId: string,
};

export class AssignGroupDlg extends React.Component<AssignGroupDlgProps, AssignGroupDlgState> {
	state = {
		hidden: true,
		error: undefined,
		groupId: ''
	};
	Show = () => {
		this.setState({ hidden: false });
	}
	Dismiss = () => {
		this.setState({
			groupId: '',
			error: null,
			hidden: true
		});
	}
	onGroupIdChanged = (value: string) => {
		this.setState({ groupId: value });
	}
	onDismiss = () => {
		this.setState({ hidden: true });
	}
	onAssign = () => {
		const { groupId } = this.state;
		if(!groupId) {
			this.setState({ error: 'Please select a group' });
			return;
		}

		if(this.props.onFinish) {
			this.props.onFinish(groupId);
		}
    }
	render() {
		const { hidden, error } = this.state;
		const contentProps = {
			type: DialogType.largeHeader,
			title: 'Assign to group'
		};
		const modalProps = {
			isBlocking: false,
			containerClassName: 'ms-dialogMainOverride'
		};
		return (
			<Dialog
				hidden={ hidden }
				onDismiss={ this.Dismiss }
				dialogContentProps={ contentProps }
				modalProps={ modalProps }
			>
				{ error ? <MessageBar messageBarType={MessageBarType.error} isMultiline={true}>{error}</MessageBar> : null }
				<GroupSelector
							defaultSelectedKey="*"
							includeAllClientsGroup={false}
							includeUnassignedClientsGroup={false}
							onSelectedGroupChanged={this.onGroupIdChanged}
				/>
				<DialogFooter>
					<PrimaryButton onClick={ this.onAssign } text='Assign' />
				</DialogFooter>
			</Dialog>
		);
	}
}
