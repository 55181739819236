// @flow
import React from 'react';
import { Dialog, DialogType, DialogFooter } from 'office-ui-fabric-react';
import { PrimaryButton } from 'office-ui-fabric-react';
import { ModuleOption, ComboBoxModuleOption } from './ComboBoxModuleOption';
import API from './API.js';

type SetGroupConfigBulkDlgProps = {
	onFinish: (config: any) => void
};

type SetGroupConfigBulkDlgState = {
	hidden: boolean,
	moduleConfiguration: any,
	optionFileModule: string,
	optionInventoryModule: string,
	optionPSTModule: string,
	optionCustomModule: string,
};

export class SetGroupConfigBulkDlg extends React.Component<SetGroupConfigBulkDlgProps, SetGroupConfigBulkDlgState> {
	state = {
		hidden: true,
		moduleConfiguration: {
			inventory: true,
			file: true,
			pst: true
		},
		optionFileModule: ModuleOption.unselected,
		optionInventoryModule: ModuleOption.unselected,
		optionPSTModule: ModuleOption.unselected,
		optionCustomModule: ModuleOption.unselected,
	};
	Show = () => {
		this.setState({ hidden: false });
	}
	Dismiss = () => {
		this.setState({ hidden: true });
	}
	onDismiss = () => {
		this.Dismiss();
	}
	onFileModuleChanged = (option: string) => {
		this.setState({ optionFileModule: option });
	}
	onInventoryModuleChanged = (option: string) => {
		this.setState({ optionInventoryModule: option });
	}
	onPSTModuleChanged = (option: string) => {
		this.setState({ optionPSTModule: option });
	}
	onCustomModuleChanged = (option: string) => {
		this.setState({ optionCustomModule: option });
	}
	onFinish = () => {
        if(this.props.onFinish) {
			const { optionFileModule, optionInventoryModule, optionPSTModule, optionCustomModule } = this.state;
			const config = {
				file: (optionFileModule !== ModuleOption.unselected) ? optionFileModule : undefined,
				inventory: (optionInventoryModule !== ModuleOption.unselected) ? optionInventoryModule : undefined,
				pst: (optionPSTModule !== ModuleOption.unselected) ? optionPSTModule : undefined,
				custom: (optionCustomModule !== ModuleOption.unselected) ? optionCustomModule : undefined,
			};
			this.props.onFinish(config);
        }
    }
	componentDidMount() {
		API.getModuleConfiguration().then((response) => {
			this.setState({ moduleConfiguration: response.data });
		}).catch(() => {});
	}
	render() {
		const { hidden, moduleConfiguration, optionFileModule, optionInventoryModule, optionPSTModule, optionCustomModule } = this.state;
		const contentProps = {
			type: DialogType.normal,
            title: 'Set Group Configuration',
		};
		const modalProps = {
			isBlocking: false,
			containerClassName: 'ms-dialogMainOverride'
		};
		return (
			<Dialog
				hidden={ hidden }
				onDismiss={ this.onDismiss }
				dialogContentProps={ contentProps }
				modalProps={ modalProps }
			>
				<ComboBoxModuleOption
					label='Inventory Module'
					disabled={!moduleConfiguration.inventory}
					selectedKey={optionInventoryModule}
					onChange={this.onInventoryModuleChanged}
				/>
				<ComboBoxModuleOption
					label='File Module'
					disabled={!moduleConfiguration.file}
					selectedKey={optionFileModule}
					onChange={this.onFileModuleChanged}
				/>
				<ComboBoxModuleOption
					label='PST Module'
					disabled={!moduleConfiguration.pst}
					selectedKey={optionPSTModule}
					onChange={this.onPSTModuleChanged}
				/>
				<ComboBoxModuleOption
					label='Custom Module'
					disabled={!moduleConfiguration.custom}
					selectedKey={optionCustomModule}
					onChange={this.onCustomModuleChanged}
				/>
				<DialogFooter>
					<PrimaryButton onClick={ this.onFinish } text='Ok' />
				</DialogFooter>
			</Dialog>
		);
	}
}
