// @flow

export const SelectedPathType = {
	drive: 'drive',
	share: 'share',
    path: 'path'
};

export type CatalogItem = {
    dirs: Map<string, CatalogItem>,
    path: string,
    state: number,
}

export type TreeItem = {
    type: $Keys<typeof SelectedPathType>,
    id: number,
    text: string,
    expanded: bool,
    state: number,
    isLeaf: bool,
    children: Array<TreeItem>    
}

export type PathRules = {
    [string]: bool
};

export type SelectedPath = {
	path: string,
	source: $Keys<typeof SelectedPathType>,
	rules: PathRules
};

export const CHECKBOX_STATE = {
	HIDDEN: 0,
    CHECKED: 1,
    UNCHECKED: 2,
    BLOCK: 3,
    INHERIT: 4,
    UNDETERMINED: 5
};

export const TreeItemEmpty :TreeItem = {
    id: 1,
    text: 'No data available',
    expanded: false,
    state: CHECKBOX_STATE.HIDDEN,
    isLeaf: true,
    children: []
};

export function CreateTreeFromCatalog(ci: CatalogItem, type: string, text: string, id: number): TreeItem {
    const ti: TreeItem = {
        type: type,
        id: id++,
        text: text,
        expanded: true,
        isLeaf: false,
        state: CHECKBOX_STATE.UNCHECKED,
        children: [],
        path: ci.path
    };
    for(var key in ci.dirs) {
        ti.children.push(CreateTreeFromCatalog(ci.dirs[key], type, key, id++ * 10));
    }
    return ti;
}

export function GetPathRules(leaf: TreeItem, lastState: bool): PathRules {
    var obj: PathRules = {};

    for(var i in leaf.children) {
        const child = leaf.children[i];
        let nextState = lastState;

        if(child.state === CHECKBOX_STATE.CHECKED) {
            if(nextState === false) {
                obj[child.path.toLowerCase()] = true;
                nextState = true;
            }
        } else if(child.state === CHECKBOX_STATE.BLOCK) {
            if(nextState === true) {
                obj[child.path.toLowerCase()] = false;
                nextState = false;
            }
        }

        Object.assign(obj, GetPathRules(child, nextState));
    }

    return obj;
}

export function GetSelectedPaths(leaf: TreeItem): Array<SelectedPath> {
    let selectedPaths: Array<SelectedPath> = [];

    for(var i in leaf.children) {
        const child = leaf.children[i];

        if(child.state === CHECKBOX_STATE.CHECKED) {
            let sp: SelectedPath = {
                path: child.path.toLowerCase(),
                source: child.type,
                rules: GetPathRules(child, true)
            }
            selectedPaths.push(sp);
        } else {
            selectedPaths = selectedPaths.concat(GetSelectedPaths(child));
        }
    }

    return selectedPaths;
}

export function SetItemState(element: TreeItem, state: number) {
    if (state === CHECKBOX_STATE.UNDETERMINED) {
        if (element.state === CHECKBOX_STATE.UNCHECKED) element.state = CHECKBOX_STATE.UNDETERMINED;
        return;
    }

    if ((element.state !== CHECKBOX_STATE.UNCHECKED) && (element.state !== CHECKBOX_STATE.INHERIT)) {
        return;
    }

    element.state = state;

	if(element.children) {
		if(state === CHECKBOX_STATE.CHECKED) {
			state = CHECKBOX_STATE.INHERIT;
		} else if(state === CHECKBOX_STATE.BLOCK) {
			state = CHECKBOX_STATE.UNCHECKED;
		}
	
		if((state === CHECKBOX_STATE.UNCHECKED) || (state === CHECKBOX_STATE.INHERIT)) {
			for(var i = 0; i < element.children.length; ++i) {
				SetItemState(element.children[i], state);
			}
		}
	}
}

export function SelectRule(leaf: TreeItem, path: string, state: bool) {
    for (var j in leaf.children) {
        const child = leaf.children[j];
        const p = child.path.toLowerCase();

        if (p === path) {
            SetItemState(child, state ? CHECKBOX_STATE.CHECKED : CHECKBOX_STATE.BLOCK);
            return true;
        } else if (path.indexOf(p) === 0) {
            if (SelectRule(child, path, state)) return true;
        }
    }

    return false;
}

export function SelectPath(leaf: TreeItem, selectedPath: SelectedPath) {
    const p = leaf.path.toLowerCase();
    if(p === selectedPath.path) {
        SetItemState(leaf, CHECKBOX_STATE.CHECKED);
        for (var k in selectedPath.rules) {
            SelectRule(leaf, k, selectedPath.rules[k]);
        }
        return true;
    } else if(p in selectedPath.rules) {
        const state = (selectedPath.rules[p] === true) ? CHECKBOX_STATE.CHECKED : CHECKBOX_STATE.BLOCK;
        SetItemState(leaf, state);
        return true;
    } else if(selectedPath.path.indexOf(p) === 0) {
        for(var j in leaf.children) {
            const child = leaf.children[j];
            if (SelectPath(child, selectedPath)) {
                SetItemState(leaf, CHECKBOX_STATE.UNDETERMINED);
                return true;
            }
        }
    }

    return false;
}

export function SetSelectedPaths(leaf: TreeItem, selectedPaths: Array<SelectedPath>) {
    for(var i in selectedPaths) {
        const selectedPath = selectedPaths[i];
        if(selectedPath.source === leaf.type) {
            for(var j in leaf.children) {
                SelectPath(leaf.children[j], selectedPath);
            }
        }
    }
}
