// @flow
import React from 'react';
import { Dialog, DialogType, DialogFooter } from 'office-ui-fabric-react';
import { PrimaryButton } from 'office-ui-fabric-react';
import { TextField } from 'office-ui-fabric-react';
import { MessageBar, MessageBarType } from 'office-ui-fabric-react';
import API from './API.js';

type FeedbackDialogState = {
	hideDialog: boolean,
	subject: string,
	text: string,
	error: ?string,
};

class FeedbackDialog extends React.Component<{}, FeedbackDialogState> {
	state = {
		hideDialog: true,
		subject: '',
		text: '',
		error: null,
	};
	Show = () => {
		this.setState({ hideDialog: false });
	}
	closeDialog = () => {
		this.setState({ hideDialog: true });
	}
	onSubmit = () => {
		API.feedback(window.location.href, this.state.subject, this.state.text).then(() => {
			this.setState({ error: null });
			this.closeDialog();
		}).catch((error) => {
			this.setState({ error: API.errorMessage(error) });
		});
	}
	onSubjectChanged = (ev: any, value: string) => {
		this.setState({subject: value});
	}
	onTextChanged = (ev: any, value: string) => {
		this.setState({text: value});
	}
	componentDidMount() {
	}
	render() {
		return (
			<Dialog hidden={ this.state.hideDialog } onDismiss={ this.closeDialog }
				dialogContentProps={ {
					type: DialogType.largeHeader,
					title: 'Submit Feedback'
				} }
				modalProps={ {
					isBlocking: false,
					containerClassName: 'ms-dialogMainOverride'
				} }
			>
				{ this.state.error ? <MessageBar messageBarType={MessageBarType.error} isMultiline={true}>{this.state.error}</MessageBar> : null }
				<TextField label='Subject' required={ true } value={this.state.subject } onChange={this.onSubjectChanged}/>
				<TextField label='Text' required={ true } multiline autoAdjustHeight value={this.state.text } onChange={this.onTextChanged}/>
				<DialogFooter>
					<PrimaryButton onClick={ this.onSubmit } text='Submit' />
				</DialogFooter>
			</Dialog>
		);
	}
}

export default FeedbackDialog;
