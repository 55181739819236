// @flow
import * as React from 'react';
import { MessageBar } from 'office-ui-fabric-react';

type DropZoneProps = {
	onDrop: Function,
	size?: number,
	style?: any,
	children?: React.Node
};
type DropZoneState = {
	isDragActive: boolean
};

class DropZone extends React.Component<DropZoneProps, DropZoneState> {
	state = {
		isDragActive: false
	};
	fileInput: ?HTMLElement;
	onDragLeave = () => {
		this.setState({
			isDragActive: false
		});
	}
	onDragOver = (e: SyntheticDragEvent<HTMLInputElement>) => {
		e.preventDefault();
		e.dataTransfer.dropEffect = 'copy';

		this.setState({
			isDragActive: true
		});
	}
	onDrop = (e: SyntheticDragEvent<HTMLInputElement>) => {
		e.preventDefault();

		this.setState({
			isDragActive: false
		});

		var files;
		if (e.dataTransfer) {
			files = e.dataTransfer.files;
		} else if (e.target) {
			let t = (e.target: any);
			files = t.files;
		}

		if(files && files.length > 0) {
			this._createPreview(files[0]);
		}
	}
	onClick = () => {
		if(this.fileInput) {
			this.fileInput.click();
		}
	}
	_createPreview = (file: File) => {
		var self = this,
			reader = new FileReader();

		reader.onloadend = function (/*e*/) {
			if (self.props.onDrop) {
				self.props.onDrop(file, reader);
			}
		};

		reader.readAsArrayBuffer(file);
	}
	render() {

		var className = 'dropzone';
		if (this.state.isDragActive) {
			className += ' active';
		}

		var style = {
			borderStyle: this.state.isDragActive ? 'solid' : 'none'
		};

		return (
			<div style={style} className={className} onClick={this.onClick} onDragLeave={this.onDragLeave} onDragOver={this.onDragOver} onDrop={this.onDrop}>
				<input style={{display: 'none' }} type='file' multiple ref={ c => { this.fileInput = c; }} onChange={this.onDrop} />
				<MessageBar>{this.props.children}</MessageBar>
			</div>
		);
	}
}

export default DropZone;
