// @flow
import 'react-app-polyfill/ie9';
import 'office-ui-fabric-react/dist/css/fabric.min.css';
import './index.css';

import React from 'react';
import ReactDOM from 'react-dom';
import { initializeIcons } from '@uifabric/icons';
import { FluentCustomizations } from '@uifabric/fluent-theme';
import { Customizer } from 'office-ui-fabric-react';
//import registerServiceWorker from './registerServiceWorker';
import { unregister } from './registerServiceWorker';
import App from './App';

initializeIcons();

let element = document.getElementById('root');
if(element) {
    ReactDOM.render(
        <Customizer {...FluentCustomizations}>
            <App />
        </Customizer>,
        element);
}

//registerServiceWorker();
unregister();
