// @flow
import React from 'react';
import type { Match, RouterHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { CommandBar, TooltipHost, MessageBar, MessageBarType, ComboBox } from 'office-ui-fabric-react';
import { Controlled as CodeMirror } from 'react-codemirror2';
import { DateTime } from 'luxon';
import { withRouter } from 'react-router-dom';
import filesize from 'filesize';
import { Grid, Row, Column } from './Grid';
import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/neat.css';
import API from './API.js';
require('codemirror/mode/javascript/javascript');

const accessOptions = [
	{ key: '', text: 'Public' },
	{ key: 'auth-read', text: 'Authenticated read' },
	{ key: 'auth-write', text: 'Authenticated read/write' },
	{ key: 'admin-write-public-read', text: 'Public read/Admin write' },
	{ key: 'admin', text: 'Authenticated read/Admin write' }
];

type Message = {
	type: number,
	msg: string,
};

type BinaryProps = {
	match: Match,
	history: RouterHistory,
};
type BinaryState = {
	Etag: string,
	ContentMD5: string,
	ContentLength: number,
	ContentType: string,
	LastModified: string,
	Permission: string,
	Script: string,
	message: ?Message,
};

export class Binary extends React.Component<BinaryProps, BinaryState> {
	state = {
		Etag: '',
		ContentMD5: '',
		ContentLength: 0,
		ContentType: '',
		LastModified: '',
		Permission: '',
		Script: '',
		message: null
	};
	onDelete = () => {
		const binary = this.props.match.params.id || '';
		API.removeBinary(binary).then(() => {
			this.props.history.push('/binaries');
		}).catch((error) => {
			const m = {
				type: MessageBarType.error,
				msg: API.errorMessage(error)
			};
			this.setState({ message: m });
		});
	};
	onSave = () => {
		const binary = this.props.match.params.id || '';
		const permission = this.canModifyAccess() ? this.state.Permission : '';

		if(this.state.Script) {
			API.uploadBlob(binary, this.state.ContentType, this.state.Script).then(() => {
				return API.setBinary(binary, {
					Permission: permission
				});
			}).then(() => {
				return API.binary(binary);
			}).then((response) => {
				this.setState(response.data);
				const m = {
					type: MessageBarType.success,
					msg: `${binary} was saved successfully`
				};
				this.setState({ message: m });
			}).catch((error) => {
				const m = {
					type: MessageBarType.error,
					msg: API.errorMessage(error)
				};
				this.setState({ message: m });
			});
		} else {
			API.setBinary(binary, {
				Permission: permission
			}).then(() => {
				const m = {
					type: MessageBarType.success,
					msg: `${binary} was saved successfully`
				};
				this.setState({ message: m });
			}).catch((error) => {
				const m = {
					type: MessageBarType.error,
					msg: API.errorMessage(error)
				};
				this.setState({ message: m });
			});
		}
	}
	onScriptChanged = (editor: any, data: any, value: string) => {
		this.setState({
			Script: value,
			message: null
		});
	}
	onChangeAccess = (ev: any, option: any) => {
		this.setState({ Permission: option.key });
	}
	canModifyAccess() {
		const binary = this.props.match.params.id || '';
		const canModifyAccess = !binary.startsWith('config') && !binary.startsWith('custom')
								&& !binary.startsWith('file') && !binary.startsWith('inventory')
								&& !binary.startsWith('main') && !binary.startsWith('pst')
								&& !binary.startsWith('server');
		return canModifyAccess;
	}
	render() {
		const binary = this.props.match.params.id || '';
		const { ContentType, ContentMD5, Etag, ContentLength, LastModified, Permission, Script } = this.state;
		const CodeMirrorOptions = {
			theme: 'neat',
			lineNumbers: true,
			mode: 'javascript'
		};
		const canModifyAccess = this.canModifyAccess();

		return (
			<Grid>
				<Helmet>
					<title>{ `realmigrator - ${ binary }` }</title>
				</Helmet>        
				<Row>
					<Column>
						<h2>{binary}</h2>
					</Column>
				</Row>
				<Row>
					<Column>
						{ this.state.message ? <MessageBar messageBarType={this.state.message.type} isMultiline={false}>{this.state.message.msg}</MessageBar> : null }
						<CommandBar isSearchBoxVisible={false} items={ [{
							key: 'open',
							name: 'Open',
							className: 'ms-CommandBarItem',
							iconProps: { iconName: 'FileSymlink' },
							href: '/api/blobs/'+binary,
							target: '_new'
						},{
							key: 'save',
							name: 'Save',
							className: 'ms-CommandBarItem',
							iconProps: { iconName: 'Save' },
							disabled: !this.state.Script && !canModifyAccess,
							onClick: this.onSave
						}] } farItems={[{
							key: 'delete',
							name: 'Delete',
							className: 'ms-CommandBarItem',
							iconProps: {
								iconName: 'Delete',
								style: {
									color: 'red'
								}
							},
							onClick: this.onDelete
						}] }
						/>
					</Column>
				</Row>
				{canModifyAccess ?
				<Row>
					<Column width={2}>
            			Access
					</Column>
					<Column width={4}>
						<ComboBox selectedKey={Permission} onChange={this.onChangeAccess} allowFreeform={false} options={accessOptions}/>
					</Column>
					<Column width={6}>
					</Column>
				</Row> : null}
				<Row>
					<Column width={2}>
            			Last Modified
					</Column>
					<Column width={6}>
						<code>{DateTime.fromISO(LastModified).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS)}</code>
					</Column>
					<Column width={4}>
					</Column>
				</Row>
				<Row>
					<Column width={2}>
            			Content-Type
					</Column>
					<Column width={6}>
						<code>{ContentType}</code>
					</Column>
					<Column width={4}>
					</Column>
				</Row>
				<Row>
					<Column width={2}>
            			Content-Length
					</Column>
					<Column width={6}>
						<TooltipHost content={ContentLength + ' bytes'} calloutProps={{ gapSpace: 0 }} closeDelay={500}>
							<code>{filesize(ContentLength)}</code>
						</TooltipHost>
					</Column>
					<Column width={4}>
					</Column>
				</Row>
				<Row>
					<Column width={2}>
            			ETag
					</Column>
					<Column width={6}>
						<code>{Etag}</code>
					</Column>
					<Column width={4}>
					</Column>
				</Row>
				<Row>
					<Column width={2}>
            			MD5
					</Column>
					<Column width={6}>
						<code>{ContentMD5}</code>
					</Column>
					<Column width={4}>
					</Column>
				</Row>
				<Row>
					<Column>
						<div style={{ maxWidth: "1080px" }}>
							{Script ? <CodeMirror value={Script} options={CodeMirrorOptions} onBeforeChange={this.onScriptChanged} /> : null}
						</div>
					</Column>
				</Row>
			</Grid>
		);
	}
	componentDidMount() {
		const binary = this.props.match.params.id || '';

		API.binary(binary).then((response) => {
			this.setState(response.data);

			if(response.data.ContentType === 'text/plain' || response.data.ContentType === 'text/html' || response.data.ContentType === 'text/css'
				|| response.data.ContentType === 'application/json' || response.data.ContentType === 'text/javascript'
				|| response.data.ContentType === 'application/javascript' || response.data.ContentType === 'application/x-javascript') {
				return API.blob(binary);
			}

			return null;
		}).then((response) => {
			if(response) {
				var s;

				if(typeof response.data === 'object') {
					s = JSON.stringify(response.data, null, 2);
				} else {
					s = response.data;
				}

				this.setState({ Script: s });
			}
		}).catch((error) => {
			const m = {
				type: MessageBarType.error,
				msg: API.errorMessage(error)
			};
			this.setState({ message: m });
		});
	}
}

export default withRouter(Binary);
