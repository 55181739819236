// @flow
import * as React from 'react';

export type GridProps = {
    children?: React.Node
}

export const Grid = (props: GridProps) => (
    <div className="ms-Grid">{props.children}</div>
);

export type RowProps = {
    children?: React.Node
}

export const Row = (props: RowProps) => (
    <div className="ms-Grid-row">{props.children}</div>
);

export type ColumnProps = {
    width: ?number,
    children?: React.Node
}

export const Column = (props: ColumnProps) => {
    const width = props.width || 12;
    return (
        <div className={`ms-Grid-col ms-sm${width} ms-md${width} ms-lg${width}`}>{props.children}</div>
    );
}
