// @flow
import React from 'react';
import { CommandBar, Label, ComboBox, MessageBar, MessageBarType, ProgressIndicator, Link as MSLink } from 'office-ui-fabric-react';
import { Controlled as CodeMirror } from 'react-codemirror2';
import { toCSV } from './toCSV';
import { saveAs } from 'file-saver';
import { DateTime as LuxonDateTime } from 'luxon';
import ReactDataGrid from 'react-data-grid';
import { Toolbar, Data } from 'react-data-grid-addons';
import API from './API.js';
import Fetcher from './Fetcher';
import Link from './Link';
import { GroupSelector } from './GroupSelector';
import { capitalize } from './utils';
import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/neat.css';
import './Config.css';
require('codemirror/mode/javascript/javascript');

const ClientIDFormatter = ({value}) => (
	<Link key={value} href={'/clients/'+value} text={value}/>
);

const GroupIDFormatter = ({value}) => (
	<Link key={value} href={'/groups/'+value} text={value}/>
);

const ProgressFormatter = ({value}) => (
	<ProgressIndicator percentComplete={value}/>
);

const SoftwareNameLink = ({value}) => {
	const href = `https://developer.microsoft.com/en-us/windows/ready-for-windows#/results/?query=${value}`;
	return (
		<MSLink href={href} className="ms-Link" target="_blank">{value}</MSLink>
	);
};

const DateTimeFormatter = ({value}) => {
	return value.toFormat('yyyy-MM-dd TT');
}

function DateTime(ts) {
	return LuxonDateTime.fromMillis(ts * 1000);
}

const BooleanFormatter = ({value}) => (
	<span>{ value === true ? 'yes' : 'no' }</span>
);

const Reports = {
	progress: [{
		key: 'get-client-state',
		text: 'Client State',
		run: function(fetcher, groupId, extractor) {
			return fetcher.fetchClients(groupId).then((clients) => {
				var progress = [];
				for (var i in clients) {
					progress.push(clients[i]);
				}
				return progress;
			}).then((progress) => {
				var arr = [];
				for(var i in progress) {
					const x = extractor(progress[i]);
					arr = arr.concat(x);
				}
				return arr;
			});
		},
		extractor: function(json) {
			const progressData = (progress) => {
				var percent = 0.0;
				var errors = 0;

				var keys = progress ? Object.keys(progress) : [];
				if(keys.length > 0) {
					keys.forEach(key => {
						var element = progress[key];
						if((element.state !== 'skip') && element.total) {
							percent += element.count / element.total;
							if (element.errors) {errors += element.errors.length}
						} else {
							percent += 1.0;
						}
					});
					percent /= keys.length;
				}
			
				return [percent, errors];
			};

			var p = json["Status"] || { };
			var state = '';

			if (p.state === 0) {
				state = 'in progress';
			} else if(p.state === 1) {
				state = 'finished';
			} else if(p.state === 2) {
				state = 'error';
			}
			var percent = 0.0, errors = 0;
			if (p.progress) {
				[percent, errors] = progressData(p.progress)
			}
			return [{
				group_id: json.Group ? json.Group.ObjectID : '',
				group_name: json.Group ? json.Group.DisplayName : '',
				client_id: json["ObjectID"],
				display_name: json["DisplayName"],
				state: state,
				message: p ? p.message : "",
				percent: percent,
				errors: errors,
				timestamp: json.Timestamp,
				upn: json.UPN,
		}];
		},
		headers: [{
			name: "Group ID",
			key: "group_id",
			width: 250,
			formatter: GroupIDFormatter,
			sortable: true,
			resizable: true,
			filterable: true
		}, {
			name: "Group",
			key: "group_name",
			width: 250,
			sortable: true,
			resizable: true,
			filterable: true
		}, {
			name: "Client ID",
			key: "client_id",
			formatter: ClientIDFormatter,
			width: 250,
			sortable: true,
			resizable: true,
			filterable: true
		}, {
			name: "UPN",
			key: "upn",
			width: 250,
			sortable: true,
			resizable: true,
			filterable: true
		}, {
			name: "State",
			key: "state",
			width: 150,
			sortable: true,
			resizable: true,
			filterable: true
		}, {
			name: "Message",
			key: "message",
			width: 400,
			sortable: true,
			resizable: true,
			filterable: true
		}, {
			name: "Complete",
			key: "percent",
			formatter: ProgressFormatter,
			width: 150,
			sortable: true,
			resizable: true,
			filterable: true
		}, {
			name: "Errors",
			key: "errors",
			width: 150,
			sortable: true,
			resizable: true,
			filterable: true
		}, {
			name: "Last seen",
			key: "timestamp",
			width: 150,
			sortable: true,
			resizable: true,
			filterable: true
		}]
	},{
		key: 'get-client-module-state',
		text: 'Module State Per Client',
		run: function(fetcher, groupId, extractor) {
			return fetcher.fetchClients(groupId).then((clients) => {
				var progress = [];
				for (var i in clients) {
					progress.push(clients[i]);
				}
				return progress;
			}).then((progress) => {
				var arr = [];
				for(var i in progress) {
					const x = extractor(progress[i]);
					arr = arr.concat(x);
				}
				return arr;
			});
		},
		extractor: function(json) {
			const progressData = (progress) => {
				if(progress) {
					if(progress.state === 'skip') {
						return 'skip';
					}
					return `${progress.count} / ${progress.total} ${progress.unit}`;
				}
				return 'n/a';
			};

			var inventory = '';
			var file = '';
			var pst = '';
			var custom = '';
			var server = '';

			if(json.Status && json.Status.progress) {
				inventory = progressData(json.Status.progress.inventory);
				file = progressData(json.Status.progress.file);
				pst = progressData(json.Status.progress.pst);
				custom = progressData(json.Status.progress.custom);
				server = progressData(json.Status.progress.server);
			}

			return [{
				group_id: json.Group ? json.Group.ObjectID : '',
				group_name: json.Group ? json.Group.DisplayName : '',
				client_id: json.ObjectID,
				upn: json.UPN,
				inventory: inventory,
				file: file,
				pst: pst,
				custom: custom,
				server: server,
		}];
		},
		headers: [{
			name: "Group ID",
			key: "group_id",
			width: 200,
			formatter: GroupIDFormatter,
			sortable: true,
			resizable: true,
			filterable: true
		}, {
			name: "Group",
			key: "group_name",
			width: 200,
			sortable: true,
			resizable: true,
			filterable: true
		}, {
			name: "Client ID",
			key: "client_id",
			formatter: ClientIDFormatter,
			width: 200,
			sortable: true,
			resizable: true,
			filterable: true
		}, {
			name: "UPN",
			key: "upn",
			width: 250,
			sortable: true,
			resizable: true,
			filterable: true
		}, {
			name: "Inventory",
			key: "inventory",
			width: 100,
			sortable: true,
			resizable: true,
			filterable: true
		}, {
			name: "File",
			key: "file",
			width: 100,
			sortable: true,
			resizable: true,
			filterable: true
		}, {
			name: "PST",
			key: "pst",
			width: 100,
			sortable: true,
			resizable: true,
			filterable: true
		}, {
			name: "Custom",
			key: "custom",
			width: 100,
			sortable: true,
			resizable: true,
			filterable: true
		}, {
			name: "Server",
			key: "server",
			width: 100,
			sortable: true,
			resizable: true,
			filterable: true
		}]
	},{
		key: 'get-group-assignments',
		text: 'Group Assignments',
		run: function(fetcher, groupId, extractor) {
			return fetcher.fetchClients(groupId).then((clients) => {
				return extractor(clients);
			});
		},
		extractor: function(clients) {
			var map = {};

			for(var i in clients) {
				const client = clients[i];
				const memberId = client.GroupID || client.ObjectID;

				if (!map[memberId]) {
					const groupId = client.Group ? client.Group.ObjectID : '';
					const groupName = client.Group ? client.Group.DisplayName : '';

					map[memberId] = {
						GroupID: groupId,
						GroupName: groupName,
						MemberID: memberId,
						count: 1
					};
				} else {
					map[memberId].count += 1;
				}
			}

			var arr = []
			for(var key in map) {
				arr.push(map[key]);
			}
			return arr;
		},
		headers: [{
			name: "Group ID",
			key: "GroupID",
			formatter: GroupIDFormatter,
			width: 400,
			sortable: true,
			resizable: true,
			filterable: true
		}, {
			name: "Group Name",
			key: "GroupName",
			width: 400,
			sortable: true,
			resizable: true,
			filterable: true
		}, {
			name: "Member ID",
			key: "MemberID",
			width: 400,
			sortable: true,
			resizable: true,
			filterable: true
		}, {
			name: "Count",
			key: "count",
			width: 150,
			sortable: true,
			resizable: true,
			filterable: true
		}]
	}, {
		key: 'group-commands',
		text: 'Group Commands',
		run: function(fetcher, groupId, extractor) {
			return fetcher.fetchModuleConfiguration(groupId).then((group_configs) => {
				return extractor(group_configs);
			});
		},
		extractor: function(group_configs) {
			var arr = []

			for(var i in group_configs) {
				arr.push({
					ObjectID: group_configs[i].ObjectID,
					DisplayName: group_configs[i].DisplayName,
					Inventory: group_configs[i].Config.inventory,
					File: group_configs[i].Config.file,
					PST: group_configs[i].Config.pst,
					Custom: group_configs[i].Config.custom
				});
			}

			return arr;
		},
		headers: [{
			name: "Group ID",
			key: "ObjectID",
			formatter: GroupIDFormatter,
			width: 400,
			sortable: true,
			resizable: true,
			filterable: true
		}, {
			name: "Group Name",
			key: "DisplayName",
			width: 400,
			sortable: true,
			resizable: true,
			filterable: true
		}, {
			name: "Inventory",
			key: "Inventory",
			width: 100,
			sortable: true,
			resizable: true,
			filterable: true
		}, {
			name: "File",
			key: "File",
			width: 100,
			sortable: true,
			resizable: true,
			filterable: true
		}, {
			name: "PST",
			key: "PST",
			width: 100,
			sortable: true,
			resizable: true,
			filterable: true
		}, {
			name: "Custom",
			key: "Custom",
			width: 100,
			sortable: true,
			resizable: true,
			filterable: true
		}]
    }],
	inventory: [{
		key: 'system-summary',
		text: 'System details',
		run: function(fetcher, groupId, extractor, progressCB) {
			return fetcher.getModuleDataForGroup(groupId, 'inventory', progressCB).then((inventories) => {
				var arr = [];
				for(var i in inventories) {
					const x = extractor(inventories[i], inventories[i].ObjectID, inventories[i].DisplayName);
					arr = arr.concat(x);
				}
				return arr;
			});
		},
		extractor: function(json, client_id, display_name) {
			var arr = [];
			var system_ram = "";

			var disk_model = "", disk_size = "";
			var os_type = "", os_architecture = "";
			var pc_model = "", pc_manufacturer = "", pc_serial = "";
			var processor_name = "", processor_speed = 0, processor_id = "";
			var video_processor = "", video_ram = 0;
			var tpm_name = "", tpm_status = "";

			if (json.wmiData) {
				if (json.wmiData.ComputerSystem && json.wmiData.ComputerSystem.length) {
					system_ram = json.wmiData.ComputerSystem[0].TotalPhysicalMemory;
				}
				if (json.wmiData.ComputerSystemProduct && json.wmiData.ComputerSystemProduct.length) {
					pc_model = json.wmiData.ComputerSystemProduct[0].Version.length > json.wmiData.ComputerSystemProduct[0].Name.length ?
						json.wmiData.ComputerSystemProduct[0].Version : json.wmiData.ComputerSystemProduct[0].Name;
				}
				if (json.wmiData.DiskDrive && json.wmiData.DiskDrive.length) {
					disk_model = json.wmiData.DiskDrive[0].Model;
					disk_size = json.wmiData.DiskDrive[0].Size;
				}
				if (json.wmiData.OperatingSystem && json.wmiData.OperatingSystem.length) {
					os_type = json.wmiData.OperatingSystem[0].Caption;
					os_architecture = json.wmiData.OperatingSystem[0].OSArchitecture;
				}
				if (json.wmiData.SystemEnclosure && json.wmiData.SystemEnclosure.length) {
					pc_manufacturer = json.wmiData.SystemEnclosure[0].Manufacturer;
					pc_serial = json.wmiData.SystemEnclosure[0].SerialNumber;
				}
				if (json.wmiData.Processor && json.wmiData.Processor.length) {
					processor_name = json.wmiData.Processor[0].Name;
					processor_speed = json.wmiData.Processor[0].CurrentClockSpeed;
					processor_id = json.wmiData.Processor[0].ProcessorId;
				}
				if (json.wmiData.VideoController && json.wmiData.VideoController.length) {
					video_ram = json.wmiData.VideoController[0].AdapterRAM;
					video_processor = json.wmiData.VideoController[0].VideoProcessor;
				}
				if (json.wmiData.TPM && json.wmiData.TPM.length) {
					tpm_name = json.wmiData.TPM[0].Name;
					tpm_status = json.wmiData.TPM[0].Status;
				}
			}

			arr.push({
				client_id: client_id,
				display_name: display_name,
				system_ram: system_ram,
				disk_model: disk_model,
				disk_size: disk_size,
				os_type: os_type,
				os_architecture: os_architecture,
				pc_model: pc_model,
				pc_manufacturer: pc_manufacturer,
				pc_serial: pc_serial,
				processor_name: processor_name,
				processor_speed: processor_speed,
				processor_id: processor_id,
				video_processor: video_processor,
				video_ram: video_ram,
				tpm_name: tpm_name,
				tpm_status: tpm_status
			})
			return arr;
		},
		headers: [{
			name: "Client ID",
			key: "client_id",
			width: 250,
			formatter: ClientIDFormatter,
			sortable: true,
			resizable: true,
			filterable: true
		}, {
			name: "Display Name",
			key: "display_name",
			width: 250,
			sortable: true,
			resizable: true,
			filterable: true
		}, {
			name: "RAM size",
			key: "system_ram",
			width: 140,
			sortable: true,
			resizable: true,
			filterable: true
		}, {
			name: "Disk",
			key: "disk_model",
			width: 200,
			sortable: true,
			resizable: true,
			filterable: true
		}, {
			name: "Disk size",
			key: "disk_size",
			width: 140,
			sortable: true,
			resizable: true,
			filterable: true
		}, {
			name: "Operating System",
			key: "os_type",
			width: 250,
			sortable: true,
			resizable: true,
			filterable: true
		}, {
			name: "Architecture",
			key: "os_architecture",
			width: 140,
			sortable: true,
			resizable: true,
			filterable: true
		}, {
			name: "PC manufacturer",
			key: "pc_manufacturer",
			width: 200,
			sortable: true,
			resizable: true,
			filterable: true
		}, {
			name: "PC model",
			key: "pc_model",
			width: 200,
			sortable: true,
			resizable: true,
			filterable: true
		}, {
			name: "Serial #",
			key: "pc_serial",
			width: 140,
			sortable: true,
			resizable: true,
			filterable: true
		}, {
			name: "Processor",
			key: "processor_name",
			width: 250,
			sortable: true,
			resizable: true,
			filterable: true
		}, {
			name: "Speed",
			key: "processor_speed",
			width: 140,
			sortable: true,
			resizable: true,
			filterable: true
		}, {
			name: "Processor id",
			key: "processor_id",
			width: 200,
			sortable: true,
			resizable: true,
			filterable: true
		}, {
			name: "Video Processor",
			key: "video_processor",
			width: 200,
			sortable: true,
			resizable: true,
			filterable: true
		}, {
			name: "Video RAM",
			key: "video_ram",
			width: 140,
			sortable: true,
			resizable: true,
			filterable: true
		}, {
			name: "TPM type",
			key: "tpm_name",
			width: 200,
			sortable: true,
			resizable: true,
			filterable: true
		}, {
			name: "TPM status",
			key: "tpm_status",
			width: 140,
			sortable: true,
			resizable: true,
			filterable: true
		}]
	}, {
		key: 'software-installed-on-client',
		text: 'Software summary',
		run: function(fetcher, groupId, extractor, progressCB) {
			return fetcher.getModuleDataForGroup(groupId, 'inventory', progressCB).then((inventories) => {
				return extractor(inventories);
			});
		},
		extractor: function(inventories) {
			var map = {};

			for(var i in inventories) {
				const json = inventories[i];
				if(json) {
					for (var x in json.installerRegistry) {
						for (var id in json.installerRegistry[x]) {
							if(!map[id]) {
								map[id] = {
									packageCode: json.installerRegistry[x][id].packageCode,
									name: json.installerRegistry[x][id].name,
									version: json.installerRegistry[x][id].version,
									count: 0
								};
							}
		
							map[id].count += 1;
						}
					}
				}
			}

			var arr = []
			for(var key in map) {
				arr.push(map[key]);
			}
			return arr;
		},
		headers: [{
			name: "Name",
			key: "name",
			width: 400,
			formatter: SoftwareNameLink,
			sortable: true,
			resizable: true,
			filterable: true
		}, {
			name: "Version",
			key: "version",
			width: 250,
			sortable: true,
			resizable: true,
			filterable: true
		}, {
			name: "Package Code",
			key: "packageCode",
			width: 300,
			sortable: true,
			resizable: true,
			filterable: true
		}, {
			name: "Install Count",
			key: "count",
			width: 250,
			sortable: true,
			resizable: true,
			filterable: true
		}]
	}, {
		key: 'software-installed-on-each-client',
		text: 'Software details',
		run: function(fetcher, groupId, extractor, progressCB) {
			return fetcher.getModuleDataForGroup(groupId, 'inventory', progressCB).then((inventories) => {
				var arr = [];
				for(var i in inventories) {
					const x = extractor(inventories[i], inventories[i].ObjectID, inventories[i].DisplayName);
					arr = arr.concat(x);
				}
				return arr;
			});
		},
		extractor: function(json, client_id, display_name) {
			var arr = [];
			for (var x in json.installerRegistry) {
				for (var y in json.installerRegistry[x]) {
					json.installerRegistry[x][y].group_id = json.GroupID;
					json.installerRegistry[x][y].group_name = json.GroupName;
					json.installerRegistry[x][y].client_id = client_id;
					json.installerRegistry[x][y].display_name = display_name;
					arr.push(json.installerRegistry[x][y])
				}
			};
			return arr;
		},
		headers: [{
			name: "Group ID",
			key: "group_id",
			width: 250,
			formatter: GroupIDFormatter,
			sortable: true,
			resizable: true,
			filterable: true
		}, {
			name: "Group",
			key: "group_name",
			width: 250,
			sortable: true,
			resizable: true,
			filterable: true
		}, {
			name: "Client ID",
			key: "client_id",
			width: 250,
			formatter: ClientIDFormatter,
			sortable: true,
			resizable: true,
			filterable: true
		}, {
			name: "Name",
			key: "name",
			width: 400,
			formatter: SoftwareNameLink,
			sortable: true,
			resizable: true,
			filterable: true
		}, {
			name: "Package Code",
			key: "packageCode",
			width: 300,
			sortable: true,
			resizable: true,
			filterable: true
		}, {
			name: "Version",
			key: "version",
			width: 250,
			sortable: true,
			resizable: true,
			filterable: true
		}, {
			name: "Flags",
			key: "flags",
			width: 100,
			sortable: true,
			resizable: true,
			filterable: true
		}]
	}, {
		key: 'drive-mappings-per-client',
		text: 'Drive mappings',
		run: function(fetcher, groupId, extractor, progressCB) {
			return fetcher.getModuleDataForGroup(groupId, 'inventory', progressCB).then((inventories) => {
				var arr = [];
				for(var i in inventories) {
					const x = extractor(inventories[i], inventories[i].ObjectID, inventories[i].DisplayName);
					arr = arr.concat(x);
				}
				return arr;
			});
		},
		extractor: function(json, client_id, display_name) {
			var arr = [];
			if (json.wmiData && json.wmiData.MappdedDrives) {
				for (var x in json.wmiData.MappdedDrives) {
					arr.push({
						group_id: json.GroupID,
						group_name: json.GroupName,
						client_id: client_id,
						name: display_name,
						drive: json.wmiData.MappdedDrives[x].Caption,
						path: json.wmiData.MappdedDrives[x].ProviderName
					})
				}
			}
			return arr;
		},
		headers: [{
			name: "Group ID",
			key: "group_id",
			width: 250,
			formatter: GroupIDFormatter,
			sortable: true,
			resizable: true,
			filterable: true
		}, {
			name: "Group",
			key: "group_name",
			width: 250,
			sortable: true,
			resizable: true,
			filterable: true
		}, {
			name: "Client ID",
			key: "client_id",
			width: 250,
			formatter: ClientIDFormatter,
			sortable: true,
			resizable: true,
			filterable: true
		}, {
			name: "Name",
			key: "name",
			width: 400,
			sortable: true,
			resizable: true,
			filterable: true
		}, {
			name: "Drive",
			key: "drive",
			width: 100,
			sortable: true,
			resizable: true,
			filterable: true
		}, {
			name: "Path",
			key: "path",
			width: 400,
			sortable: true,
			resizable: true,
			filterable: true
		}]
	}, {
		key: 'printers',
		text: 'Printers',
		run: function(fetcher, groupId, extractor, progressCB) {
			return fetcher.getModuleDataForGroup(groupId, 'inventory', progressCB).then((inventories) => {
				var arr = [];
				for(var i in inventories) {
					const x = extractor(inventories[i], inventories[i].ObjectID, inventories[i].DisplayName);
					arr = arr.concat(x);
				}
				return arr;
			});
		},
		extractor: function(json, client_id, display_name) {
			var arr = [];
			if (json.wmiData && json.wmiData.Printer && json.wmiData.Printer.length) {
				for (var x in json.wmiData.Printer) {
					json.wmiData.Printer[x].group_id = json.GroupID;
					json.wmiData.Printer[x].group_name = json.GroupName;
					json.wmiData.Printer[x].client_id = client_id;
					json.wmiData.Printer[x].display_name = display_name;
					arr.push(json.wmiData.Printer[x]);
				}
			}
			return arr;
		},
		headers: [{
			name: "Group ID",
			key: "group_id",
			width: 250,
			formatter: GroupIDFormatter,
			sortable: true,
			resizable: true,
			filterable: true
		}, {
			name: "Group",
			key: "group_name",
			width: 250,
			sortable: true,
			resizable: true,
			filterable: true
		}, {
			name: "Client ID",
			key: "client_id",
			width: 250,
			formatter: ClientIDFormatter,
			sortable: true,
			resizable: true,
			filterable: true
		}, {
			name: "Name",
			key: "Name",
			width: 400,
			sortable: true,
			resizable: true,
			filterable: true
		}, {
			name: "Printer Name",
			key: "Caption",
			width: 300,
			sortable: true,
			resizable: true,
			filterable: true
		}, {
			name: "Port",
			key: "PortName",
			width: 250,
			sortable: true,
			resizable: true,
			filterable: true
		}, {
			name: "Local",
			key: "Local",
			width: 100,
			formatter: BooleanFormatter,
			sortable: true,
			resizable: true,
			filterable: true
		}]
	}],
	file: [{
		key: 'file-state',
		text: 'File state',
		run: function(fetcher, groupId, extractor, progressCB) {
			return fetcher.getModuleDataForGroup(groupId, 'file', progressCB).then((inventories) => {
				var arr = [];
				for(var i in inventories) {
					const x = extractor(inventories[i], inventories[i].ObjectID, inventories[i].DisplayName);
					arr = arr.concat(x);
				}
				return arr;
			});
		},
		extractor: function(json, client_id, display_name) {
			var arr = [];
			const states = ["Check", "Incomplete", "Exclude", "Delete", "In Sync"];
			var walk = function (dir) {
				for (var f in dir.files) {
					dir.files[f].catalog = cat;
					dir.files[f].name = f;
					dir.files[f].path = dir.path;
					dir.files[f].client_id = client_id;
					dir.files[f].display_name = display_name;
					dir.files[f].state_cooked = states[dir.files[f].state];
					dir.files[f].modified = DateTime(dir.files[f].modified);
					if (dir.files[f].state === 4) {
						dir.files[f].size_synced = dir.files[f].size;
					} else if ((dir.files[f].state === 1) && dir.files[f].hashMap) {
						dir.files[f].size_synced = dir.files[f].hashMap.size;						
					} else {
						dir.files[f].size_synced = 0;						
					}
					delete dir.files[f].hashMap;
					arr.push(dir.files[f])
				}
				for (var d in dir.dirs) {
					walk(dir.dirs[d]);
				}
			};

			for (var cat in json.catalogs) {
				walk(json.catalogs[cat]);
			};
			return arr;
		},
		headers: [{
			name: "Client ID",
			key: "client_id",
			width: 250,
			formatter: ClientIDFormatter,
			sortable: true,
			resizable: true,
			filterable: true
		}, {
			name: "File",
			key: "name",
			width: 250,
			sortable: true,
			resizable: true,
			filterable: true
		}, {
			name: "Path",
			key: "path",
			width: 400,
			sortable: true,
			resizable: true,
			filterable: true
		}, {
			name: "Size",
			key: "size",
			width: 150,
			sortable: true,
			resizable: true,
			filterable: true
		}, {
			name: "Size Synced",
			key: "size_synced",
			width: 150,
			sortable: true,
			resizable: true,
			filterable: true
		}, {
			name: "State",
			key: "state_cooked",
			width: 150,
			sortable: true,
			resizable: true,
			filterable: true
		}, {
			name: "Modified",
			key: "modified",
			width: 150,
			formatter: DateTimeFormatter,
			sortable: true,
			resizable: true,
			filterable: true
		}]
	},
	{
		key: 'file-state-anonymous',
		text: 'File state anonymized',
		run: function(fetcher, groupId, extractor, progressCB) {
			return fetcher.getModuleDataForGroup(groupId, 'file', progressCB).then((inventories) => {
				var arr = [];
				for(var i in inventories) {
					const x = extractor(inventories[i], inventories[i].ObjectID, inventories[i].DisplayName);
					arr = arr.concat(x);
				}
				return arr;
			});
		},
		extractor: function(json, client_id, display_name) {
			var arr = [];
			const states = ["Check", "Incomplete", "Exclude", "Delete", "In Sync"];
			var d = new Date();
			var t = Math.round(d.getTime()/1000);

			function rounder(num, div) {
				return Math.round(num/div) * div;
			}
			function namer(name) {
				var dot = name.lastIndexOf(".");
				if (dot > 0) { return name.substring(dot+1);}
				else return "";
			}
			function sizer(size) {
				return (rounder(size, 1000000000) ||
					rounder(size, 1000000) ||
					rounder(size, 1000) ||
					rounder(size, 10));
			}
			function dater(date) {
				return Math.floor((t-date)/(60*60*24*30));
			}
			function walk(dir) {
				for (var f in dir.files) {
					arr.push({name: namer(f), size: sizer(dir.files[f].size), 
						months_unchanged: dater(dir.files[f].modified), 
						state: states[dir.files[f].state]});
				}
				for (var d in dir.dirs) {
					walk(dir.dirs[d]);
				}
			}

			for (var cat in json.catalogs) {
				walk(json.catalogs[cat]);
			};
			return arr;
		},
		headers: [{
			name: "File",
			key: "name",
			width: 250,
			sortable: true,
			resizable: true,
			filterable: true
		}, {
			name: "Size",
			key: "size",
			width: 150,
			sortable: true,
			resizable: true,
			filterable: true
		}, {
			name: "Months unchanged",
			key: "months_unchanged",
			width: 150,
			sortable: true,
			resizable: true,
			filterable: true
		}, {
			name: "State",
			key: "state",
			width: 150,
			sortable: true,
			resizable: true,
			filterable: true
		}]
	}],
	pst: [{
		key: 'pst-state',
		text: 'Client upload: state',
		run: function(fetcher, groupId, extractor, progressCB) {
			return fetcher.getModuleDataForGroup(groupId, 'pst', progressCB).then((inventories) => {
				var arr = [];
				for(var i in inventories) {
					const x = extractor(inventories[i], inventories[i].ObjectID, inventories[i].DisplayName);
					arr = arr.concat(x);
				}
				return arr;
			});
		},
		extractor: function(json, client_id, display_name) {
			var arr = [];
			const states = ["Check", "Incomplete", "Exclude", "Delete", "In Sync"];
			for (var cat in json.catalogs) {
				for (var f in json.catalogs[cat].files) {
					json.catalogs[cat].files[f].catalog = cat;
					json.catalogs[cat].files[f].path = f;
					json.catalogs[cat].files[f].client_id = client_id;
					json.catalogs[cat].files[f].display_name = display_name;
					json.catalogs[cat].files[f].state_cooked = states[json.catalogs[cat].files[f].state];
					json.catalogs[cat].files[f].modified = DateTime(json.catalogs[cat].files[f].modified);
					if (json.catalogs[cat].files[f].state === 4) {
						json.catalogs[cat].files[f].size_synced = json.catalogs[cat].files[f].size;
					} else if ((json.catalogs[cat].files[f].state === 1) && json.catalogs[cat].files[f].hashMap) {
						json.catalogs[cat].files[f].size_synced = json.catalogs[cat].files[f].hashMap.size;						
					} else {
						json.catalogs[cat].files[f].size_synced = 0;						
					}
					delete json.catalogs[cat].files[f].hashMap;
					delete json.catalogs[cat].files[f].summary;
					arr.push(json.catalogs[cat].files[f])
				}
			};
			return arr;
		},
		headers: [{
			name: "Client ID",
			key: "client_id",
			width: 250,
			formatter: ClientIDFormatter,
			sortable: true,
			resizable: true,
			filterable: true
		}, {
			name: "PST",
			key: "name",
			width: 250,
			sortable: true,
			resizable: true,
			filterable: true
		}, {
			name: "Path",
			key: "path",
			width: 400,
			sortable: true,
			resizable: true,
			filterable: true
		}, {
			name: "Size",
			key: "size",
			width: 150,
			sortable: true,
			resizable: true,
			filterable: true
		}, {
			name: "Size Synced",
			key: "size_synced",
			width: 150,
			sortable: true,
			resizable: true,
			filterable: true
		}, {
			name: "State",
			key: "state_cooked",
			width: 150,
			sortable: true,
			resizable: true,
			filterable: true
		}, {
			name: "Modified",
			key: "modified",
			width: 150,
			formatter: DateTimeFormatter,
			sortable: true,
			resizable: true,
			filterable: true
		}]
	},{
		key: 'pst-detail',
		text: 'Client upload: details',
		run: function(fetcher, groupId, extractor, progressCB) {
			return fetcher.getModuleDataForGroup(groupId, 'pst', progressCB).then((inventories) => {
				var arr = [];
				for(var i in inventories) {
					const x = extractor(inventories[i], inventories[i].ObjectID, inventories[i].DisplayName);
					arr = arr.concat(x);
				}
				return arr;
			});
		},
		extractor: function(json, client_id, display_name) {
			var arr = [];
			const states = ["Check", "Incomplete", "Exclude", "Delete", "In Sync"];
			for (var cat in json.catalogs) {
				for (var f in json.catalogs[cat].files) {
					json.catalogs[cat].files[f].catalog = cat;
					json.catalogs[cat].files[f].path = f;
					json.catalogs[cat].files[f].client_id = client_id;
					json.catalogs[cat].files[f].display_name = display_name;
					json.catalogs[cat].files[f].state_cooked = states[json.catalogs[cat].files[f].state];
					json.catalogs[cat].files[f].modified = DateTime(json.catalogs[cat].files[f].modified);
					if (json.catalogs[cat].files[f].state === 4) {
						json.catalogs[cat].files[f].size_synced = json.catalogs[cat].files[f].size;
					} else if ((json.catalogs[cat].files[f].state === 1) && json.catalogs[cat].files[f].hashMap) {
						json.catalogs[cat].files[f].size_synced = json.catalogs[cat].files[f].hashMap.size;	
					} else {
						json.catalogs[cat].files[f].size_synced = 0;						
					}
                  	if (json.catalogs[cat].files[f].hashMap) {
						json.catalogs[cat].files[f].blockmap_length = json.catalogs[cat].files[f].hashMap.blockmap ? json.catalogs[cat].files[f].hashMap.blockmap.length : 0;
						json.catalogs[cat].files[f].hash = json.catalogs[cat].files[f].hashMap.hash;
						json.catalogs[cat].files[f].hashmap_index = json.catalogs[cat].files[f].hashMap.nextIndex;
                    } else {
						json.catalogs[cat].files[f].blockmap_length = 0;
						json.catalogs[cat].files[f].hash = '';
						json.catalogs[cat].files[f].hashmap_index = 0;
					}
					if (json.catalogs[cat].files[f].summary) {
						json.catalogs[cat].files[f].encryption = json.catalogs[cat].files[f].summary.Encryption;
						json.catalogs[cat].files[f].format = json.catalogs[cat].files[f].summary.Format;
						json.catalogs[cat].files[f].summary_size = json.catalogs[cat].files[f].summary.Size;
						json.catalogs[cat].files[f].version = json.catalogs[cat].files[f].summary.Version;
					}
					delete json.catalogs[cat].files[f].hashMap;
					delete json.catalogs[cat].files[f].summary;
					arr.push(json.catalogs[cat].files[f])
				}
			};
			return arr;
		},
		headers: [{
			name: "Client ID",
			key: "client_id",
			width: 250,
			formatter: ClientIDFormatter,
			sortable: true,
			resizable: true,
			filterable: true
		}, {
			name: "PST",
			key: "name",
			width: 250,
			sortable: true,
			resizable: true,
			filterable: true
		}, {
			name: "Path",
			key: "path",
			width: 400,
			sortable: true,
			resizable: true,
			filterable: true
		}, {
			name: "Size",
			key: "size",
			width: 150,
			sortable: true,
			resizable: true,
			filterable: true
		}, {
			name: "Header Size",
			key: "summary_size",
			width: 150,
			sortable: true,
			resizable: true,
			filterable: true
		}, {
			name: "Format",
			key: "format",
			width: 150,
			sortable: true,
			resizable: true,
			filterable: true
		}, {
			name: "Encryption",
			key: "encryption",
			width: 150,
			sortable: true,
			resizable: true,
			filterable: true
		}, {
			name: "Version",
			key: "version",
			width: 80,
			sortable: true,
			resizable: true,
			filterable: true
		}, {
			name: "Size Synced",
			key: "size_synced",
			width: 150,
			sortable: true,
			resizable: true,
			filterable: true
		}, {
			name: "Blockmap length",
			key: "blockmap_length",
			width: 150,
			sortable: true,
			resizable: true,
			filterable: true
		}, {
			name: "Next Index",
			key: "hashmap_index",
			width: 80,
			sortable: true,
			resizable: true,
			filterable: true
		}, {
			name: "Hash",
			key: "hash",
			width: 270,
			sortable: true,
			resizable: true,
			filterable: true
		}, {
			name: "State",
			key: "state_cooked",
			width: 150,
			sortable: true,
			resizable: true,
			filterable: true
		}, {
			name: "Modified",
			key: "modified",
			width: 150,
			formatter: DateTimeFormatter,
			sortable: true,
			resizable: true,
			filterable: true
		}]
	},{
		key: 'pst-import-state',
		text: 'Server import: state',
		run: function(fetcher, groupId, extractor, progressCB) {
				return fetcher.getModuleDataForGroup(groupId, 'server', progressCB).then((inventories) => {
						var arr = [];
						for(var i in inventories) {
								const x = extractor(inventories[i], inventories[i].ObjectID, inventories[i].DisplayName);
								arr = arr.concat(x);
						}
						return arr;
				});
		},
		extractor: function(json, client_id, display_name) {
			var arr = [];
			var files = {};
			var obj = {
					succeeded: 0,
					running: 0,
					failed: 0,
					client_id: client_id
			};
			if(json.pst) {
					for (var f in json.pst.files) {
							var jobChangedNew = json.pst.files[f].jobChanged;
							var remotePath = json.pst.files[f].remotePath;
							var state = files[remotePath] ? files[remotePath].state : "";
							var newState = json.pst.files[f].state;
							if ((state === "Completed") || (state === "CompletedWithWarning")) {
									// discard...
							}  
							else if(!(state === "")){ 
									if(files[remotePath].jobChanged < jobChangedNew){
									files[remotePath].state = newState;
									files[remotePath].jobChanged = jobChangedNew;
									}
							}
							
							else {
									// store this entry...
									files[remotePath] = {
											remotePath: json.pst.files[f].remotePath,
											state: newState,
											jobChanged: json.pst.files[f].jobChanged,
											client_id: client_id
									};
							}
					}
					
					for(var n in files){
							if (files[n].state === "Completed" || files[n].state === "CompletedWithWarning") {
									obj.succeeded += 1;          
							} else if (files[n].state === "Failed" || files[n].state === "Error" || files[n].state === "Orphaned") {
								obj.failed += 1;
							} else{
								obj.running += 1;                        
							}
					}
					arr.push(obj);
			}
			return arr; 
		},
		headers: [{
				name: "Client ID",
				key: "client_id",
				width: 250,
				formatter: ClientIDFormatter,
				sortable: true,
				resizable: true,
				filterable: true
		},  {
				name: "Imports Running",
				key: "running",
				width: 150,
				sortable: true,
				resizable: true,
				filterable: true
		}, {
				name: "Imports Failed",
				key: "failed",
				width: 150,
				sortable: true,
				resizable: true,
				filterable: true
		}, {
				name: "Imports succeeded",
				key: "succeeded",
				width: 150,
				sortable: true,
				resizable: true,
				filterable: true
		}]
	},{
		key: 'pst-import-details',
		text: 'Server import: details',
		run: function(fetcher, groupId, extractor, progressCB) {
				return fetcher.getModuleDataForGroup(groupId, 'server', progressCB).then((inventories) => {
						var arr = [];
						for(var i in inventories) {
								const x = extractor(inventories[i], inventories[i].ObjectID, inventories[i].DisplayName);
								arr = arr.concat(x);
						}
						return arr;
				});
		},
		extractor: function(json, client_id, display_name) {
				var arr = [];
				if(json.pst) {
						for (var f in json.pst.files) {
								json.pst.files[f].job = f;
								json.pst.files[f].client_id = client_id;
								json.pst.files[f].display_name = display_name;
								json.pst.files[f].modified = DateTime(json.pst.files[f].modified);
								json.pst.files[f].size_synced = json.pst.files[f].size;          
								arr.push(json.pst.files[f])
						}                             
				};
				return arr;                     
		},
		headers: [{
				name: "Client ID",
				key: "client_id",
				formatter: ClientIDFormatter,
				width: 250,
				sortable: true,
				resizable: true,
				filterable: true
		}, {
				name: "Remote Path",
				key: "remotePath",
				width: 450,
				sortable: true,
				resizable: true,
				filterable: true
		}, {
				name: "State",
				key: "state",
				width: 100,
				sortable: true,
				resizable: true,
				filterable: true
		}, {
				name: "Size",
				key: "size_synced",
				width: 120,
				sortable: true,
				resizable: true,
				filterable: true
		}, {
				name: "Job Created",
				key: "jobCreated",
				width: 170,
				sortable: true,
				resizable: true,
				filterable: true
		}, {
				name: "Job",
				key: "job",
				width: 800,
				sortable: true,
				resizable: true,
				filterable: true
		}]
	}]
};

function emptyExtractor(json, client_id, display_name) {
	return [];
};

type GraphQueryProps = {

};
type GraphQueryState = {
	isLoading: boolean,
	percentComplete: ?number,
	success: ?bool,
	error: ?string,

	advancedReport: boolean,
	reports: any,

	dataSources: Array<any>,
	selectedDataSource: string,
	selectedReport: number,
	groupId: string,

	dataExtractor: string,
	tableHeaders: string,

	extractor: (json:any, client_id: string, display_name: string) => Array<any>,
	_columns: Array<any>,
	filters: any,
	rows: ?any,
};

const getIndexOfKey = (arr, key) => {
	return arr.findIndex(item => {
		return item && item.key === key;
	});
}

const getMergedReports = (custom_reports) => {
	var reports = { ...Reports };

	for(var source in custom_reports) {
		if(reports[source]) {
			if(custom_reports[source] !== null) {	// data source is available in report.js --> iterate over reports
				for(var custom_report of custom_reports[source]) {
					const idx = getIndexOfKey(reports[source], custom_report.key);
					if(idx !== -1) {
						if(custom_report.run) {	// report in reports.js has a run function --> replace
							reports[source][idx] = custom_report;
						} else { // delete
							delete reports[source][idx];
						}
					} else {	// report in reports.js has no run function --> delete
						reports[source].push(custom_report);
					}
				}
			} else {	// data source is set to null in report.js --> remove it
				delete reports[source];
			}
		} else if (custom_reports[source]) {	// data source is available in report.js but not in reports --> add it
			reports[source] = custom_reports[source];
		}
	}

	return reports;
};

const getDataSourcesFromReports = (reports) => {
	var dataSources = [];
	for(var key in reports) {
		dataSources.push({
			key: key,
			text: capitalize(key)
		});
	}
	return dataSources;
}

class GraphQuery extends React.Component<GraphQueryProps, GraphQueryState> {
	state = {
		isLoading: false,
		percentComplete: null,
		success: undefined,
		error: null,

		advancedReport: false,
		reports: null,

		dataSources: [],
		selectedDataSource: 'progress',
		selectedReport: 0,
		groupId: '*',

		dataExtractor: '',
		tableHeaders: '',

		extractor: emptyExtractor,
		_columns: [],
		filters: {},
		rows: [],
	};
	fetcher = new Fetcher();
	componentDidMount() {
		API.blob('reports.js').then(res => {
			try {
				const custom_reports = eval(`(function() { return ${res.data}; })();`);		// eslint-disable-line no-eval
				const reports = getMergedReports(custom_reports);
				const dataSources = getDataSourcesFromReports(reports);
				this.setState({
					dataSources: dataSources,
					reports: reports
				});
			} catch(e) {
				this.setError(`Failed to parse reports.js: ${e.message}`);
			}
		}).catch(err => {
			const reports = Reports;
			const dataSources = getDataSourcesFromReports(reports);
			this.setState({
				dataSources: dataSources,
				reports: reports
			});
		});
	}
	setError(msg: string) {
		this.setState({
			success: false,
			error: msg,
			isLoading: false
		});
	}
	onDataSourceChanged = (ev:any, option: string) => {
		this.setState({
			selectedDataSource: option.key,
			selectedReport: 0,
			extractor: emptyExtractor,
			dataExtractor: '',
			_columns: [],
			tableHeaders: '',
			filters: {},
			rows: [],
		});
	}
	onReportChanged = (ev: any, option: string, index: number) => {
		const { selectedDataSource, reports } = this.state;

		if(!selectedDataSource || !reports || !reports[selectedDataSource] || !reports[selectedDataSource][index]) {
			this.setState({
				selectedReport: index,
				extractor: emptyExtractor,
				dataExtractor: '',
				_columns: [],
				tableHeaders: '',
				filters: {},
				rows: [],
			});
			return;
		}

		const r = reports[selectedDataSource][index];
		this.setState({
			selectedReport: index,
			extractor: r.extractor,
			dataExtractor: r.extractor.toString(),
			_columns: r.headers,
			tableHeaders: JSON.stringify(r.headers, null, 2),
			filters: {},
			rows: []
		});
	}
	onGroupIdChanged = (value: string) => {
		this.setState({ groupId: value });
	}
	onDataExtractorChanged = (editor: any, data: any, value: string) => {
		try {
			const fn = (new Function('return '+value))();		// eslint-disable-line no-new-func
			this.setState({
				dataExtractor: value,
				extractor: fn,
			});
		}
		catch(e) {
			this.setState({ dataExtractor: value });
		}
	}
	onTableHeadersChanged = (editor: any, data: any, value: string) => {
		try {
			const headers = JSON.parse(value);
			this.setState({
				tableHeaders: value,
				_columns: headers
			});
		} catch(e) {
			this.setState({tableHeaders: value});
		}
	}
	queryButton = () => {
		const { groupId, selectedDataSource, reports, selectedReport, extractor } = this.state;
		if(!groupId) {
			this.setError('Group may not be empty');
			return;
		}
		if(!selectedDataSource || !reports || !reports[selectedDataSource] || !reports[selectedDataSource][selectedReport]) {
			return;
		}

		this.setState({
			filters: {},
			rows: [],
			isLoading: true,
			percentComplete: null,
			error: null
		});

		const r = reports[selectedDataSource][selectedReport];
		this.fetcher = new Fetcher();

		const progressCB = (step, total) => {
			const completed = (step * (100 / total)) / 100;
			var newComplete = (this.state.percentComplete ? this.state.percentComplete : 0.0) + completed;
			if(newComplete >= 1.0) {
				newComplete = 1.0;
			}

			this.setState({ percentComplete: newComplete });
		};

		r.run(this.fetcher, groupId === 'ALL' ? '' : groupId, extractor, progressCB).then((arr) => {
			this.setState({
				rows: arr,
				isLoading: false
			});
		}).catch((error) => {
			this.setError(API.errorMessage(error));
		});
	}
	onSaveReportCSV = () => {
		const headers = this.state._columns ? this.state._columns.map(el => {
			return el.key;
		}) : null;
		const csv = toCSV(this.getRows(), headers);
		const blob = new Blob([csv], {type: 'text/csv'});
		saveAs(blob, 'Report.csv');
	}
	onSaveReportTSV = () => {
		const headers = this.state._columns ? this.state._columns.map(el => {
			return el.key;
		}) : null;
		const tsv = toCSV(this.getRows(), headers, '\t');
		const blob = new Blob([tsv], {type: 'text/tab-separated-values'});
		saveAs(blob, 'Report.tsv');
	}
	onAdvancedReport = () => {
		this.setState({ advancedReport: !this.state.advancedReport });
	}
	onGridSort = (sortColumn, sortDirection) => {
		this.setState({ sortColumn: sortColumn, sortDirection: sortDirection });
	}
	getRows = () => {
		return Data.Selectors.getRows(this.state);
	};
	getSize = () => {
		return this.getRows().length;
	};
	rowGetter = (idx) => {
		let rows = this.getRows();
		return rows[idx];
	};
	onAddFilter = (filter) => {
		let newFilters = Object.assign({}, this.state.filters);
		if (filter.filterTerm) {
			newFilters[filter.column.key] = filter;
		} else {
			delete newFilters[filter.column.key];
		}
		this.setState({ filters: newFilters });
	};
	onClearFilters = () => {
		this.setState({filters: {} });
	};
	render() {
		const CodeMirrorOptions = {
			theme: 'neat',
			lineNumbers: true,
			mode: 'javascript'
		};
		const { isLoading, percentComplete, dataExtractor, tableHeaders, _columns, dataSources, selectedDataSource, advancedReport } = this.state;
		const rowsCount = this.getSize();

		var reports = [];
		if(selectedDataSource && this.state.reports && this.state.reports[selectedDataSource]) {
			reports = this.state.reports[selectedDataSource];
		}

		return (
			<div>
				<div className="ms-Grid-row">
					<div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
						{ this.state.error ? <MessageBar messageBarType={MessageBarType.error} isMultiline={true}>Error - {this.state.error}</MessageBar> : null }
						<CommandBar isSearchBoxVisible={ false } items={ [{
							key: 'run-query',
							name: 'Run query',
							className: 'ms-CommandBarItem',
							iconProps: { iconName: 'CheckMark' },
							onClick: this.queryButton
						}, {
							key: 'download-report',
							name: 'Download report',
							className: 'ms-CommandBarItem',
							iconProps: { iconName: 'DownloadDocument' },
							disabled: this.getSize() === 0,
							subMenuProps: {
								items: [{
									key: 'download-report-csv',
									name: 'As CSV file',
									iconProps: { iconName: 'AnalyticsReport' },
									onClick: this.onSaveReportCSV
								}, {
									key: 'download-report-tsv',
									name: 'As TSV file',
									iconProps: { iconName: 'MobileReport' },
									onClick: this.onSaveReportTSV
								}]
							}
						}, {
							key: 'custom-report',
							name: 'Advanced Report',
							className: 'ms-CommandBarItem',
							iconProps: { iconName: 'QueryList' },
							canCheck: true,
							checked: this.state.advancedReport,
							onClick: this.onAdvancedReport
						}] }
						/>
					</div>
				</div>
				<div className="ms-Grid-row">
					<div className="ms-Grid-col ms-sm2 ms-md2 ms-lg2">
						<ComboBox label="Data Source" options={dataSources} onChange={this.onDataSourceChanged}/>
					</div>
					<div className="ms-Grid-col ms-sm3 ms-md3 ms-lg3">
						<ComboBox label="Report" options={reports} onChange={this.onReportChanged}/>
					</div>
					<div className="ms-Grid-col ms-sm3 ms-md3 ms-lg3">
						<GroupSelector
							defaultSelectedKey="*"
							includeAllClientsGroup={true}
							includeUnassignedClientsGroup={true}
							onSelectedGroupChanged={this.onGroupIdChanged}
						/>
					</div>
					<div className="ms-Grid-col ms-sm4 ms-md4 ms-lg4">
					</div>
				</div>
				{advancedReport ?
				<div className="ms-Grid-row">
					<div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
						<div style={{ maxWidth: "1080px" }}>
							Data Extractor
							<CodeMirror value={dataExtractor} options={CodeMirrorOptions} onBeforeChange={this.onDataExtractorChanged}/>
						</div>
					</div>
				</div> : null }
				{advancedReport ?
				<div className="ms-Grid-row">
					<div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
						<div style={{ maxWidth: "1080px" }}>
							Table Headers
							<CodeMirror value={tableHeaders} options={CodeMirrorOptions} onBeforeChange={this.onTableHeadersChanged}/>
						</div>
					</div>
				</div> : null }
				<div className="ms-Grid-row">
					<div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
						{ isLoading? <ProgressIndicator label='Loading...' percentComplete={percentComplete}/> : null }
					</div>
				</div>
				{!isLoading ? <div className="ms-Grid-row">
					<div className="ms-Grid-col ms-sm4 ms-md4 ms-lg4">
						<Label>Total {rowsCount} items</Label>
					</div>
					<div className="ms-Grid-col ms-sm8 ms-md8 ms-lg8">
					</div>
				</div> : null }
				<div className="ms-Grid-row">
					<div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
						<div style={ { width: '100%' } }>
							<ReactDataGrid
								onGridSort={this.onGridSort}
								onAddFilter={this.onAddFilter}
								onClearFilters={this.onClearFilters}
								toolbar={<Toolbar enableFilter={true}/>}
								columns={_columns}
								rowGetter={this.rowGetter}
								rowsCount={rowsCount}
								minHeight={700} />
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default GraphQuery;
