// @flow
import React from 'react';
import { Panel, PanelType } from 'office-ui-fabric-react';
import { DefaultButton } from 'office-ui-fabric-react';
import { IconButton, Link } from 'office-ui-fabric-react';
import './PanelO365Apps.css';

type AppListProps = {
	tenant: ?string
};
function AppList(props: AppListProps) {
	const tenant = props.tenant || '';
	const Apps = [{
		name: 'Outlook',
		href: 'https://outlook.office365.com/',
		icon: 'outlook',
	}, {
		name: 'OneDrive',
		href: `https://${tenant}-my.sharepoint.com/`,
		icon: 'onedrive',
	}, {
		name: 'Word',
		href: 'https://www.office.com/launch/word',
		icon: 'word',
	}, {
		name: 'Excel',
		href: 'https://www.office.com/launch/excel',
		icon: 'excel',
	}, {
		name: 'Powerpoint',
		href: 'https://www.office.com/launch/powerpoint',
		icon: 'powerpoint',
	}, {
		name: 'OneNote',
		href: 'https://www.onenote.com/notebooks',
		icon: 'onenote',
	}, {
		name: 'Sharepoint',
		href: `https://${tenant}.sharepoint.com/`,
		icon: 'sharepoint',
	}, {
		name: 'Teams',
		href: 'https://aka.ms/mstfw',
		icon: 'teams',
	}];

	let list = [];
	for(var i = 0; i < Apps.length; i += 2) {
		let arr = [];

		for(var j = i; (j < Apps.length) && (j < i + 2); ++j) {
			const app = Apps[j];
			const el = (
				<div key={ "app-" + j } className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
					<Link href={app.href} target="_blank">
						<DefaultButton className="AppListButton">
							<div className={ "ms-BrandIcon--icon16 ms-BrandIcon--" + app.icon }></div>&nbsp;{app.name}
						</DefaultButton>
					</Link>
				</div>
			);
			arr.push(el);
		}

		const el = (
			<div key={ "row-" + i/2 } className="ms-Grid-row TopBarRow">
				{arr}
			</div>
		);
		list.push(el);
	}
	return list;
}

type PanelO365AppsProps = {
	tenant: ?string
};
type PanelO365AppsState = {
	showPanel: boolean,
};

class PanelO365Apps extends React.Component<PanelO365AppsProps, PanelO365AppsState> {
	state = {
		showPanel: false,
	};
	Show = () => {
		this.setState({ showPanel: true });
	}
	onShowPanel = () => {
		this.setState({ showPanel: true });
	}
	onClosePanel = () => {
		this.setState({ showPanel: false });
	}
	render() {
		const { tenant } = this.props;
		return (
			<Panel isOpen={this.state.showPanel} type={PanelType.smallFixedNear} onDismiss={this.onClosePanel} onLightDismissClick={this.onClosePanel}
				hasCloseButton={false} isBlocking={false} isLightDismiss={true}>
				<div className="ms-Grid">
					<div className="ms-Grid-row TopBar">
						<div className="ms-Grid-col ms-sm4 ms-md4 ms-lg4">
							<IconButton className="TopBar WaffleIcon" iconProps={ { iconName: 'WaffleOffice365' } } onClick={ this.onClosePanel }/>
						</div>
						<div className="ms-Grid-col ms-sm8 ms-md8 ms-lg8">
							<Link className="TopBarLink" href="https://www.office.com/" target="_blank">
								Office 365 <i className="ms-Icon ms-Icon--Forward"></i>
							</Link>
						</div>
					</div>
					<div className="ms-Grid-row TopBarRow">
						<div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
							<h2 className="ms-font-xl ms-fontWeight-semibold">Apps</h2>
						</div>
					</div>
					<AppList tenant={tenant}/>
				</div>
			</Panel>
		);
	}
}

export default PanelO365Apps;
