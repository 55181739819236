// @flow
import React from 'react';
import { ComboBox } from 'office-ui-fabric-react';

export const ModuleOption = {
    unselected: '',
    skip: 'skip',
    inventory: 'inventory',
    migrate: 'migrate'
}

const moduleOptions = [
	{ key: ModuleOption.skip, text: 'Skip' },
	{ key: ModuleOption.inventory, text: 'Discover' },
	{ key: ModuleOption.migrate, text: 'Migrate' }
];

type ComboBoxModuleOptionProps = {
    label: string,
    selectedKey: string,
    disabled: boolean,
	onChange: (option: string) => void
};

export class ComboBoxModuleOption extends React.Component<ComboBoxModuleOptionProps, {}> {
	onChange = (ev: any, option: any) => {
		if (this.props.onChange && (option !== undefined)) {
            this.props.onChange(option.key);
		}
	}
	render() {
        const { disabled, selectedKey, label } = this.props;
		return (
            <ComboBox
                disabled={disabled}
                selectedKey={selectedKey}
                onChange={this.onChange}
                label={label}
                allowFreeform={false}
                options={moduleOptions}
            />
		);
	}
}
