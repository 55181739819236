// @flow

/*
import Form from 'react-jsonschema-form';
import './Form.css';
export { Form };
//*/
///*
import React from 'react';
window.JSONEditor = require ('@json-editor/json-editor');
window.JSONEditor.defaults.iconlibs.officeui = window.JSONEditor.AbstractIconLib.extend({
    mapping: {
      collapse: 'CollapseContent',
      expand: 'MiniExpand',
      "delete": 'Delete',
      edit: 'PageEdit',
      add: 'PageAdd',
      cancel: 'Cancel',
      save: 'Save',
      moveup: 'Up',
      movedown: 'Down'
    },
    icon_prefix: 'ms-Icon ms-Icon--'
});
window.JSONEditor.defaults.themes.officeui = window.JSONEditor.defaults.themes.html.extend({
    getFloatRightLinkHolder: function() {
        var el = document.createElement('div');
        if (typeof(el.style) === 'undefined' || el.style === null) { el.style = {}; }
        el.style.cssFloat = 'right';
        el.style.marginLeft = '10px';
        return el;
    },
    getGridContainer: function() {
        var el = document.createElement('div');
        el.className = 'ms-Grid';
        return el;
    },
    getHeader: function(text) {
        var el = document.createElement('h3');
        el.className = 'ms-fontSize-l ms-fontWeight-semibold';
        if(typeof text === "string") {
            el.textContent = text;
        }
        else {
            el.appendChild(text);
        }
        return el;
    },
    getGridRow: function() {
        var el = document.createElement('div');
        el.className = 'ms-Grid-row';
        return el;
    },
    getGridColumn: function() {
        var el = document.createElement('div');
        el.className = 'ms-Grid-col';
        if (typeof(el.style) === 'undefined' || el.style === null) { el.style = {}; }
        el.style.marginBottom = '2px';
        return el;
    },
    setGridColumnSize: function(el, size) {
        el.className = 'ms-Grid-col ms-sm' + size + ' ms-md' + size + ' ms-lg' + size;
    },
    getButton: function(text, icon, title) {
        var el = document.createElement('button');
        el.className = 'ms-Button ms-Button--default';
        el.type = 'button';
        this.setButtonText(el,text,icon,title);
        return el;
    },
    getFormInputLabel: function(text) {
        var el = document.createElement('label');
        el.className = 'ms-Label ms-fontSize-m ms-fontWeight-semibold';
        el.textContent = text;
        if (typeof(el.style) === 'undefined' || el.style === null) { el.style = {}; }
        el.style.display = 'block';
        el.style.float = 'left';
        el.style.minWidth = '150px';
        el.style.marginRight = '5px';
        return el;
    },
    getFormInputField: function(type) {
        var el = document.createElement('input');
        el.className = 'ms-TextField-field ms-fontSize-s';
        el.setAttribute('type', type);
        if (typeof(el.style) === 'undefined' || el.style === null) { el.style = {}; }
        el.style.width = '50%';
        return el;
    },
    getFormInputDescription: function(text) {
        var el = document.createElement('p');
        el.className = 'ms-fontSize-s';
        if (typeof(el.style) === 'undefined' || el.style === null) { el.style = {}; }
        el.style.margin = '0px';
        el.style.marginLeft = '5px';
        el.style.display = 'inline-block';
        el.style['font-style'] = 'italic';
        el.appendChild(document.createTextNode(text));
        return el;
    },
    getTextareaInput: function() {
        var el = document.createElement('textarea');
        el.className = 'ms-TextField-field ms-fontSize-s';
        if (typeof(el.style) === 'undefined' || el.style === null) { el.style = {}; }
        el.style.width = '100%';
        el.style.height = '60px';
        return el;
    },
    getIndentedPanel: function() {
        var el = document.createElement('div');
        if (typeof(el.style) === 'undefined' || el.style === null) { el.style = {}; }
        el.style.border = '1px solid #ddd';
        el.style.padding = '5px';
        el.style.margin = '10px';
        el.style.borderRadius = '3px';
        return el;
    },
    getTopIndentedPanel: function() {
        var el = document.createElement('div');
        if (typeof(el.style) === 'undefined' || el.style === null) { el.style = {}; }
        el.style.paddingLeft = '10px';
        el.style.marginLeft = '10px';
        return el;
    },
    getErrorMessage: function(text) {
        var el = document.createElement('p');
        if (typeof(el.style) === 'undefined' || el.style === null) { el.style = {}; }
        el.style.color = 'red';
        el.appendChild(document.createTextNode(text));
        return el;
    },
    getTab: function(span, tabId) {
        var el = document.createElement('div');
        el.appendChild(span);
        el.id = tabId;
        if (typeof(el.style) === 'undefined' || el.style === null) { el.style = {}; }
        this.applyStyles(el,{
          border: '1px solid #ccc',
          borderWidth: '1px 0 1px 1px',
          textAlign: 'center',
          lineHeight: '30px',
          borderRadius: '5px',
          borderBottomRightRadius: 0,
          borderTopRightRadius: 0,
          fontWeight: 'bold',
          cursor: 'pointer'
        });
        return el;
    },
    getTopTab: function(span, tabId) {
        var el = document.createElement('div');
        el.id = tabId;
        el.appendChild(span);
        if (typeof(el.style) === 'undefined' || el.style === null) { el.style = {}; }
        this.applyStyles(el,{
          float: 'left',
          border: '1px solid #ccc',
          borderWidth: '1px 1px 0px 1px',
          textAlign: 'center',
          lineHeight: '30px',
          borderRadius: '5px',
          paddingLeft:'5px',
          paddingRight:'5px',
          borderBottomRightRadius: 0,
          borderBottomLeftRadius: 0,
          fontWeight: 'bold',
          cursor: 'pointer'
        });
        return el;
    },
});

window.JSONEditor.defaults.options.iconlib = 'officeui';
window.JSONEditor.defaults.options.theme = 'officeui';
window.JSONEditor.defaults.options.no_additional_properties = true;
// window.JSONEditor.defaults.options.remove_empty_properties = true;
// window.JSONEditor.defaults.options.disable_collapse=true;
// window.JSONEditor.defaults.options.disable_properties=true;
// window.JSONEditor.defaults.options.disable_edit_json=true;
// window.JSONEditor.defaults.options.disable_array_delete_all_rows=true;
// window.JSONEditor.defaults.options.disable_array_delete_last_row=true;
// window.JSONEditor.defaults.options.disable_array_reorder=true;
// window.JSONEditor.defaults.options.prompt_before_delete=false;
// window.JSONEditor.defaults.options.required_by_default=true;
// window.JSONEditor.defaults.options.show_errors='always';

type FormProps = {
    onUnmount: (any) => void,
    title: ?string,
    schema: any,
    value: any,
};
type FormState = {
};

export class Form extends React.Component<FormProps, FormState> {
    static defaultProps = {
        title: ''
    };
    editor: any;
	getValue = ():string => {
		return this.editor ? this.editor.getValue() : '';
	}
	setValue = (s: string) => {
        if(this.editor) {
            this.editor.setValue(s);            
        }
    }
    load = (props: any) => {
        const el = document.getElementById('json-form');
        this.editor = new window.JSONEditor(el, props);
        this.editor.on('ready', () => {
            if(props.value) {
                this.setValue(props.value);
            }
            this.editor.validate();
        });
    }
	render() {
        return (
            <div id="json-form"/>
        )
	}
	componentWillUnmount() {
        if(this.props.onUnmount) {
            this.props.onUnmount(this.editor);
        }
        if(this.editor) {
            this.editor.destroy();
        }
	}
	componentWillReceiveProps(nextProps: any) {
        const shouldLoad = (this.props.schema.title !== nextProps.schema.title) || (JSON.stringify(this.props.value) !== JSON.stringify(nextProps.value));
		if(shouldLoad) {
            this.editor.destroy();
            this.load(nextProps);
		}
	}
	componentDidMount() {
        this.load(this.props);
	}
}
//*/
