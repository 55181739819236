// @flow
import React from 'react';
import type { Match } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { CommandBar, DefaultButton } from 'office-ui-fabric-react';
import { Pivot, PivotItem, PivotLinkFormat } from 'office-ui-fabric-react';
import { Controlled as CodeMirror } from 'react-codemirror2';
import API from './API.js';
import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/neat.css';
import { capitalize } from './utils';
import { ProgressIndicator, MessageBar, MessageBarType } from 'office-ui-fabric-react';
import { Form } from './Form.js';
import FormSchema from './FormSchema';
require('codemirror/mode/javascript/javascript');

type ModuleState = {
	enabled: ?bool,
	config: any,
	script: string,
	loading: boolean,
	success: ?boolean,
	error: ?string,
};

export class Module extends React.Component<{ match: Match }, ModuleState> {
	editor: ?Form;
	state = {
		enabled: undefined,
		config: '',
		script: '',
		loading: true,
		success: null,
		error: null,
	};
	onSave = () => {
		const module = this.props.match.params.id || '';

		this.setState({ error: null, success: false });

		API.saveModule(module, this.state.script).then(() => {
			var config = this.state.config;
			if(this.editor) {
				config = this.editor.getValue();
			}

			if(config) {
				return API.saveModuleJSON(module, config);
			}

			API.deleteModuleJSON(module).catch(() => {});
			return null;
		}).then(() => {
			this.setState({ error: null, success: true });
			API.blob(`config/config_${module}`).catch(() => {});
		}).catch((error) => {
			this.setState({ error: API.errorMessage(error), success: false });
		});
	}
	onScriptChanged = (editor: any, data: any, value: string) => {
		this.setState({ script: value });
	}
	load = (module: string) => {
		const promises = [
			API.getModuleConfiguration().catch(() => {}),
			API.getModuleJSON(module).catch(() => {}),
			API.module(module).catch(() => {})
		];
		Promise.all(promises).then((responses) => {
			this.setState({
				loading: false,
				enabled: responses[0] && responses[0].data ? responses[0].data[module] : false,
				config: responses[1] ? responses[1].data : '',
				script: responses[2] ? responses[2].data : '',
			});
		});
	}
	onResetConfig = () => {
		this.setState({ config: '' });
	}
	onUnmountForm = (editor: Form) => {
		this.setState({ config: editor.getValue() });
	}
	render() {
		const CodeMirrorOptions = {
			theme: 'neat',
			lineNumbers: true,
			mode: 'javascript'
		};

		var topBar = null;
		if(this.state.loading) {
			topBar = <ProgressIndicator label='Loading...'/>;
		} else if(this.state.error) {
			topBar = <MessageBar messageBarType={MessageBarType.error} isMultiline={true}>Error - {this.state.error}</MessageBar>;
		} else if(this.state.success) {
			topBar = <MessageBar messageBarType={MessageBarType.success} isMultiline={false}>Successfully saved config</MessageBar>;
		}

		let schema = null;
		if(this.props.match.params.id === 'custom') {
			schema = FormSchema[this.state.enabled] ? FormSchema[this.state.enabled] : null;
		} else {
			schema = FormSchema[this.props.match.params.id] ? FormSchema[this.props.match.params.id] : null;
		}

		let commandBarItems = null;
		if(this.state.enabled) {
			commandBarItems = [{
				key: 'save',
				name: 'Save',
				className: 'ms-CommandBarItem',
				iconProps: { iconName: 'Save' },
				onClick: this.onSave
			}];
		} else {
			commandBarItems = [{
				key: 'disabled',
				name: `${capitalize(this.props.match.params.id||'')} Module is disabled`,
				className: 'ms-CommandBarItem',
				iconProps: {
					iconName: 'BlockedSite',
					style: {
						color: 'blue'
					}
				},
				disabled: true,
			}];
		}

		return (
			<div className="ms-Grid">
				<Helmet>
					<title>realmigrator - {capitalize(this.props.match.params.id||'')} Module</title>
				</Helmet>
				<div className="ms-Grid-row">
					<div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
						<h2>{capitalize(this.props.match.params.id||'')} Module</h2>
					</div>
					<div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
					</div>
				</div>
				<div className="ms-Grid-row">
					<div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
						{ topBar }
						<CommandBar isSearchBoxVisible={ false } items={ commandBarItems }/>
					</div>
				</div>
				{ this.state.enabled ?
				<div className="ms-Grid-row">
					<div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
						{ schema ? <h3>Module Configuration <DefaultButton text="Reset" onClick={this.onResetConfig} iconProps={ { iconName: 'ClearFormatting' } }/></h3> : null }
					</div>
				</div>
				: null }
				{ this.state.enabled ?
				<div className="ms-Grid-row">
					<div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
						<Pivot linkFormat={PivotLinkFormat.tabs}>
							{ schema ?
								<PivotItem headerText='Config'>
									<Form ref={ c => { this.editor = c; }} title={null} onUnmount={this.onUnmountForm} schema={schema} value={this.state.config}/>
								</PivotItem> : <div/>
							}
							<PivotItem headerText='Script'>
								<div style={{ maxWidth: "1080px" }}>
									<CodeMirror value={this.state.script} options={CodeMirrorOptions} onBeforeChange={this.onScriptChanged}/>
								</div>
							</PivotItem>
						</Pivot>
					</div>
				</div>
				: null }
			</div>
		);
	}
	componentDidMount() {
		const module = this.props.match.params.id || '';
		this.load(module);
	}
	componentWillReceiveProps(nextProps: any) {
		const module = this.props.match.params.id;
		const nextModule = nextProps.match.params.id;

		if(module !== nextModule) {
			this.setState({ loading: true, error: null, success: false });
			this.load(nextModule);
		}
	}
}

export default Module;
