// @flow
import { init as initApm } from '@elastic/apm-rum'
import { version } from './version';
//import history from './history';

const apm = initApm({
	serviceName: 'web',
	serverUrl: '/apm',
	serviceVersion: version
});
//apm.setInitialPageLoadName(history.location.pathname);

/**
 * To stop listening, call the function returned from listen().
 * unlisten();
 * const unlisten =
**/
/*
history.listen((location, action) => {
    apm.startTransaction(location.pathname, 'route-change');
});
*/

export default apm;
