// @flow
import React from 'react';
import { Modal, TextField, DefaultButton, PrimaryButton,
    MessageBar, MessageBarType,
    Spinner, SpinnerSize } from 'office-ui-fabric-react';
import { Grid, Row, Column } from './Grid';
import API from './API.js';


export type TeamsData = {
    name: string,
    url: String,
};

type CreateTeamsDlgProps = {
	onOK: (team: ?TeamsData) => void,
	userId: string,
};


type CreateTeamsState = {
	hidden: boolean,
};

export class CreateTeamsDlg extends React.Component<CreateTeamsDlgProps, CreateTeamsState> {
	state = {
        hidden: true,
        cancomplete: true,
        name: '',
        description: '',
        owners: '',
        error: '',
    };
	Show = () => {
		let state = {
            hidden: false,
            cancomplete: true,
            name: '',
            description: '',
            owners: '',
            error: '',
        };

        this.setState(state);
    };
	onNameChanged = (event: SyntheticEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
		this.setState({name: newValue, cancomplete: true, error: ''});
	}
	onDescChanged = (event: SyntheticEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
		this.setState({description: newValue, cancomplete: true, error: ''});
	}
	onOwnersChanged = (event: SyntheticEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
		this.setState({owners: newValue, cancomplete: true, error: ''});
	}
	Dismiss = () => {
		this.setState({
			hidden: true,
			team: null
		});
	};
	onDismiss = () => {
		this.Dismiss();
    };
    onOK = () => {
        this.setState({cancomplete: false})

        let createData = {
            displayName: this.state.name,
            description: this.state.description,
            owners: this.state.owners,
        };

        let team: TeamsData = {
            name: this.props.name,
            url: "",
        };

        API.createGroup(this.props.userId, createData).then((response) => {
            team.url = response.data.id || "";
            if(this.props.onOK && (team.url !== "")) {
                this.props.onOK(team);
            }        
        }).catch((error) => {
            this.setState({cancomplete: false, error: 'Failed to create Team: ' + API.errorMessage(error) })
        });
    }
	render() {
		const { hidden, name, description, owners, cancomplete, error } = this.state;
        const title = 'Create new Team';

        let errorMessage = null
        if (error) {
            errorMessage = (<Row><Column>
                <MessageBar messageBarType={MessageBarType.error} isMultiline={false}>
               {error}</MessageBar></Column></Row>);
        } else if (!cancomplete) {
            errorMessage = (<Row><Column>
                    <div style={ { float: 'left', bottom: '0px' } }>
                        <Spinner label="Creating new Team ..." ariaLive="assertive" labelPosition="right" size={SpinnerSize.xSmall} />
                    </div>
                </Column></Row>);
        }

		return (
			<Modal
				isOpen={ !hidden }
				onDismiss={ this.Dismiss }
				containerClassName="AddCatalogItemDlgContainer">
                <div className="AddCatalogItemDlgHeader">
                    <span>{title}</span>
                </div>
                <div className="AddCatalogItemDlgBody">
                    <Grid>
                        <Row>
                            <Column>
                                <TextField label='Name' description='' required={ true } value={name} onChange={this.onNameChanged}/>
                            </Column>
                        </Row>
                        <Row>
                            <Column>
                                <TextField label='Description' description='' required={ false } value={description} onChange={this.onDescChanged}/>
                            </Column>
                        </Row>
                        <Row>
                            <Column>
                                <TextField label='Owners' description='Enter a comma-separated list of owner IDs or UPNs' required={ true } value={owners} onChange={this.onOwnersChanged}/>
                            </Column>
                        </Row>
                        {errorMessage}
						<Row>
							<Column>
								<div style={ { float: 'right', bottom: '0px' } }>
									<DefaultButton onClick={ this.onDismiss } text='Cancel' />
									<PrimaryButton onClick={ this.onOK } text='Create' disabled = {!cancomplete} />
								</div>
							</Column>
						</Row>
                    </Grid>
                </div>
            </Modal>
        );
    };
}
