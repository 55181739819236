// @flow
import React from 'react';
import type { Match, RouterHistory } from 'react-router-dom';
import { Grid, Row, Column } from './Grid';
import { Helmet } from 'react-helmet';
import { CommandBar } from 'office-ui-fabric-react';
import { TextField } from 'office-ui-fabric-react';
import { MessageBar, MessageBarType } from 'office-ui-fabric-react';
import { CompoundButton } from 'office-ui-fabric-react';
import { ModuleOption, ComboBoxModuleOption } from './ComboBoxModuleOption';
import { ClientList } from './ClientList.js';
import { AssignGroupDlg } from './AssignGroupDlg';
import { DeleteSelectedItemsDlg } from './DeleteSelectedItemsDlg';
import { DateTimePicker } from './DateTimePicker';
import API from './API.js';

type GroupProps = {
	match: Match,
	history: RouterHistory,
};
type GroupState = {
	loading: ?bool,
	display: string,
	note: string,
	members: string,
	group: any,
	users: Array<any>,
	moduleConfiguration: any,
	optionFileModule: string,
	optionInventoryModule: string,
	optionPSTModule: string,
	optionCustomModule: string,
	finalizeDate: ?Date,
	success: ?boolean,
	error: ?string,
	selectedItems: Array<any>,
	isModalSelection: boolean
};

export class Group extends React.Component<GroupProps, GroupState> {
	assignGroupDlg: ?AssignGroupDlg;
	deleteSelectedItemsDlg: ?DeleteSelectedItemsDlg;
	dateTimePicker: ?DateTimePicker;
	state = {
		loading: undefined,
		display: '',
		note: '',
		members: '',
		group: {},
		users: [],
		moduleConfiguration: {
			inventory: true,
			file: true,
			pst: true
		},
		optionFileModule: ModuleOption.unselected,
		optionInventoryModule: ModuleOption.unselected,
		optionPSTModule: ModuleOption.unselected,
		optionCustomModule: ModuleOption.unselected,
		finalizeDate: null,
		success: null,
		error: null,
		selectedItems: [],
		isModalSelection: false
	};
	onDelete = () => {
		const group = this.props.match.params.id || '';
		API.removeGroup(group).then(() => {
			this.props.history.push('/groups');
		}).catch(() => {});
	}
	onNameChanged = (ev: any, value: string) => {
		this.setState({ display: value });
	}
	onNoteChange = (ev: any, value: string) => {
		this.setState({note: value});
	}
	onMembersChanged = (ev: any, value: string) => {
		this.setState({ members: value });
	}
	onSave = () => {
		const group = this.props.match.params.id || '';
		const data = {
			IsLocked: this.state.group.IsLocked,
			DisplayName: this.state.display,
			Note: this.state.note,
			Finalize: this.state.finalizeDate,
			Members: this.state.members.split(',')
		};
		API.saveGroup(group, data).then(() => {
			if(group !== '*') {
				this.loadMembers();
			}
		}).catch(() => {});

		this.setState({ error: null, success: false });

		const moduleConfig = {
			file: this.state.optionFileModule,
			inventory: this.state.optionInventoryModule,
			pst: this.state.optionPSTModule,
			custom: this.state.optionCustomModule,
		};
		API.saveGroupModuleConfig(group, moduleConfig).then(() => {
			this.setState({ error: null, success: true });
		}).catch((error) => {
			this.setState({ error: API.errorMessage(error), success: false });
		});
	}
	onLock = () => {
		const group = this.props.match.params.id || '';
		if(group !== '*') {
			const data = {
				IsLocked: true,
				DisplayName: this.state.display,
				Finalize: this.state.finalizeDate,
				Members: this.state.members.split(',')
			};
			API.saveGroup(group, data).then((response) => {
				this.setState({
					error: null,
					success: true,
					group: response.data,
					display: response.data.DisplayName,
					finalizeDate: response.data.Finalize ? new Date(response.data.Finalize) : null,
					members: response.data.Members ? response.data.Members.join() : ''
				});
			}).catch((error) => {
				this.setState({ error: API.errorMessage(error), success: false });
			});
		}
	}
	onUnlock = () => {
		const group = this.props.match.params.id || '';
		if(group !== '*') {
			const data = {
				IsLocked: false,
				DisplayName: this.state.display,
				Finalize: this.state.finalizeDate,
				Members: this.state.members.split(',')
			};
			API.saveGroup(group, data).then((response) => {
				this.setState({
					error: null,
					success: true,
					group: response.data,
					display: response.data.DisplayName,
					finalizeDate: response.data.Finalize ? new Date(response.data.Finalize) : null,
					members: response.data.Members ? response.data.Members.join() : ''
				});
			}).catch((error) => {
				this.setState({ error: API.errorMessage(error), success: false });
			});
		}
	}
	loadMembers = () => {
		const group = this.props.match.params.id || '';
		API.groupMembers(group).then((response) => {
			this.setState({ users: response.data ? response.data : [] });
		}).catch(() => { });
	}
	onFileModuleChanged = (option: string) => {
		this.setState({ optionFileModule: option });
	}
	onInventoryModuleChanged = (option: string) => {
		this.setState({ optionInventoryModule: option });
	}
	onPSTModuleChanged = (option: string) => {
		this.setState({ optionPSTModule: option });
	}
	onCustomModuleChanged = (option: string) => {
		this.setState({ optionCustomModule: option });
	}
	onSelectionChanged = (selection: Array<any>) => {
		const groups = selection.map(it => it.ObjectID);
		this.setState({ selectedItems: groups });
	}
	onAssignedSelected = () => {
		if(this.assignGroupDlg) {
			this.assignGroupDlg.Show();
		}
	}
	onGroupAssigned = (groupId: string) => {
		const items = this.state.selectedItems.slice();
		if(this.assignGroupDlg) {
			this.assignGroupDlg.Dismiss();
		}
		this.setState({
			loading: true,
			isModalSelection: false,
			selectedItems: []
		});
		API.assignClientsToGroup(groupId, items).then(() => {
			this.loadMembers();
		}).catch((error) => { });
	}
	onDeleteSelected = () => {
		if(this.deleteSelectedItemsDlg) {
			this.deleteSelectedItemsDlg.Show();
		}
	}
	onDeleteSelectedItems = () => {
		const items = this.state.selectedItems.slice();
		if(this.deleteSelectedItemsDlg) {
			this.deleteSelectedItemsDlg.Dismiss();
		}
		this.setState({
			isModalSelection: false,
			selectedItems: []
		});
		API.removeClients(items).then(() => {
			this.loadMembers();
		}).catch((error) => { });
	}
	render() {
		const {display, members, group, users, moduleConfiguration, isModalSelection } = this.state;
		const {optionFileModule, optionInventoryModule, optionPSTModule, optionCustomModule} = this.state;
		const groupId = this.props.match.params.id || '';
		const document_title = (groupId === '*') ? 'Unassigned Users' : (group.DisplayName || group.ObjectID);

		let commandBarItems = [{
			key: 'save',
			name: 'Save',
			className: 'ms-CommandBarItem',
			iconProps: { iconName: 'Save' },
			onClick: this.onSave
		}];

		if(groupId !== '*') {
			if(group.IsLocked) {
				commandBarItems.push({
					key: 'unlock',
					name: 'Unlock',
					title: '',
					className: 'ms-CommandBarItem',
					iconProps: { iconName: 'Unlock' },
					onClick: this.onUnlock
				});
			} else {
				commandBarItems.push({
					key: 'lock',
					name: 'Lock',
					title: 'Lock the group to its current members',
					className: 'ms-CommandBarItem',
					iconProps: { iconName: 'Lock' },
					onClick: this.onLock
				});
			}
		}

		commandBarItems.push({
			key: 'select',
			name: isModalSelection ? 'Stop selection' : 'Start selection',
			className: 'ms-CommandBarItem',
			iconProps: { iconName: 'SelectAll' },
			onClick: () => { this.setState({ isModalSelection: !this.state.isModalSelection }) }
		});

		let commandBarFarItems = [];
		if(isModalSelection) {
			commandBarItems.push({
				key: 'assign-group',
				name: 'Assign to group',
				disabled: this.state.selectedItems.length === 0,
				className: 'ms-CommandBarItem',
				iconProps: { iconName: 'AddGroup' },
				onClick: this.onAssignedSelected
			});

			commandBarFarItems.push({
				key: 'delete-selected',
				name: 'Delete selected',
				disabled: this.state.selectedItems.length === 0,
				className: 'ms-CommandBarItem',
				iconProps: {
					iconName: 'Delete',
					style: {
						color: 'red'
					}
				},
				onClick: this.onDeleteSelected
			});
		}
		commandBarFarItems.push({
			key: 'delete',
			name: 'Delete',
			className: 'ms-CommandBarItem',
			iconProps: {
				iconName: 'Delete',
				style: {
					color: 'red'
				}
			},
			onClick: this.onDelete
		});

		return (
			<Grid>
				<Helmet>
					<title>realmigrator - Group {groupId}</title>
				</Helmet>        
				<Row>
					<Column>
						<h2>{document_title}</h2>
					</Column>
				</Row>
				<Row>
					<Column>
						{ this.state.error ? <MessageBar messageBarType={MessageBarType.error} isMultiline={true}>
              				Error - {this.state.error}
						</MessageBar> : null }
						{ this.state.success ? <MessageBar messageBarType={MessageBarType.success} isMultiline={false}>
              				Successfully saved group
						</MessageBar> : null }
						<CommandBar isSearchBoxVisible={ false } items={ commandBarItems } farItems={ commandBarFarItems }/>
						<AssignGroupDlg
							ref={ c => { this.assignGroupDlg = c; }}
							onFinish={this.onGroupAssigned}
						/>
						<DeleteSelectedItemsDlg
							ref={ c => { this.deleteSelectedItemsDlg = c; }}
							onDelete={ this.onDeleteSelectedItems }
						/>
					</Column>
				</Row>
				{ (groupId !== '*') ?
					<Row>
						<Column width={8}>
							<TextField label="Display Name" value={display} onChange={this.onNameChanged}/>
							<TextField label="Assigned Objects (separated by a comma)" multiline autoAdjustHeight value={members} disabled={group.IsLocked} onChange={this.onMembersChanged}/>
							<TextField label='Note' placeholder='Enter custom note' multiline rows={ 4 } value={this.state.note} onChange={this.onNoteChange}/>
						</Column>
						<Column width={4}>
						</Column>
					</Row> : null
				}
				<Row>
					<Column width={8}>
						<DateTimePicker ref={ (picker) => this.dateTimePicker = picker }
							date={ this.state.finalizeDate }
							onChanged={ (date: ?Date) => { this.setState({ finalizeDate: date }); } }
						/>
					</Column>
					<Column width={4}>
					</Column>
				</Row>
				<Row>
					<Column width={2}>
						<ComboBoxModuleOption
							label='Inventory Module'
							disabled={!moduleConfiguration.inventory}
							selectedKey={optionInventoryModule}
							onChange={this.onInventoryModuleChanged}
						/>
					</Column>
					<Column width={2}>
						<ComboBoxModuleOption
							label='File Module'
							disabled={!moduleConfiguration.file}
							selectedKey={optionFileModule}
							onChange={this.onFileModuleChanged}
						/>
					</Column>
					<Column width={2}>
						<ComboBoxModuleOption
							label='PST Module'
							disabled={!moduleConfiguration.pst}
							selectedKey={optionPSTModule}
							onChange={this.onPSTModuleChanged}
						/>
					</Column>
					<Column width={2}>
						<ComboBoxModuleOption
							label='Custom Module'
							disabled={!moduleConfiguration.custom}
							selectedKey={optionCustomModule}
							onChange={this.onCustomModuleChanged}
						/>
					</Column>
					<Column width={4}>
					</Column>
				</Row>
				<Row>
					<Column width={8}>
					</Column>
					<Column width={4}>
						<CompoundButton text='PST Import CSV'
							secondaryText='Click here to download the PST import mapping CSV.'
							href={'/api/admin/groups/'+groupId+'/pstimport'}
							iconProps={ { iconName: 'Download' } }
						/>
					</Column>
				</Row>
				<Row>
					<Column>
						<h3>Users (Total {users.length})</h3>
					</Column>
				</Row>
				<ClientList items={users} isModalSelection={isModalSelection} onSelectionChanged={this.onSelectionChanged}/>
			</Grid>
		);
	}
	componentDidMount() {
		const group = this.props.match.params.id || '';
		API.group(group).then((response) => {
			if(group === '*') {
				this.setState({
					group: response.data,
					finalizeDate: response.data.Finalize ? new Date(response.data.Finalize) : null,
				});
			} else {
				this.setState({
					group: response.data,
					display: response.data.DisplayName || '<EMPTY>',
					note: response.data.Note,
					finalizeDate: response.data.Finalize ? new Date(response.data.Finalize) : null,
					members: response.data.Members ? response.data.Members.join() : ''
				});
			}
			if(this.dateTimePicker) {
				this.dateTimePicker.setDate(response.data.Finalize ? new Date(response.data.Finalize) : null);
			}
		}).catch(() => {});

		API.groupModuleConfig(group).then((response) => {
			this.setState({
				optionFileModule: response.data.file,
				optionInventoryModule: response.data.inventory,
				optionPSTModule: response.data.pst,
				optionCustomModule: response.data.custom
			});
		}).catch(() => {});

		API.getModuleConfiguration().then((response) => {
			this.setState({ moduleConfiguration: response.data });
		}).catch(() => {});

		this.loadMembers();
	}
}

export default Group;
