// @flow
import axios from 'axios';

class Fetcher {
    cache = new Map();
    chunkArray = (arr, len) => {
        var chunks = [];
        var i = 0;
        var n = arr.length;

        while (i < n) {
            chunks.push(arr.slice(i, i += len));
        }
        return chunks;
    }
    fetch = (path: string) => {
        var res = this.cache.get(path);
        if (res === undefined) {
            res = axios.get<any, any>(path).then(response => {
                return response.data;
            });
            this.cache.set(path, res);
        }
        return res;
    }
    getModuleData = (module: string, user: string) => {
        return this.fetch(`/api/admin/module/${module}/${user}`);
    }
    fetchGroups = () => {
        return this.fetch(`/api/admin/groups`);
    }
    fetchGroup = (group_id: string) => {
        return this.fetch(`/api/admin/groups/${group_id}`);
    }
    fetchModuleConfiguration = (group_id: string) => {
        if (group_id) {
            return this.fetchGroup(group_id).then((group) => {
                return this.fetch(`/api/admin/groups/${group_id}/module`).then((config) => {
                    group.Config = config;
                    return [group];
                });
            });
        } else {
            return this.fetchGroups().then((groups) => {
                groups.push({
                    ObjectID: '*'
                });

                const chunk_size = 10;
                var responses = [];

                var chunks = this.chunkArray(groups, chunk_size);
                return chunks.reduce((chain, chunk) => {
                    return chain.then(res => {
                        var promises = [];
    
                        for (var i in chunk) {
                            const group = chunk[i];
                            let p = this.fetch(`/api/admin/groups/${group.ObjectID}/module`).then((config) => {
                                group.Config = config;
                                return group
                            });
                            promises.push(p);
                        }
        
                        return Promise.all(promises).then(data => {
                            for(var i in data) {
                                responses.push(data[i]);
                            }
                        });
                    });
                }, Promise.resolve()).then(res => {
                    return responses;
                });
            }).then(data => {
                var arr = [];
                for(var i in data) {
                    arr = arr.concat(data[i])
                }
                return arr;
            });
        }
    }
    fetchClients = (group_id: string) => {
        if (group_id) {
            return this.fetchGroup(group_id).then((group) => {
                return this.fetch(`/api/admin/groups/${group_id}/members`).then((clients) => {
                    for(var i in clients) {
                        clients[i].Group = group;
                    }
                    return clients;
                });
            });
        } else {
            return this.fetchGroups().then((groups) => {
                groups.push({
                    ObjectID: '*',
                    DisplayName: 'Unassigned Clients'
                });

                const chunk_size = 10;
                var responses = [];

                var chunks = this.chunkArray(groups, chunk_size);
                return chunks.reduce((chain, chunk) => {
                    return chain.then(res => {
                        var promises = [];
    
                        for (var i in chunk) {
                            const group = chunk[i];
                            let p = this.fetchClients(group["ObjectID"]).then((clients) => {
                                if(!clients) {
                                    return [];
                                }
                                for(var i in clients) {
                                    clients[i].Group = group;
                                }
                                return clients;
                            });
                            promises.push(p);
                        }
        
                        return Promise.all(promises).then(data => {
                            for(var i in data) {
                                responses.push(data[i]);
                            }
                        });
                    });
                }, Promise.resolve()).then(res => {
                    return responses;
                });
            }).then(data => {
                var arr = [];
                for(var i in data) {
                    arr = arr.concat(data[i])
                }
                return arr;
            });
        }
    }
    getModuleDataForGroup = (group_id: string, module: string, progressCB: any) => {
        return this.fetchClients(group_id).then((clients) => {
            const chunk_size = 10;
            var responses = [];

            var chunks = this.chunkArray(clients, chunk_size);
            return chunks.reduce((chain, chunk) => {
                return chain.then(res => {
                    var promises = [];

                    for (var i in chunk) {
                        const client = chunk[i];
                        const objectId = client["ObjectID"];
                        const displayName = client["DisplayName"];
                        const upn = client["UPN"];
                        const groupMemberId = client["GroupID"];
                        const groupId = client.Group ?  client.Group.ObjectID : '';
                        const groupName = client.Group ?  client.Group.DisplayName : '';
    
                        var p = this.getModuleData(module, objectId).then((response) => {
                            response.ObjectID = objectId;
                            response.DisplayName = displayName;
                            response.UPN = upn;
                            response.GroupMemberID = groupMemberId;
                            response.GroupID = groupId;
                            response.GroupName = groupName;
                            return response;
                        }).catch((error) => {
                            return {
                                ObjectID: objectId,
                                DisplayName: displayName,
                                UPN: upn,
                                GroupMemberID: groupMemberId,
                                GroupID: groupId,
                                GroupName: groupName
                            };
                        });
                        promises.push(p);
                    }
    
                    return Promise.all(promises).then(data => {
                        for(var i in data) {
                            responses.push(data[i]);
                        }
    
                        progressCB(chunk.length, clients.length);
                    });
                });
            }, Promise.resolve()).then(res => {
                return responses;
            });
        });
    }
    getModuleDataAndClientForGroup = (group_id: string, module: string, progressCB: any) => {
        return this.fetchClients(group_id).then((clients) => {
            const chunk_size = 10;
            var responses = [];

            var chunks = this.chunkArray(clients, chunk_size);
            return chunks.reduce((chain, chunk) => {
                return chain.then(res => {
                    var promises = [];

                    for (var i in chunk) {
                        const client = chunk[i];
                        const objectId = client["ObjectID"];
                        const displayName = client["DisplayName"];
                        const upn = client["UPN"];
                        const groupMemberId = client["GroupID"];
                        const groupId = client.Group ?  client.Group.ObjectID : '';
                        const groupName = client.Group ?  client.Group.DisplayName : '';
    
                        var p = this.getModuleData(module, objectId).then((response) => {
                            response.ObjectID = objectId;
                            response.DisplayName = displayName;
                            response.UPN = upn;
                            response.GroupMemberID = groupMemberId;
                            response.GroupID = groupId;
                            response.GroupName = groupName;
                            response.Client = client;
                            return response;
                        }).catch((error) => {
                            return {
                                ObjectID: objectId,
                                DisplayName: displayName,
                                UPN: upn,
                                GroupMemberID: groupMemberId,
                                GroupID: groupId,
                                GroupName: groupName
                            };
                        });
                        promises.push(p);
                    }
    
                    return Promise.all(promises).then(data => {
                        for(var i in data) {
                            responses.push(data[i]);
                        }
    
                        progressCB(chunk.length, clients.length);
                    });
                });
            }, Promise.resolve()).then(res => {
                return responses;
            });
        });
    }
}

export default Fetcher;
