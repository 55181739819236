// @flow
import React from 'react';
import { Helmet } from 'react-helmet';
import SwaggerUI from 'swagger-ui';
import Link from './Link';
import { Link as MSLink } from 'office-ui-fabric-react';
import 'swagger-ui/dist/swagger-ui.css';

export class ApiHelp extends React.Component<{}, {}> {
	render() {
		return (
			<div>
				<Helmet>
					<title>realmigrator - API Help</title>
				</Helmet>
				<div id="swagger-ui">
				</div>
			</div>
		);
	}
	componentDidMount() {
		SwaggerUI({
			url: 'openapi.yaml',
			dom_id: '#swagger-ui'
		});
	}
}

export const Help = () => (
	<div className="ms-Grid">
		<Helmet>
			<title>realmigrator - Help</title>
		</Helmet>
		<div className="ms-Grid-row">
			<div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
				<h1>Help</h1>
				<h2>Documentation</h2>
				<p>
					You can find the latest documentation on <MSLink href='https://docs.realmigrator.com'>https://docs.realmigrator.com</MSLink>
				</p>
				<h2>API</h2>
				<p>
					You can find the API documentation <Link href='/help/api' text='here' />.
				</p>
			</div>
		</div>
	</div>
);
