// @flow
import React from 'react';
import { Helmet } from 'react-helmet';
import { CommandBar } from 'office-ui-fabric-react';
import { Dialog, DialogType, DialogFooter } from 'office-ui-fabric-react';
import { PrimaryButton } from 'office-ui-fabric-react';
import { TextField } from 'office-ui-fabric-react';
import { MessageBar, MessageBarType } from 'office-ui-fabric-react';
import {Link} from 'office-ui-fabric-react';
import SortableList from './SortableList';
import API from './API.js';

type ProjectItemLinkArgs = {
	Project: string,
	Link: string
};
export const ProjectItemLink = (item: ProjectItemLinkArgs) => (
	<Link key={item.Project} href={item.Link}>{item.Project}</Link>
);

const columnDefinitions = [{
	key: 'Project',
	name: 'Project',
	fieldName: 'Project',
	isResizable: true,
	data: 'string',
	minWidth: 250,
	onRender: ProjectItemLink
},{
	key: 'Tenant',
	name: 'Tenant',
	fieldName: 'Tenant',
	isResizable: true,
	data: 'string',
	minWidth: 250
}];

type ProjectsState = {
	canAddProjects: boolean,
	hideDialog: boolean,
	path: string,
	type: string,
	items: Array<any>,
	project: string,
	tenant: string,
	error: ?string,
};

export class Projects extends React.Component<{}, ProjectsState> {
	state = {
		canAddProjects: false,
		hideDialog: true,
		path: '',
		type: '',
		items: [],
		project: '',
		tenant: '',
		error: null,
	};
	loadItems = () => {
		API.projects().then((response) => {
			this.setState({ items: response.data });
		}).catch(() => { });
	}
	onAdd = () => {
		this.setState({ hideDialog: false });
	}
	onProjectChanged = (ev: any, value: string) => {
		this.setState({project: value});
	}
	onTenantChanged = (ev: any, value: string) => {
		this.setState({tenant: value});
	}
	closeDialog = () => {
		this.setState({ hideDialog: true });
	}
	save = () => {
		API.saveProject(this.state.project, this.state.tenant).then(() => {
			this.setState({ error: null });
			this.closeDialog();
			this.loadItems();
		}).catch((error) => {
			this.setState({ error: API.errorMessage(error) });
		});
	}
	render() {
		var addProjectBar = null;
		if(this.state.canAddProjects) {
			addProjectBar = (
				<CommandBar isSearchBoxVisible={ false } items={ [{
					key: 'new',
					name: 'Add',
					className: 'ms-CommandBarItem',
					iconProps: { iconName: 'Add' },
					onClick: this.onAdd
				}]
				}
				/>
			);
		}

		const { items } = this.state;
		return (
			<div className="ms-Grid">
				<Helmet>
					<title>realmigrator - Projects</title>
				</Helmet>        
				<div className="ms-Grid-row">
					<div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
						<h2>Projects</h2>
					</div>
				</div>
				<div className="ms-Grid-row">
					<div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
						{ addProjectBar }
						<Dialog hidden={ this.state.hideDialog } onDismiss={ this.closeDialog }
							dialogContentProps={ {
								type: DialogType.largeHeader,
								title: 'Create Project'
							} }
							modalProps={ {
								isBlocking: false,
								containerClassName: 'ms-dialogMainOverride'
							} }
						>
							{ this.state.error ? <MessageBar messageBarType={MessageBarType.error} isMultiline={true}>{this.state.error}</MessageBar> : null }
							<TextField label='Project' required={ true } value={this.state.project } onChange={this.onProjectChanged}/>
							<TextField label='Tenant' suffix='.onmicrosoft.com' required={ true } value={this.state.tenant } onChange={this.onTenantChanged}/>
							<DialogFooter>
								<PrimaryButton onClick={ this.save } text='Save' />
							</DialogFooter>
						</Dialog>
					</div>
				</div>
				<div className="ms-Grid-row">
					<div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
						<SortableList columns={columnDefinitions} items={items}/>
					</div>
				</div>
			</div>
		);
	}
	componentDidMount() {
		API.config().then((response) => {
			this.setState({ canAddProjects : response.data.CanAddProjects });
			if(response.data.HasProjects) {
				this.loadItems();
			}
		}).catch(() => { });
	}
}

export default Projects;
