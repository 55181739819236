// @flow
import React from 'react';
import { DefaultButton, CompoundButton, IconButton } from 'office-ui-fabric-react';
import { Panel, PanelType, Persona } from 'office-ui-fabric-react';
import { TextField } from 'office-ui-fabric-react';
import { Label } from 'office-ui-fabric-react';
import CopyToClipboard from 'react-copy-to-clipboard';
import FeedbackDialog from './FeedbackDialog';
import API from './API.js';

type PanelAuthenticatedProps = {
	hasProjects: boolean,
	name: string,
	image: string,
	frontendVersion: string,
	serverVersion: string
};
type PanelAuthenticatedState = {
	showPanel: boolean,
	token: string,
};

class PanelAuthenticated extends React.Component<PanelAuthenticatedProps, PanelAuthenticatedState> {
	state = {
		showPanel: false,
		token: '',
	};
	dlgFeedback: any;
	Show = () => {
		this.setState({ showPanel: true });
	}
	onShowPanel = () => {
		this.setState({ showPanel: true });
	}
	onClosePanel = () => {
		this.setState({ showPanel: false });
	}
	onRefreshToken = () => {
		API.refreshToken().then(token => {
			this.setState({token: token});
		}).catch(() => { });
	}
	componentDidMount() {
		API.token().then(token => {
			this.setState({token: token});
		}).catch(() => { });
	}
	render() {
		let feedback = null;
		if(this.props.hasProjects) {
			feedback = (<div>
				<FeedbackDialog ref={ c => { this.dlgFeedback = c; }}/>
				<Label>Feedback</Label>
				<CompoundButton text='You can give feedback here' onClick={ () => {this.dlgFeedback.Show(); } }>Feedback</CompoundButton>
			</div>);
		}

		return (
			<Panel isOpen={this.state.showPanel} type={PanelType.smallFixedFar} onDismiss={this.onClosePanel} hasCloseButton={false}
				isLightDismiss={true}>
				<div>
					<Persona text={this.props.name} imageUrl={this.props.image} onClick={ this.onShowPanel }/>
				</div>
				{ feedback }
				<div>
					<TextField readOnly label="Token" value={this.state.token} multiline rows={9} resizable={false} borderless/>
					<IconButton iconProps={ { iconName: 'Refresh' } } onClick={this.onRefreshToken}/>
					<CopyToClipboard text={this.state.token}>
						<IconButton iconProps={ { iconName: 'Copy' } }/>
					</CopyToClipboard>
				</div>
				<div>
					<Label>Logout</Label>
					<DefaultButton primary={true} text='Logout' href="/admin/logout"/>
				</div>
				<div className="ms-font-xs" style={ { position: 'absolute', bottom: '10px' } }>
					<Label>Frontend Version: {this.props.frontendVersion}</Label>
					<Label>Server Version: {this.props.serverVersion}</Label>
				</div>
			</Panel>
		);
	}
}

export default PanelAuthenticated;
