// @flow
import './App.css';

import React from 'react';
import { Helmet } from 'react-helmet';
import { Router, /*Route,*/ Switch } from 'react-router-dom';
import { ApmRoute } from '@elastic/apm-rum-react'
import { Fabric } from 'office-ui-fabric-react';
import NavBar from './NavBar.js';
import SidebarMenu from './SidebarMenu';

import { version } from './version';
import apm from './apm';
import history from './history';
import API from './API';
import { Permission } from './API';
import Home from './Home';
import Binaries from './Binaries';
import Binary from './Binary';
import Clients from './Clients';
import Client from './Client';
import Config from './Config';
import Module from './Module';
import ModuleConfig from './ModuleConfig';
import Groups from './Groups';
import Group from './Group';
import Projects from './Projects';
import Reporting from './Reporting';
import {Help, ApiHelp} from './Help';
import type { Me } from './API';

type State = {
	isAuthenticated: bool,
	admin: Me
};

export class App extends React.Component<{}, State> {
	state = {
		isAuthenticated: false,
		admin: { }
	};
	componentDidMount() {
		API.me().then(admin => {
			apm.setUserContext({
				id: admin.ObjectID,
				username: admin.DisplayName,
				email: admin.UPN
			});

			this.setState({
				isAuthenticated: true,
				admin: admin
			});
		}).catch(() => {
			this.setState({ isAuthenticated: false });
		});
	}
	render() {
		const { isAuthenticated, admin } = this.state;
		let content = null;

		if(isAuthenticated) {
			if(admin.Permission === Permission.SelfAdmin) {
				content = (
					<Switch>
						<ApmRoute exact path="/" component={Home}/>
						<ApmRoute path="/clients/:id" component={Client}/>
						<ApmRoute path="/clients" component={Clients}/>
						<ApmRoute path="/help/api" component={ApiHelp}/>
						<ApmRoute path="/help" component={Help}/>
					</Switch>
				);
			} else {
				content = (
					<Switch>
						<ApmRoute exact path="/" component={Config}/>
						<ApmRoute path="/binaries/:id+" component={Binary}/>
						<ApmRoute path="/binaries" component={Binaries}/>
						<ApmRoute path="/config" component={Config}/>
						<ApmRoute path="/modules/:id" component={Module}/>
						<ApmRoute path="/modules" component={ModuleConfig}/>
						<ApmRoute path="/groups/:id" component={Group}/>
						<ApmRoute path="/groups" component={Groups}/>
						<ApmRoute path="/clients/:id" component={Client}/>
						<ApmRoute path="/clients" component={Clients}/>
						<ApmRoute path="/projects" component={Projects}/>
						<ApmRoute path="/reporting" component={Reporting}/>
						<ApmRoute path="/help/api" component={ApiHelp}/>
						<ApmRoute path="/help" component={Help}/>
					</Switch>
				);
			}
		}

		return (
			<Router history={history}>
				<Fabric className="App">
					<Helmet>
						<title>realmigrator</title>
					</Helmet>        
					<div className="header">
						<NavBar title="realmigrator"
								authenticated={isAuthenticated}
								name={admin.DisplayName}
								image={isAuthenticated ? '/admin/photo' : ''}
								tenant={admin.Tenant}
								version={version}
						/>
					</div>
					<div className="body">
						<div className="content">
							{ content }
						</div>
						<div className="sidebar">
							{ isAuthenticated ? <SidebarMenu permission={admin.Permission} /> : null }
						</div>
					</div>
				</Fabric>
			</Router>
		);
	}
}

export default App;
