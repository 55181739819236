// @flow
import axios from 'axios';
import type { AxiosPromise } from 'axios';

export const Permission = {
  NoPermission: 0,
  User: 1,
  UserElevated: 2,
  SelfAdmin: 3,
  ViewOnlyAdmin: 4,
  Admin: 5,
  SuperAdmin: 6,
};

export type Me = {
  ObjectID: string,
  DisplayName: string,
  UPN: string,
  Permission: number,
  Tenant: string,
};
export type Token = string;
export type Binaries = Array<{
  Path: string,
  Size: number,
}>;
export type Binary = {
  Etag: string,
  ContentMD5: string,
  ContentLength: number,
  ContentType: string,
  LastModified: string,
  Permission: string,
};
export type Config = {
  ClientVersion: string,
  ServerVersion: string,
  AllowedAdministrators: string,
  UserAdministrators: string,
  ViewOnlyAdministrators: string,
  SelfAdministrators: string,
  HasCustomClient: boolean,
  HasProjects: boolean,
  CanAddProjects: boolean,
  HasAdminConsent: boolean,
};

export class APIClient {
  _client: axios.Axios = axios.create({
    baseURL: '/api/',
    headers: {
      'X-Cache-Bypass': true,
    },
  });
  errorMessage(error: any): string {
    return error.response && error.response.data && error.response.data.message
      ? error.response.data.message
      : error.message;
  }
  me(): Promise<Me> {
    return this._client.get('admin/me').then((res) => {
      return res.data;
    });
  }
  token(): Promise<Token> {
    return this._client.get('admin/me/token').then((res) => {
      return res.data;
    });
  }
  refreshToken(): Promise<Token> {
    return this._client.put('admin/me/token').then((res) => {
      return res.data;
    });
  }
  binaries(): AxiosPromise<Binaries> {
    return this._client.get<Binaries, Binaries>('admin/binaries');
  }
  binary(path: string): AxiosPromise<Binary> {
    return this._client.get<any, any>(`admin/binaries/${path}`);
  }
  setBinary(path: string, options: any) {
    return this._client.put<any, any>(`admin/binaries/${path}`, options);
  }
  removeBinary(path: string) {
    return this._client.delete<any, any>(`admin/binaries/${path}`);
  }
  blob(path: string) {
    return this._client.get<any, any>(`blobs/${path}`);
  }
  uploadBlob(path: string, contentType: string, data: any) {
    const config = {
      headers: {
        'Content-Type': contentType,
      },
    };
    return this._client.put<any, any>(`admin/blobs/${path}`, data, config);
  }
  client(id: string) {
    return this._client.get<any, any>(`admin/clients/${id}`);
  }
  clientGroup(id: string) {
    return this._client.get<any, any>(`admin/clients/${id}/group`);
  }
  removeClient(id: string) {
    return this._client.delete<any, any>(`admin/clients/${id}`);
  }
  removeClients(clients: Array<string>) {
    return this._client.post<any, any>(`admin/clients`, clients, {
      params: {
        delete: true,
      },
    });
  }
  saveClient(id: string, data: any) {
    return this._client.put<any, any>(`admin/clients/${id}`, data);
  }
  clients() {
    return this._client.get<any, any>('admin/clients');
  }
  groups() {
    return this._client.get<any, any>('admin/groups');
  }
  addGroup(data: any) {
    return this._client.put<any, any>('admin/groups', data);
  }
  group(id: string) {
    return this._client.get<any, any>(`admin/groups/${id}`);
  }
  removeGroup(id: string) {
    return this._client.delete<any, any>(`admin/groups/${id}`);
  }
  removeGroups(groups: Array<string>) {
    return this._client.post<any, any>(`admin/groups`, groups, {
      params: {
        delete: true,
      },
    });
  }
  editGroups(groups: Array<string>, config: any) {
    const data = {
      groups: groups,
      config: config,
    };
    return this._client.post<any, any>(`admin/groups`, data, {
      params: {
        edit: true,
      },
    });
  }
  saveGroup(id: string, data: any) {
    return this._client.put<any, any>(`admin/groups/${id}`, data);
  }
  groupModuleConfig(id: string) {
    return this._client.get<any, any>(`admin/groups/${id}/module`);
  }
  saveGroupModuleConfig(id: string, config: any) {
    return this._client.put<any, any>(`admin/groups/${id}/module`, config);
  }
  groupMembers(id: string) {
    return this._client.get<any, any>(`admin/groups/${id}/members`);
  }
  moduleData(module: string, id: string) {
    return this._client.get<any, any>(`admin/module/${module}/${id}`);
  }
  config() {
    return this._client.get<Config, Config>('admin/config');
  }
  storageToken() {
    return this._client.get<any, any>('admin/config/storagetoken');
  }
  saveConfig(data: Config) {
    return this._client.put<any, any>('admin/config', data);
  }
  listToken() {
    return this._client.get<any, any>('admin/config/token');
  }
  generateToken(name: string, permission: number, expires: number) {
    const fd = new FormData();
    fd.append('name', name);
    fd.append('permission', permission);
    fd.append('expires', expires);

    return this._client.post<any, any>('admin/config/token', fd, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }
  revokeToken(id: string) {
    return this._client.delete<any, any>('admin/config/token/' + id);
  }
  log(id: string) {
    return this._client.get<any, any>(`admin/log/${id}`, {
      responseType: 'blob',
    });
  }
  removeLog(id: string) {
    return this._client.delete<any, any>(`admin/log/${id}`, {
      responseType: 'blob',
    });
  }
  feedback(page: string, subject: string, text: string) {
    const data = {
      page: page,
      subject: subject,
      text: text,
    };
    return this._client.post<any, any>('admin/feedback', data);
  }
  projects() {
    return this._client.get<any, any>('admin/projects');
  }
  saveProject(project: string, tenant: string) {
    const data = {
      Project: project,
      Tenant: tenant,
    };
    return this._client.put<any, any>('admin/projects', data);
  }

  module(module: string) {
    return this._client.get<any, any>(`blobs/config/config_${module}.chai`);
  }
  getModuleJSON(module: string) {
    return this.blob(`config/config_${module}.json`);
  }
  deleteModuleJSON(module: string) {
    return this._client.delete<any, any>(
      `admin/blobs/config/config_${module}.json`
    );
  }
  saveModuleJSON(module: string, data: any) {
    const config = {
      headers: {
        'Content-Type': 'text/plain',
      },
    };
    return this._client.put<any, any>(
      `admin/blobs/config/config_${module}.json`,
      JSON.stringify(data, null, 2),
      config
    );
  }
  saveModule(module: string, data: any) {
    const config = {
      headers: {
        'Content-Type': 'text/plain',
      },
    };
    return this._client.put<any, any>(
      `admin/blobs/config/config_${module}.chai`,
      data,
      config
    );
  }
  getModuleConfiguration() {
    return this._client.get<any, any>(`admin/config/module`);
  }
  setModuleConfiguration(data: any) {
    return this._client.put<any, any>(`admin/config/module`, data);
  }
  moduleConfig(module: string) {
    return this._client.get<any, any>(`admin/config/module/${module}`);
  }
  importConfig(contentType: string, data: any) {
    const config = {
      headers: { 'Content-Type': contentType },
    };
    return this._client.put<any, any>('admin/config/import', data, config);
  }
  importClient(file: string | Blob) {
    const config = {
      headers: { 'Content-Type': 'multipart/form-data' },
    };
    const formData = new FormData();
    formData.append('file', file);

    return this._client.put<any, any>('admin/client/import', formData, config);
  }
  deleteClient() {
    return this._client.delete<any, any>('admin/download');
  }
  rescheduleOnboarding(id: string) {
    return this._client.post<any, any>(
      `admin/clients/${id}/reschedule`,
      undefined,
      {
        params: {
          command: 'onboarding',
        },
      }
    );
  }
  reschedulePSTImport(id: string) {
    return this._client.post<any, any>(
      `admin/clients/${id}/reschedule`,
      undefined,
      {
        params: {
          command: 'pst-import',
        },
      }
    );
  }
  updatePSTImportStatus(id: string) {
    return this._client.post<any, any>(
      `admin/clients/${id}/reschedule`,
      undefined,
      {
        params: {
          command: 'pst-update',
        },
      }
    );
  }
  serverMigrationCommand(id: string) {
    return this._client.post<any, any>(
      `admin/clients/${id}/reschedule`,
      undefined,
      {
        params: {
          command: 'server-migration',
        },
      }
    );
  }
  assignClientsToGroup(group: string, clients: Array<string>) {
    const data = {
      group: group,
      clients: clients,
    };
    return this._client.post<any, any>(`admin/clients`, data, {
      params: {
        assign: true,
      },
    });
  }
  driveForGroup(hint: string, id: string) {
    return this._client.get<any, any>(
      `/admin/group/drive?client=${id}&hint=${hint}`
    );
  }
  createGroup(id: string, data: any) {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    return this._client.post<any, any>(
      `admin/group?client=${id}`,
      JSON.stringify(data, null, 2),
      config
    );
  }
}

export default new APIClient();
