// @flow
import React from 'react';
import { Helmet } from 'react-helmet';
import { ProgressIndicator, CommandBar } from 'office-ui-fabric-react';
import { AddGroupDlg } from './AddGroupDlg';
import { DeleteSelectedItemsDlg } from './DeleteSelectedItemsDlg';
import { SetGroupConfigBulkDlg } from './SetGroupConfigBulkDlg';
import SortableList from './SortableList';
import Link from './Link';
import API from './API.js';

type GroupItemLinkArgs = {
	ObjectID: string,
	DisplayName: string
};
export const GroupItemLink = (item: GroupItemLinkArgs) => (
	<Link key={item.ObjectID} href={'/groups/'+item.ObjectID} text={item.DisplayName||'<EMPTY>'}/>
);

const columnDefinitions = [{
	key: 'colName',
	name: 'Name',
	fieldName: 'DisplayName',
	isResizable: true,
	data: 'string',
	onRender: GroupItemLink
},{
	key: 'colModConfigInventory',
	name: 'Inventory',
	isResizable: true,
	onRender: (item) => (`${item.ModuleConfig.inventory||'n/a'}`)
},{
	key: 'colModConfigFile',
	name: 'File',
	isResizable: true,
	onRender: (item) => (`${item.ModuleConfig.file||'n/a'}`)
},{
	key: 'colModConfigPst',
	name: 'PST',
	isResizable: true,
	onRender: (item) => (`${item.ModuleConfig.pst||'n/a'}`)
},{
	key: 'colFinalize',
	name: 'Finalize Date',
	fieldName: 'Finalize',
	isResizable: true,
	data: 'date',
	minWidth: 150
}];

type GroupsState = {
	items: Array<any>,
	selectedItems: Array<any>,
	loading: boolean,
	isModalSelection: boolean
}

export class Groups extends React.Component<{}, GroupsState> {
	addGroupDlg: ?AddGroupDlg;
	setModuleConfigurationDlg: ?SetGroupConfigBulkDlg;
	deleteSelectedItemsDlg: ?DeleteSelectedItemsDlg;
	state = {
		items: [],
		selectedItems: [],
		loading: true,
		isModalSelection: false
	};
	loadItems = () => {
		this.setState({
			loading: true
		});
		Promise.all([
			API.groupModuleConfig('*'),
			API.groups()
		]).then((results) => {
			let items = results[1].data;
			items.push({
				ObjectID: '*',
				DisplayName: 'Unassigned Clients',
				ModuleConfig: results[0].data
			});
			this.setState({
				loading: false,
				items: items
			});
		}).catch(() => {
			this.setState({
				loading: false
			});
		});
	}
	onAdd = () => {
		if(this.addGroupDlg) {
			this.addGroupDlg.Show();
		}
	}
	onSelectionChanged = (selection) => {
		const groups = selection.map(it => it.ObjectID);
		this.setState({ selectedItems: groups });
	}
	onSetModuleConfiguration = () => {
		if(this.setModuleConfigurationDlg) {
			this.setModuleConfigurationDlg.Show();
		}
	}
	onModuleConfigurationFinished = (config) => {
		const items = this.state.selectedItems.slice();
		if(this.setModuleConfigurationDlg) {
			this.setModuleConfigurationDlg.Dismiss();
		}
		this.setState({
			loading: true,
			isModalSelection: false,
			selectedItems: []
		});
		API.editGroups(items, config).then(() => {
			this.setState({
				loading: false
			});
		}).catch((error) => { });
	}
	onDeleteSelected = () => {
		if(this.deleteSelectedItemsDlg) {
			this.deleteSelectedItemsDlg.Show();
		}
	}
	onDeleteSelectedItems = () => {
		const items = this.state.selectedItems.slice();
		if(this.deleteSelectedItemsDlg) {
			this.deleteSelectedItemsDlg.Dismiss();
		}
		this.setState({
			loading: true,
			isModalSelection: false,
			selectedItems: []
		});
		API.removeGroups(items).then(() => {
			this.loadItems();
		}).catch((error) => { });
	}
	render() {
		const { loading, items, isModalSelection } = this.state;
		let commandBarItems = [{
			key: 'new',
			name: 'Add',
			className: 'ms-CommandBarItem',
			iconProps: { iconName: 'Add' },
			onClick: this.onAdd
		}, {
			key: 'select',
			name: isModalSelection ? 'Stop selection' : 'Start selection',
			disabled: loading,
			className: 'ms-CommandBarItem',
			iconProps: { iconName: 'SelectAll' },
			onClick: () => { this.setState({ isModalSelection: !this.state.isModalSelection }) }
		}];
		let commandBarFarItems = [];

		if(isModalSelection) {
			commandBarItems.push({
				key: 'set-module-configuration',
				name: 'Set Module Configuration',
				disabled: loading || this.state.selectedItems.length === 0,
				className: 'ms-CommandBarItem',
				iconProps: { iconName: 'ConfigurationSolid' },
				onClick: this.onSetModuleConfiguration
			});
			commandBarFarItems.push({
				key: 'delete',
				name: 'Delete',
				disabled: loading || this.state.selectedItems.length === 0,
				className: 'ms-CommandBarItem',
				iconProps: {
					iconName: 'Delete',
					style: {
						color: 'red'
					}
				},
				onClick: this.onDeleteSelected
			});
		}

		return (
			<div className="ms-Grid">
				<Helmet>
					<title>realmigrator - Groups</title>
				</Helmet>        
				<div className="ms-Grid-row">
					<div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
						<h2>Groups {loading ? '' : `(Total ${items.length})`}</h2>
					</div>
				</div>
				<div className="ms-Grid-row">
					<div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
						{loading ? <ProgressIndicator label='Loading...'/> : null}
					</div>
				</div>
				<div className="ms-Grid-row">
					<div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
						<CommandBar isSearchBoxVisible={ false } items={ commandBarItems } farItems={ commandBarFarItems }/>
						<AddGroupDlg
							ref={ c => { this.addGroupDlg = c; }}
							onGroupAdded={this.loadItems}
						/>
						<SetGroupConfigBulkDlg
							ref={ c => { this.setModuleConfigurationDlg = c; }}
							onFinish={this.onModuleConfigurationFinished}
						/>
						<DeleteSelectedItemsDlg
							ref={ c => { this.deleteSelectedItemsDlg = c; }}
							onDelete={ this.onDeleteSelectedItems }
						/>
					</div>
				</div>
				<div className="ms-Grid-row">
					<div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
						<SortableList columns={columnDefinitions} items={items} isModalSelection={isModalSelection} onSelectionChanged={this.onSelectionChanged}/>
					</div>
				</div>
			</div>
		);
	}
	componentDidMount() {
		this.loadItems();
	}
}

export default Groups;
