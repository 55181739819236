// @flow
import React from 'react';
import { Helmet } from 'react-helmet';
import { Dialog, DialogType, DialogFooter } from 'office-ui-fabric-react';
import { PrimaryButton } from 'office-ui-fabric-react';
import { TextField } from 'office-ui-fabric-react';
import DropZone from './DropZone';
import filesize from 'filesize';
import SortableList from './SortableList';
import Link from './Link';
import API from './API.js';

type BinaryItemLinkArgs = {
	Path: string
};
export const BinaryItemLink = (item: BinaryItemLinkArgs) => (
	<Link key={item.Path} href={'/binaries/'+item.Path} text={item.Path}/>
);

type RenderFileSizeArgs = {
	Size: number
};
export const RenderFileSize = (item: RenderFileSizeArgs) => (
	filesize(item.Size)
);

const columnDefinitions = [{
	key: 'colPath',
	name: 'Path',
	fieldName: 'Path',
	isResizable: true,
	data: 'string',
	onRender: BinaryItemLink
},{
	key: 'colSize',
	name: 'Size',
	fieldName: 'Size',
	isResizable: true,
	data: 'number',
	onRender: RenderFileSize
}];

type BinariesState = {
	hideDialog: boolean,
	path: string,
	type: string,
	file: any,
	items: Array<any>,
	reader: ?FileReader,
}

export class Binaries extends React.Component<{}, BinariesState> {
	state = {
		hideDialog: true,
		path: '',
		type: '',
		file: { },
		items: [],
		reader: null,
	};
	onPathChanged = (ev: any, value: string) => {
		this.setState({path: value});
	}
	onMimeTypeChanged = (ev: any, value: string) => {
		this.setState({type: value});
	}
	closeDialog = () => {
		this.setState({
			hideDialog: true,
			path: '',
			type: '',
			file: { }
		});
	}
	onAddFile = (file: File, reader: FileReader) => {
		var type = file.type;
		if(!type && file.name.indexOf('.chai') !== -1) {
			type = 'text/plain';
		}
		this.setState({
			hideDialog: false,
			path: file.name,
			type: type,
			file: file,
			reader: reader
		});
	}
	uploadFile = () => {
		const { path, type, reader } = this.state;
		if(reader) {
			API.uploadBlob(path, type, reader.result).then(() => {
				this.closeDialog();
				this.loadItems();
			}).catch(() => { });
		}
	}
	loadItems = () => {
		API.binaries().then((response) => {
			this.setState({ items: response.data });
		}).catch(() => { });
	}
	render() {
		const { items, path, type } = this.state;

		return (
			<div className="ms-Grid">
				<Helmet>
					<title>realmigrator - Binaries</title>
				</Helmet>        
				<div className="ms-Grid-row">
					<div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
						<h2>Binaries</h2>
					</div>
				</div>
				<div className="ms-Grid-row">
					<div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
						<DropZone onDrop={this.onAddFile}>Drag &amp; drop files here or click here to browse for files.</DropZone>
						<Dialog hidden={ this.state.hideDialog } onDismiss={ this.closeDialog }
							dialogContentProps={ {
								type: DialogType.largeHeader,
								title: 'Upload Binary',
								subText: 'Please enter the details for the new binary.'
							} }
							modalProps={ {
								isBlocking: false,
								containerClassName: 'ms-dialogMainOverride'
							} }
						>
							<TextField label='File Path' required={ true } value={path} onChange={this.onPathChanged}/>
							<TextField label='Mime Type' required={ true } value={type} onChange={this.onMimeTypeChanged}/>
							<DialogFooter>
								<PrimaryButton onClick={ this.uploadFile } text='Upload' />
							</DialogFooter>
						</Dialog>
					</div>
				</div>
				<div className="ms-Grid-row">
					<div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
						<SortableList columns={columnDefinitions} items={items}/>
					</div>
				</div>
			</div>
		);
	}
	componentDidMount() {
		this.loadItems();
	}
}

export default Binaries;
