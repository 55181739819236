import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import {
  Pivot,
  PivotItem,
  ProgressIndicator,
  TextField,
} from 'office-ui-fabric-react';
import { DateTime } from 'luxon';
import { Grid, Row, Column } from './Grid';
import { ModuleData } from './ModuleData';
import API from './API.js';

type UploadAgentConfig = {
  groupsFilter: string;
};

const defaultConfig: UploadAgentConfig = {
  groupsFilter: '',
};

type UploadAgentRef = {
  GetConfig: () => UploadAgentConfig;
};

type UploadAgentProps = {
  userId: string;
  isUploadAgent: boolean;
  initialConfig: UploadAgentConfig;
};

// we are using a forward ref since the parent component uses a ref to access the get data function
// with all functional components this would be implemented with a callback, but here we are using
// the forward ref to allow the same old behaviour and not to over complicate things

export const UploadAgent = forwardRef<UploadAgentRef, UploadAgentProps>(
  ({ userId, isUploadAgent, initialConfig }, ref) => {
    const [state, setState] = useState({
      loading: true,
      config: initialConfig || defaultConfig,
      date: null,
      data: {},
    });
    const pivotRef = useRef(null);

    useImperativeHandle(ref, () => ({
      GetConfig: () => isUploadAgent ? state.config : undefined,
      current: pivotRef.current,
    }));

    useEffect(() => {
      API.moduleData('upload_agent', userId)
        .catch(() => {
          return { data: {}, headers: {} };
        })
        .then((response) => {
          setState({
            loading: false,
            config: initialConfig || defaultConfig,
            date: DateTime.fromHTTP(response.headers['last-modified']),
            data: response.data,
          });
        });
    }, [userId, initialConfig]);

    if (state.loading) {
      return <ProgressIndicator label='Loading...' />;
    }

    return (
      <Pivot ref={pivotRef}>
        {isUploadAgent && (
          <PivotItem headerText='Config'>
            <Grid>
              <Row>
                <Column width={2}>Group Filters</Column>
                <Column width={8}>
                  <TextField
                    label=''
                    value={state.config.groupsFilter}
                    onChange={(ev: any, value: string) => {
                      setState({
                        ...state,
                        config: {
                          ...state.config,
                          groupsFilter: value,
                        },
                      });
                    }}
                    description='A comma separated list of groups that will be processed by the upload agent. Wildcards are supported.'
                  />
                </Column>
              </Row>
            </Grid>
          </PivotItem>
        )}
        <PivotItem headerText='Data'>
          <ModuleData
            type='upload_agent'
            userId={userId}
            data={state.data}
            date={state.date}
          />
        </PivotItem>
      </Pivot>
    );
  }
);
