// @flow
import React from 'react';
import { ProgressIndicator } from 'office-ui-fabric-react';
import { Icon } from 'office-ui-fabric-react';
import SortableList from './SortableList';
import { DateTime } from 'luxon';
import Link from './Link';

type ProgressItem = {
	count: number,
	total: number,
	state: string,
};
type GetOverallProgressArg = {
	[string]: ProgressItem
}

export const GetOverallProgress = (progress: GetOverallProgressArg) => {
	var percent = 0.0;

	var keys = Object.keys(progress);
	if(keys.length > 0) {
		keys.forEach(key => {
			var element = progress[key];
			if((element.state !== 'skip') && element.total) {
				percent += element.count / element.total;
			} else {
				percent += 1.0;
			}
		});
		percent /= keys.length;
	}

	return percent;
};

type OverallProgressProps = {
	progress: any,
};
export const OverallProgress = (props: OverallProgressProps) => {
	const { progress } = props;
	var percent = (progress !== null && typeof progress === 'object')
		? GetOverallProgress(progress)
		: 0.0;
	return (
		<ProgressIndicator percentComplete={percent}/>
	);
};

type ClientItemLinkArgs = {
	DisplayName: ?string,
	UPN: ?string,
	ObjectID: string
};
export const ClientItemLink = (item: ClientItemLinkArgs) => (
	<Link key={item.UPN||item.ObjectID} href={'/clients/'+item.ObjectID} text={item.DisplayName||item.UPN||item.ObjectID}/>
);

type ClientItemArgs = {
	Status: {
		message: string,
		state: number,
		progress: GetOverallProgressArg
	},
	Timestamp: string
};
export const ClientItemMessage = (item: ClientItemArgs) => (
	<span>{item.Status ? item.Status.message : ''}</span>
);
export const ClientItemStatus = (item: ClientItemArgs) => {
	let icon = 'ProgressRingDots';
	if(item.Status) {
		if(item.Status.state === 1) { icon = 'Accept'; }
		else if(item.Status.state === 2) { icon = 'Error'; }
	}
	return (
		<Icon iconName={icon}/>
	);
};
export const ClientItemProgress = (item: ClientItemArgs) => (
	<OverallProgress progress={item.Status ? item.Status.progress : []}/>
);
export const ClientItemTimestamp = (item: ClientItemArgs) => (
	<span>{DateTime.fromISO(item.Timestamp).toFormat('yyyy-MM-dd TT')}</span>
);
export const GetStatusMessageValue = (item: ClientItemArgs) => {
	return item.Status ? item.Status.message : '';
};

export const GetStateValue = (item: ClientItemArgs) => {
	return item.Status ? item.Status.state : 0;
};

export const GetProgressValue = (item: ClientItemArgs) => {
	return (item.Status != null && typeof item.Status.progress === 'object') ? GetOverallProgress(item.Status.progress) : 0.0;
};

const columnDefinitions = [{
	key: 'colDisplayName',
	name: 'Client',
	iconName: 'Mail',
	fieldName: 'DisplayName',
	isRowHeader: true,
	isResizable: true,
	data: 'string',
	minWidth: 250,
	onRender: ClientItemLink
},{
	key: 'colStatus',
	name: 'Last Message',
	iconName: 'Message',
	isResizable: true,
	minWidth: 200,
	getValue: GetStatusMessageValue,
	onRender: ClientItemMessage
},{
	key: 'colState',
	name: 'Migration Status',
	isResizable: true,
	getValue: GetStateValue,
	onRender: ClientItemStatus
},{
	key: 'colProgress',
	name: 'Progress',
	iconName: 'ProgressRingDots',
	isResizable: true,
	minWidth: 100,
	getValue: GetProgressValue,
	onRender: ClientItemProgress
},{
	key: 'colTimestamp',
	name: 'Timestamp',
	iconName: 'AwayStatus',
	fieldName: 'Timestamp',
	isResizable: true,
	data: 'date',
	minWidth: 150,
	onRender: ClientItemTimestamp
},{
	key: 'colObjectID',
	name: 'Object ID',
	fieldName: 'ObjectID',
	isResizable: true,
	data: 'string',
	minWidth: 150
},{
	key: 'colGroup',
	name: 'Group ID',
	iconName: 'Group',
	fieldName: 'GroupID',
	isResizable: true,
	data: 'string',
	minWidth: 100
}];

type ClientListProps = {
	items: Array<any>,
	isModalSelection: boolean,
	onSelectionChanged: (Array<any>) => void
};

export const ClientList = (props: ClientListProps) => (
	<SortableList
		columns={columnDefinitions}
		items={props.items}
		isModalSelection={props.isModalSelection}
		onSelectionChanged={props.onSelectionChanged}
	/>
);
