// @flow
import React from 'react';
import { Helmet } from 'react-helmet';
import GraphQuery from './GraphQuery';

export class Reporting extends React.Component<{}, {}> {
	render() {
		return (
			<div className="ms-Grid">
				<Helmet>
					<title>realmigrator - Reporting</title>
				</Helmet>
				<div className="ms-Grid-row">
					<div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
						<h2>Reporting</h2>
					</div>
				</div>
				<GraphQuery/>
			</div>
		)
	}
}

export default Reporting;
