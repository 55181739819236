// @flow
import React from 'react';
import { Panel, PanelType } from 'office-ui-fabric-react';
import { Label, DefaultButton } from 'office-ui-fabric-react';

type PanelNotAuthenticatedProps = {
	version: string
};

const PanelNotAuthenticated = (props: PanelNotAuthenticatedProps) => (
	<Panel isOpen={true} type={PanelType.smallFixedFar} hasCloseButton={false} isLightDismiss={false} headerText="Login">
		<DefaultButton primary={true} text='Login' href="/admin/login"/>
		<div className="ms-font-xs" style={ { position: 'absolute', bottom: '10px' } }>
			<Label>Version: {props.version}</Label>
		</div>
	</Panel>
);

export default PanelNotAuthenticated;
