// @flow
import React from 'react';
import { Link as MSLink } from 'office-ui-fabric-react';
import history from './history';

type Props = {
	href: string,
	text: string,
};

const Link = (props: Props) => (
	<MSLink href={props.href} onClick={ (event) => {
		if(event) {
			if(event.ctrlKey || event.shiftKey || event.metaKey) {
				// do normal processing if control of shift key is clicked
				return;
			}
			event.nativeEvent.preventDefault();
		}
		history.push(props.href);
	} }>{props.text}</MSLink>
);

export default Link;
