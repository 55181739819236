// @flow
import React from 'react';
import { Pivot, PivotItem, ActionButton, ProgressIndicator } from 'office-ui-fabric-react';
import { AddCatalogItemDlg } from './AddCatalogItemDlg';
//import { CatalogList } from './CatalogList';
import { Catalogs } from './Catalogs';
import { ModuleData } from './ModuleData';
import { DateTime } from 'luxon';
import API from './API.js';
import type { SelectedPaths } from './TreeViewHelper';
import type { CatalogItem } from './AddCatalogItemDlg';

type ServerMigrationProps = {
    enableResetServerDefault: bool,
	userId: string,
	userData: SelectedPaths,
	onSelectionChanged: (selection: SelectedPaths) => void
};
type UserData = {
	catalogs: Array<CatalogItem>
}
type ServerMigrationState = {
	loaded: boolean,
	data: ?Blob,
	userData: UserData,
};

export class ServerMigrationClass extends React.Component<ServerMigrationProps, ServerMigrationState> {
	editCatalogItemDlg: ?AddCatalogItemDlg;
	state = {
		loaded: false,
		createTeams: false,
		finalizeCatalogs: false,
		stageOnedrive: false,
		stageSharepoint: false,
		sources: null,
		wholeData: null,
		date: null,
		userData: JSON.parse(JSON.stringify(this.props.userData)),
		tree: []
	};
	GetData = () => {
		return this.state.userData;
	}
	onUnmountForm = (editor: Form) => {
		this.setState({ catalogs: editor.getValue() });
	}
	onAdd = () => {
		if(this.editCatalogItemDlg) {
			this.editCatalogItemDlg.Show(-1, null);
		}
	}
	onDelete = (idx: number, item: CatalogItem) => {
		const { userData } = this.state;
		userData.catalogs.splice(idx, 1);;
		this.setState({ userData: userData });
	}
	onEdit = (idx: number, item: CatalogItem) => {
		if(this.editCatalogItemDlg) {
			this.editCatalogItemDlg.Show(idx, item);
		}
	}
	onItemAdded = (idx: number, item: ?CatalogItem) => {
		const { userData } = this.state;

		if(idx === -1) {
			if(userData.catalogs) {
				userData.catalogs.push(item);
			} else {
				userData.catalogs = [ item ];
			}
		} else {
			userData.catalogs[idx] = item;
		}

		if(this.editCatalogItemDlg) {
			this.editCatalogItemDlg.Dismiss();
		}

		this.setState({ userData: userData });
	}
	componentDidMount() {
		const { userId } = this.props;
		const promises = [
			API.blob(`config/config_custom.json`).catch(() => {return null}),
			API.moduleData('custom', userId).catch(() => {return null})
		];
		Promise.all(promises).then((responses) => {
			this.setState({
				loaded: true,
				createTeams: responses[0] && responses[0].data ? responses[0].data.createTeams : false,
				finalizeCatalogs: responses[0] && responses[0].data ? responses[0].data.finalizeCatalogs : false,
				stageOnedrive: responses[0] && responses[0].data ? responses[0].data.stageOnedrive : false,
				stageSharepoint: responses[0] && responses[0].data ? responses[0].data.stageSharepoint : false,
				sources: responses[1] && responses[1].data ? responses[1].data.sources : null,
				wholeData: responses[1] ? responses[1].data : null,
				date: DateTime.fromHTTP(responses[1] && responses[1].headers ? responses[1].headers['last-modified'] : '')
			});
		});
	}
	render() {
		const { userId } = this.props;
		const { loaded, sources, wholeData, userData, date } = this.state;

		if(!loaded) {
			return (
				<ProgressIndicator label='Loading...'/>
			);
		}

		let catalogs = [];
		if(userData && userData.catalogs) {
			catalogs = userData.catalogs;
		}

        return (
			<Pivot>
				<PivotItem headerText='Catalogs'>
					<AddCatalogItemDlg
						ref={ c => { this.editCatalogItemDlg = c; } }
						userId={userId}
						data={sources}
						teamCreate={this.state.createTeams}
						finalizeCatalogs={this.state.finalizeCatalogs}
						stageOnedrive={this.state.stageOnedrive}
						stageSharepoint={this.state.stageSharepoint}
						onOK={this.onItemAdded}
					/>
					<ActionButton
						text="Add catalog" iconProps={ { iconName: 'Add' } }
						onClick={this.onAdd}/>
					<Catalogs onDelete={this.onDelete} onEdit={this.onEdit} catalogs={catalogs} data={wholeData} />
				</PivotItem>
				<PivotItem headerText='Data'>
					<ModuleData type='custom' userId={userId} data={wholeData} date={date} />
				</PivotItem>
			</Pivot>
        );
	}
}

export const ServerMigration = ServerMigrationClass;
