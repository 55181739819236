// @flow
import React from 'react';
import { ProgressIndicator } from 'office-ui-fabric-react';
import { ErrorList } from './ErrorList';

type ClientProgressItemProps = {
	name: string,
	progress: {
		state: string,
		count: number,
		total: number,
		unit: string,
		errors: Array<string>
	}
};

export function ClientProgressItem(props: ClientProgressItemProps) {
	const label = `${props.name} (${props.progress.state})`;
	const desc = `${props.progress.count} of ${props.progress.total} ${props.progress.unit} complete.`;
	const percentComplete = (props.progress.total > 0.0) ? (props.progress.count / props.progress.total) : 0;
	return (
		<div>
			<ProgressIndicator label={label} description={ desc } percentComplete={ percentComplete }/>
			<ErrorList errors={props.progress.errors}/>
		</div>
	);
}
