// @flow
import React from 'react';
import { MessageBar, MessageBarType, ProgressIndicator } from 'office-ui-fabric-react';
//import { withTransaction } from '@elastic/apm-rum-react'
import API from './API.js';

type ClientLogProps = {
	userId: string
};
type ClientLogState = {
	loaded: boolean,
	err: ?string,
	date: ?string,
	data: ?Blob,
};

export class ClientLogImpl extends React.Component<ClientLogProps, ClientLogState> {
	constructor(props) {
        super(props);
		this.state = {
			loaded: false,
			err: null,
			date: null,
			data: null
		};
		this.deleteLog = () => {
			const { userId } = this.props;
			API.removeLog(userId).then(() => {
				this.setState({loaded: true, date: null, data: null, err: null });
			}).catch(() => {});
		}
	}
	componentDidMount() {
		const { userId } = this.props;

		API.log(userId).then((response) => {
			this.setState({
				loaded: true,
				date: response.headers ? response.headers['last-modified'] : '',
				data: response.data,
				err: null
			});
		}).catch((error) => {
			if(error.response && error.response.status === 404) {
				// not found is not an error, display log is empty banner
				this.setState({ loaded: true, date: null, data: null, err: null });
				return;
			}

			this.setState({ loaded: true, err: API.errorMessage(error), date: null, data: null });
		});
	}
	render() {
		const { loaded, err, data } = this.state;

		if(loaded) {
			if(err) {
				return (
					<MessageBar messageBarType={MessageBarType.error} isMultiline={true}>Failed to load client log</MessageBar>
				);
			}

			if(data && data.size !== 0) {
				const url = URL.createObjectURL(data);
				return (
					<iframe title="log file" src={url} style={ {width:'100%', height:'500px'} }/>
				);
			}

			return (
				<MessageBar messageBarType={MessageBarType.warning} isMultiline={true}>Client log is empty</MessageBar>
			);
		}

		return (
			<ProgressIndicator label='Loading...'/>
		);
	}
}

//export const ClientLog = withTransaction('ClientLog', 'component')(ClientLogImpl);
export const ClientLog = ClientLogImpl;
