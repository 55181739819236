// @flow
import React from 'react';
import { Modal, Text, TextField, Checkbox, ChoiceGroup, DefaultButton, IChoiceGroupOption,
		Pivot, PivotItem, PivotLinkFormat, PivotLinkSize,
		PrimaryButton, Label, getTheme} from 'office-ui-fabric-react';
import { Grid, Row, Column } from './Grid';
import TreeView from 'deni-react-treeview';
import { TreeItemEmpty, SelectedPathType, CHECKBOX_STATE, GetSelectedPaths, SetSelectedPaths, CreateTreeFromCatalog } from './TreeViewHelper';
import { TreeItem, SelectedPath } from './TreeViewHelper';
import './AddCatalogItemDlg.scss';
import API from './API.js';
import { CreateTeamsDlg } from './CreateTeamsDlg';
import { TeamsData } from './CreateTeamsDlg';

export const SourceType = {
	local: 'local',
	unc: 'unc'
};
export const DestinationType = {
	serverBlob: 'serverBlob',
	serverGraph: 'serverGraph',
	serverSharepoint: 'serverSharepoint'
};

type SourcePickerProps = {
	selected: string,
	onChange: (option: IChoiceGroupOption, evt?: SyntheticEvent<HTMLElement | HTMLInputElement>) => void
};
const SourcePicker = (props: SourcePickerProps) => (
	<ChoiceGroup
		label="Select a source"
		selectedKey={props.selected}
		options={ [{
				key: SourceType.local,
				iconProps: { iconName: 'CommandPrompt' },
				text: 'Local filesystem'
			}, {
				key: SourceType.unc,
				iconProps: { iconName: 'Server' },
				text: 'Network share'
			}] }
		onChange={props.onChange}
	/>
);

const DestinationPicker = (props: SourcePickerProps) => (
	<ChoiceGroup
		label="Select a destination"
		selectedKey={props.selected}
		options={ [{
				key: DestinationType.serverBlob,
				iconProps: { iconName: 'AzureLogo' },
				text: 'Blob storage'
			}, {
				key: DestinationType.serverGraph,
				iconProps: { iconName: 'OneDrive' },
				text: 'OneDrive'
			}, {
				key: DestinationType.serverSharepoint,
				iconProps: { iconName: 'TeamsLogo' },
				text: 'Teams'
			}] }
		onChange={props.onChange}
	/>
);

export type CatalogItemSourceType = $Keys<typeof SourceType>;
export type CatalogItemSourceLocal = {
	type: 'local',
	paths: Array<SelectedPath>,
};
export type CatalogItemSourceUNC = {
	type: 'unc',
	path: string,
	username?: string,
	password?: string,
};

export type CatalogItemDestinationType = $Keys<typeof DestinationType>;
export type CatalogItemDestinationServerBlob = {
	type: 'serverBlob',
	rootPath: string,
	diffFileSize?: number,
	diffBlockSize?: number,
};
export type CatalogItemDestinationOneDrive = {
	type: 'serverGraph',
	oneDriveUpn: string,
	rootPath: string,
};
export type CatalogItemDestinationSharepoint = {
	type: 'serverSharepoint',
	sharepointUrl: string,
	rootPath: string,
};

export type CatalogItem = {
	name: string,
	source: CatalogItemSourceLocal | CatalogItemSourceUNC,
	destination: CatalogItemDestinationServerBlob | CatalogItemDestinationOneDrive | CatalogItemDestinationSharepoint,
	filter: string,
	blackList: bool,
	finalize: bool,
};

const TeamsUrlState = {
	empty: 0,
	ok: 1,
	bad: 2,
	checking: 3
};

type AddCatalogItemDlgProps = {
	onOK: (index: number, item: ?CatalogItem) => void,
	userId: string,
	teamCreate: bool,
	finalizeCatalogs: bool,
	stageOnedrive: bool,
	stageSharepoint: bool,
	data: ?Blob
};

type AddCatalogItemDlgState = {
	hidden: boolean,
	tree: Array<TreeItem>,
	index: number,
	item: ?CatalogItem,
	name: string,
	filter: string,
	blacklist: bool,
	source: CatalogItemSourceType,
	selectedPaths: Array<SelectedPath>,
	destination: CatalogItemDestinationType,
	teamsUrlState: TeamsUrlState,
};

export class AddCatalogItemDlg extends React.Component<AddCatalogItemDlgProps, AddCatalogItemDlgState> {
    treeView: ?TreeView;
	createTeamsDlg: ?CreateTeamsDlg;
	state = {
		hidden: true,
		tree: this.createTree(),
		index: -1,
		item: null,
		name: '',
		filter: '.*|~*|*.tmp|pagefile.sys|hiberfile.sys|IconCache.db|desktop.ini',
		blacklist: true,
		finalize: false,
		source: SourceType.local,
		selectedPaths: [],
		destination: DestinationType.serverBlob,
		sharepointRootPath: '/General',
		teamsUrlState: TeamsUrlState.empty,
		teamsDesc: '',
	};
	Show = (index: number, item: ?CatalogItem) => {
		let state = {
			hidden: false,
			index: index,
			item: item,
			filter: '.*|~*|*.tmp|pagefile.sys|hiberfile.sys|IconCache.db|desktop.ini',
			blacklist: true,
			finalize: false,
			source: SourceType.local,
			selectedPaths: [],
			destination: DestinationType.serverBlob,
			sharepointRootPath: '/General',
			teamsUrlState: TeamsUrlState.empty,
			teamsDesc: '',
			tree: this.createTree(this.props.data),
		};

		if(item) {
			state.name = item.name;
			state.filter = item.filter;
			state.blacklist = item.blackList;
			state.finalize = item.finalize && this.props.finalizeCatalogs;

			if(item.source) {
				state.source = item.source.type;
				if(item.source.type === SourceType.local) {
					state.selectedPaths = item.source.paths;

					for(var i in state.tree) {
						SetSelectedPaths(state.tree[i], state.selectedPaths);
					}
				} else if(item.source.type === SourceType.unc) {
					state.path = item.source.path;
					state.username = item.source.username;
					state.password = item.source.password;
				}
			}

			if(item.destination) {
				state.destination = item.destination.type;
				state.rootPath = item.destination.rootPath;
				if(item.destination.type === DestinationType.serverGraph) {
					state.upn = item.destination.oneDriveUpn;
				} else if(item.destination.type === DestinationType.serverSharepoint) {
					state.sharepointRootPath = item.destination.rootPath;
					state.url = item.destination.sharepointUrl;
				}
			}
		} else {
			state.name = state.path = state.username = state.password = state.rootPath = state.url = state.upn = '';
		}

		this.setState(state);
	}
	Dismiss = () => {
		this.setState({
			hidden: true,
			index: -1,
			item: null
		});
	}
	onDismiss = () => {
		this.Dismiss();
	}
	onOK = () => {
		const { source, destination } = this.state;

		let item: CatalogItem = {
			name: this.state.name,
			filter: this.state.filter,
			blackList: this.state.blacklist,
		};

		if(source === SourceType.local) {
			let s: CatalogItemSourceLocal = {
				type: source,
				paths: this.state.selectedPaths
			};
			item.source = s;
		} else if(source === SourceType.unc) {
			const { path, username, password } = this.state;
			let s: CatalogItemSourceUNC = {
				type: source,
				path: path,
				username: username,
				password: password
			};
			item.source = s;
		}

		if(destination === DestinationType.serverBlob) {
			const { rootPath } = this.state;
			let d: CatalogItemDestinationServerBlob = {
				type: destination,
				rootPath: rootPath
			};
			item.destination = d;
			item.finalize = false;
		} else if(destination === DestinationType.serverGraph) {
			const { rootPath, upn } = this.state;
			let d: CatalogItemDestinationOneDrive = {
				type: destination,
				rootPath: rootPath,
				oneDriveUpn: upn
			};
			item.destination = d;
			item.finalize = this.state.finalize;
		} else if(destination === DestinationType.serverSharepoint) {
			const { sharepointRootPath, url } = this.state;
			let d: CatalogItemDestinationSharepoint = {
				type: destination,
				rootPath: sharepointRootPath,
				sharepointUrl: url
			};
			item.destination = d;
			item.finalize = this.state.finalize;
		}

        if(this.props.onOK) {
            this.props.onOK(this.state.index, item);
        }
    }
	createTree(sources: ?Blob): Array<TreeItem> {
		var tree = [ ];
		var key;

        if(sources) {
			var id = 1;
			if(sources.Drives) {
				const ti = {
					type: SelectedPathType.drive,
					id: id++,
					text: 'Drives',
					expanded: true,
					isLeaf: false,
					state: CHECKBOX_STATE.HIDDEN,
					children: []
				};
				for(key in sources.Drives) {
					const ci = sources.Drives[key];
					ti.children.push(CreateTreeFromCatalog(ci, ti.type, key, id++));
				}
				tree.push(ti);
			}
			if(sources.Paths) {
				const ti = {
					type: SelectedPathType.path,
					id: id++,
					text: 'Paths',
					expanded: true,
					isLeaf: false,
					state: CHECKBOX_STATE.HIDDEN,
					children: []
				};
				for(key in sources.Paths) {
					const ci = sources.Paths[key];
					ti.children.push(CreateTreeFromCatalog(ci, ti.type, key, id++));
				}
				tree.push(ti);
			}
			if(sources.Shares) {
				const ti = {
					type: SelectedPathType.share,
					id: id++,
					text: 'Shares',
					expanded: true,
					isLeaf: false,
					state: CHECKBOX_STATE.HIDDEN,
					children: []
				};
				for(key in sources.Shares) {
					const ci = sources.Shares[key];
					ti.children.push(CreateTreeFromCatalog(ci, ti.type, key, id++));
				}
				tree.push(ti);
			}
        } else {
			tree.push(TreeItemEmpty);
		}
		return tree;
	}
	onCheckTreeViewItem = () => {
		if(this.treeView) {
			const selection = GetSelectedPaths(this.treeView.api.getRootItem());
			this.setState({ selectedPaths: selection });
		}
	}
	onNameChanged = (event: SyntheticEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
		this.setState({name: newValue});
	}
	onFilterChange = (event: SyntheticEvent<HTMLInputElement | HTMLTextAreaElement>, value: string) => {
		this.setState({filter: value});
	}
	onBlacklistChange = (event: SyntheticEvent<HTMLInputElement | HTMLTextAreaElement>, value: bool) => {
		this.setState({blacklist: value});
	}
	onFinalizeChange = (event: SyntheticEvent<HTMLInputElement | HTMLTextAreaElement>, value: bool) => {
		this.setState({finalize: value});
	}
	onChangeSource = (ev: any, value: string) => {
		this.setState({source: value.key});
	}
	onChangeDestination = (ev: any, value: string) => {
		this.setState({destination: value.key});
	}
	onPathChanged = (event: SyntheticEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
		this.setState({path: newValue});
	}
	onUsernameChanged = (event: SyntheticEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
		this.setState({username: newValue});
	}
	onPasswordChanged = (event: SyntheticEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
		this.setState({password: newValue});
	}
	onRootPathChanged = (event: SyntheticEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
		this.setState({rootPath: newValue});
	}
	onSharepointRootPathChanged = (event: SyntheticEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
		this.setState({sharepointRootPath: newValue});
	}
	onUpnChanged = (event: SyntheticEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
		this.setState({upn: newValue});
	}
	onUrlChanged = (event: SyntheticEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
		this.setState({url: newValue});
	}
	checkTeam = (newValue?: string) => {
		if (newValue === "") {
			this.setState({url: newValue, teamsUrlState: TeamsUrlState.empty});
			return;
		}

		this.setState({url: newValue, teamsUrlState: TeamsUrlState.checking});
		API.driveForGroup(newValue, this.props.userId).then((response) => {
			this.setState({teamsUrlState: TeamsUrlState.ok, teamsDesc: 'OK, document library: ' + response.data.webUrl});
		}).catch((error) => {
			this.setState({teamsUrlState: TeamsUrlState.bad});
		});
	}
	onTeamChanged = (event: SyntheticEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
		this.checkTeam(newValue);
	}
	onTeamAdded = (team: ?TeamsData) => {
		this.setState({url: team.url, teamsUrlState: TeamsUrlState.ok, teamsDesc: 'Team successfully created'});
		if(this.createTeamsDlg) {
			this.createTeamsDlg.Dismiss();
		}
	}
	onNewTeam = () => {
		if(this.createTeamsDlg) {
			this.createTeamsDlg.Show();
		}
	}
	onRenderDescription = (props: ITextFieldProps): JSX.Element => {
		const theme = getTheme();
		return (
		  <Text variant="small" styles={{ root: { color: theme.palette.green } }}>
			{props.description}
		  </Text>
		);
	  };
	render() {
		const { hidden, tree, item, name, finalize, blacklist, filter, source, destination } = this.state;
		const title = item ? 'Edit catalog entry' : 'Add catalog entry';
		
		let canFinalize = false;
		let sourceForm = null;
		if(source === SourceType.local) {
			sourceForm = <TreeView style={ { width: '100%', height: '400px' } } ref={ c => { this.treeView = c; }}
							onCheckItem={this.onCheckTreeViewItem} items={tree} showCheckbox={true} />;
		} else if(source === SourceType.unc) {
			const { path, username, password } = this.state;
			sourceForm = (
				<>
					<TextField label='Path' description='UNC Path' required={ true } value={ path } onChange={this.onPathChanged}/>
					<TextField label='Username' description='The username which is used for authentication' value={ username } onChange={this.onUsernameChanged}/>
					<TextField label='Password' description='The password that authenticates the user' type="password" value={ password } onChange={this.onPasswordChanged}/>
				</>
			);
		}

		let destinationForm = null;
		if(destination === DestinationType.serverBlob) {
			const { rootPath } = this.state;
			destinationForm = <TextField label='Path' description='Optional path on target blob store store, e.g. "/realmigrator"' required={ true } value={ rootPath } onChange={this.onRootPathChanged}/>;
		} else if(destination === DestinationType.serverGraph) {
			canFinalize = this.props.finalizeCatalogs && this.props.stageOnedrive;
			const { rootPath, upn } = this.state;
			destinationForm = (
				<>
					<TextField label='Path' description='Optional path in the target OneDrive "/realmigrator"' required={ true } value={ rootPath } onChange={this.onRootPathChanged}/>
					<TextField label='UPN' description="UPN of user's OneDrive. You can leave this field empty and use the GetUPNforPath() script breakout for each direct subfolder (home share mode)." required={ true } value={ upn } onChange={this.onUpnChanged}/>
				</>
			);
		} else if(destination === DestinationType.serverSharepoint) {
			canFinalize = this.props.finalizeCatalogs && this.props.stageSharepoint;
			const { sharepointRootPath, url, teamsUrlState, teamsDesc} = this.state;
			let teamState;
			let createButton = null;
			let teamWidth = 12;

			if (this.props.teamCreate) {
				teamWidth = 10;
				createButton = (<Column width={2}>
									<div style={ { float: 'right'} }>
										<PrimaryButton onClick={ this.onNewTeam } text='Create...' />
									</div>
								</Column>);
			}
			switch(teamsUrlState) {
				case TeamsUrlState.empty:
				default:
					teamState = (<TextField description='Please enter a teams channel url, e.g. "https://teams.microsoft.com/l/channel/..", or the teams group id.' value={ url } onChange={this.onTeamChanged}/>);
					break;
				case TeamsUrlState.ok:
					teamState = (<TextField description={teamsDesc} value={ url } onChange={this.onTeamChanged} onRenderDescription={this.onRenderDescription}/>);
					break;
				case TeamsUrlState.bad:
					teamState = (<TextField errorMessage='Team does not exist' description='Please enter a teams channel url, e.g. "https://teams.microsoft.com/l/channel/..", or the teams group id.' value={ url } onChange={this.onTeamChanged}/>);
					break;
				case TeamsUrlState.checking:
					teamState = (<TextField description='checking..' value={ url } onChange={this.onTeamChanged}/>);
					break;
			}

			destinationForm = (
				<Grid alignItems="center">
					<Row>
						<Column>
							<TextField label='Path' description='Optional path in the target document library "/realmigrator"' required={ true } value={ sharepointRootPath } onChange={this.onSharepointRootPathChanged}/>
						</Column>
					</Row>
					<Row>
						<Column>
							<Label>Team</Label>
						</Column>
					</Row>
					<Row>
						<Column width={teamWidth}>
							{teamState}
						</Column>
						{createButton}
					</Row>
				</Grid>
			);
		}

		let nameForm = null;

		if (item && canFinalize)  {
			nameForm = (<>
				<Column width={10}>
					<TextField description='' value={ name } onChange={this.onNameChanged}/>
				</Column>
				<Column width={2}>
					<Checkbox label='Finalize Catalog' defaultChecked={finalize} onChange={this.onFinalizeChange} />
				</Column>
			</>);	
		} else {
			nameForm = (
				<Column>
					<TextField description='' value={ name } onChange={this.onNameChanged}/>
				</Column>
			);	
		}

		return (
			<Modal
				isOpen={ !hidden }
				onDismiss={ this.Dismiss }
				containerClassName="AddCatalogItemDlgContainer"
			>
				<CreateTeamsDlg
					ref={ c => { this.createTeamsDlg = c; } }
					userId={this.props.userId}
					onOK={this.onTeamAdded}
				/>
				<div className="AddCatalogItemDlgHeader">
					<span>{title}</span>
				</div>
				<div className="AddCatalogItemDlgBody">
					<Grid>
						<Row>
							<Column>
								<Label>Name</Label>
							</Column>
							{nameForm}
						</Row>
						<Row>
							<Column>
								<Label>Filter</Label>
							</Column>
						</Row>
						<Row>
							<Column width={10}>
								<TextField description='A pipe-delimited list of file names that should be in- or excluded from being copied.' required={ false } value={ filter } onChange={ this.onFilterChange }/>
							</Column>
							<Column width={2}>
								<Checkbox label='Blacklist' defaultChecked={blacklist} onChange={this.onBlacklistChange} />
							</Column>
						</Row>
						<Row>
							<Column>
							<div style={ { float: 'right', height: '30px' } }>
								</div>
							</Column>
						</Row>
						<Row>
							<Column>
								<Pivot linkFormat={PivotLinkFormat.tabs} linkSize={PivotLinkSize.large}>
									<PivotItem headerText='Source'>
										<SourcePicker selected={source} onChange={this.onChangeSource}/>
										{sourceForm}
									</PivotItem>
									<PivotItem headerText='Destination'>
										<DestinationPicker selected={destination} onChange={this.onChangeDestination}/>
										{destinationForm}
									</PivotItem>
								</Pivot>
							</Column>
						</Row>
						<Row>
							<Column>
								<div style={ { float: 'right', bottom: '0px' } }>
									<DefaultButton onClick={ this.onDismiss } text='Cancel' />
									<PrimaryButton onClick={ this.onOK } text='OK' />
								</div>
							</Column>
						</Row>
					</Grid>
				</div>
			</Modal>
		);
	}
}
