// @flow
import React from 'react';

const Home = () => (
	<div className="ms-Grid">
		<div className="ms-Grid-row">
			<div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
				<h2>Home</h2>
			</div>
		</div>
	</div>
);

export default Home;
