// @flow
import React from 'react';
import { Dialog, DialogType, DialogFooter } from 'office-ui-fabric-react';
import { PrimaryButton } from 'office-ui-fabric-react';
import { TextField } from 'office-ui-fabric-react';
import { MessageBar, MessageBarType } from 'office-ui-fabric-react';
import API from './API.js';

type AddGroupDlgProps = {
	onGroupAdded: () => void
};

type AddGroupDlgState = {
	hidden: boolean,
	error: ?string,
	group: string,
};

export class AddGroupDlg extends React.Component<AddGroupDlgProps, AddGroupDlgState> {
	state = {
		hidden: true,
		error: undefined,
		group: ''
	};
	Show = () => {
		this.setState({ hidden: false });
	}
	Dismiss = () => {
		this.setState({
			group: '',
			error: null,
			hidden: true
		});
	}
	onGroupChanged = (ev: any, value: string) => {
		this.setState({group: value});
	}
	onDismiss = () => {
		this.setState({ hidden: true });
	}
	onSave = () => {
		const data = {
			DisplayName: this.state.group,
		};
		API.addGroup(data).then(() => {
			this.setState({ error: null });
			this.Dismiss();
			if(this.props.onGroupAdded) {
				this.props.onGroupAdded();
			}
		}).catch((error) => {
			this.setState({ error: API.errorMessage(error) });
		});
	}
	render() {
		const { hidden, error, group } = this.state;
		const contentProps = {
			type: DialogType.largeHeader,
			title: 'Create Group'
		};
		const modalProps = {
			isBlocking: false,
			containerClassName: 'ms-dialogMainOverride'
		};
		return (
			<Dialog
				hidden={ hidden }
				onDismiss={ this.Dismiss }
				dialogContentProps={ contentProps }
				modalProps={ modalProps }
			>
				{ error ? <MessageBar messageBarType={MessageBarType.error} isMultiline={true}>{error}</MessageBar> : null }
				<TextField label='Group' required={ true } value={ group } onChange={this.onGroupChanged}/>
				<DialogFooter>
					<PrimaryButton onClick={ this.onSave } text='Save' />
				</DialogFooter>
			</Dialog>
		);
	}
}
