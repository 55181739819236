// @flow
import React from 'react';
import { Helmet } from 'react-helmet';
import { ComboBox, Toggle } from 'office-ui-fabric-react';
import { Grid, Row, Column } from './Grid';
import API from './API.js';

type ModuleConfigState = {
  inventory: boolean,
  file: boolean,
  pst: boolean,
  custom: string,
  server: boolean,
  upload_agent: boolean,
};

const customModuleOptions = [
  { key: '', text: 'Off' },
  { key: 'custom', text: 'Custom' },
  { key: 'file', text: 'File' },
  { key: 'pst', text: 'PST' },
  { key: 'servermigration', text: 'Server Migration' },
];

class ModuleConfig extends React.Component<{}, ModuleConfigState> {
  state = {
    inventory: true,
    file: true,
    pst: true,
    custom: '',
    server: false,
    upload_agent: false,
  };
  onChangeInventory = (ev: any, checked: boolean) => {
    this.setState({ inventory: checked });
  };
  onChangeFile = (ev: any, checked: boolean) => {
    this.setState({ file: checked });
  };
  onChangePST = (ev: any, checked: boolean) => {
    this.setState({ pst: checked });
  };
  onChangeCustom = (ev: any, option: any) => {
    if (option !== undefined) {
      this.setState({ custom: option.key });
    }
  };
  onChangeServer = (ev: any, checked: boolean) => {
    this.setState({ server: checked });
  };
  onChangeUploadAgent = (ev: any, checked: boolean) => {
    this.setState({ upload_agent: checked });
  };
  componentDidUpdate() {
    API.setModuleConfiguration(this.state);
  }
  render() {
    const { inventory, file, pst, custom, server } = this.state;
    const uploadAgent = this.state.upload_agent;

    return (
      <Grid>
        <Helmet>
          <title>realmigrator - Module Configuration</title>
        </Helmet>
        <Row>
          <Column>
            <h2>Module Configuration</h2>
          </Column>
        </Row>
        <Row>
          <Column width={3}>
            <Toggle
              checked={inventory}
              label='Inventory Module'
              onText='On'
              offText='Off'
              onChange={this.onChangeInventory}
            />
          </Column>
          <Column width={3}>
            <Toggle
              checked={file}
              label='File Module'
              onText='On'
              offText='Off'
              onChange={this.onChangeFile}
            />
          </Column>
          <Column width={3}>
            <Toggle
              checked={pst}
              label='PST Module'
              onChange={this.onChangePST}
            />
          </Column>
          <Column width={3}></Column>
        </Row>
        <Row>
          <Column width={3}>
            <ComboBox
              selectedKey={custom}
              label='Custom Module'
              onChange={this.onChangeCustom}
              allowFreeform={false}
              options={customModuleOptions}
            />
          </Column>
          <Column width={3}>
            <Toggle
              checked={server}
              label='Server Module'
              onText='On'
              offText='Off'
              onChange={this.onChangeServer}
            />
          </Column>
          <Column width={3}>
            <Toggle
              checked={uploadAgent}
              label='Upload Agent'
              onText='On'
              offText='Off'
              onChange={this.onChangeUploadAgent}
            />
          </Column>
          <Column width={3}></Column>
        </Row>
      </Grid>
    );
  }
  componentDidMount() {
    API.getModuleConfiguration()
      .then((response) => {
        this.setState(response.data);
      })
      .catch(() => {});
  }
}

export default ModuleConfig;
