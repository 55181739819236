// @flow
import React from 'react';
import { ComboBox } from 'office-ui-fabric-react';
import API from './API.js';

export type GroupSelectorProps = {
	onSelectedGroupChanged: (string) => void,
	defaultSelectedKey: string,
	includeAllClientsGroup: boolean,
	includeUnassignedClientsGroup: boolean
};
export type GroupSelectorState = {
	options: Array<any>
};

export class GroupSelector extends React.Component<GroupSelectorProps, GroupSelectorState> {
	unassignedGroup = {
		key: '*',
		text: 'Unassigned Clients'
	};
	allGroup = {
		key: 'ALL',
		text: 'All Clients'
	};
	state = {
		options: [this.unassignedGroup]
	};
	onSelectionChanged = (ev: any, option: any) => {
		if(this.props.onSelectedGroupChanged) {
			this.props.onSelectedGroupChanged(option.key);
		}
	}
	componentDidMount() {
		API.groups().then((response) => {
			var items = this.props.includeUnassignedClientsGroup ? [this.unassignedGroup] : [];

			if(Array.isArray(response.data)) {
				response.data.sort((a, b) => {
					return a.DisplayName.toLowerCase().localeCompare(b.DisplayName.toLowerCase());
				});

				for(var i in response.data) {
					items.push({
						key: response.data[i].ObjectID,
						text: response.data[i].DisplayName
					});
				}
			}

			if(this.props.includeAllClientsGroup) {
				items.push(this.allGroup);
			}

			this.setState({ options: items });
		}).catch(() => { });
	}
	render() {
		return (
			<ComboBox label="Group" options={this.state.options} defaultSelectedKey={this.props.defaultSelectedKey} onChange={this.onSelectionChanged}/>
		);
	}
}
