const resetCode = {
  title: 'Reset code',
  description: 'Discard saved module settings if reset code does not match',
  required: true,
  type: 'string',
};
const powerMode = {
  title: 'Power mode',
  description:
    'If true, prevent idle power down / hibernation when realmigrator is running',
  required: true,
  type: 'boolean',
  format: 'checkbox',
  default: false,
};

const uploadAgent = {
  title: 'Upload agent',
  description:
    'If true, this catalog will be processed by an upload agent',
  required: true,
  type: 'boolean',
  format: 'checkbox',
  default: false,
};

const uiMode = {
  title: 'Finalize UI Mode',
  type: 'integer',
  required: true,
  options: {
    enum_titles: ['No UI', 'Tray and progress', 'Fullscreen'],
  },
  enum: [0, 1, 2],
  default: 0,
};

const uiLanguages = {
  title: 'Language',
  type: 'string',
  required: true,
  options: {
    enum_titles: [
      'Arabic',
      'Chinese',
      'English',
      'French',
      'German',
      'Hindi',
      'Japanese',
      'Portuguese',
      'Spanish',
    ],
  },
  enum: ['ar', 'cn', 'en', 'fr', 'de', 'hi', 'ja', 'pt', 'es'],
  default: 'en',
};

const uiSettings = {
  title: 'UI Settings',
  type: 'object',
  options: {
    collapsed: true,
    disable_edit_json: true,
    disable_properties: true,
  },
  properties: {
    language: uiLanguages,
    uiVersion: {
      title: 'UI Version',
      type: 'integer',
      description: 'Increment if clients should reload UI components',
      default: 0,
    },
    startTitle: {
      title: 'Start title',
      type: 'string',
      format: 'text',
      description: '',
      default: 'Finalizing your migration to the cloud',
    },
    startText: {
      title: 'Start text',
      type: 'string',
      format: 'textarea',
      description: '',
      default:
        'Today, we want to finish your migration into the cloud.<br><br>If you have any questions, please ask <a href="https://www.google.de">Your Helpdesk</a>.',
    },
    postponeText: {
      title: 'Postpone text',
      type: 'string',
      format: 'textarea',
      description: '',
      default:
        'If you need your PC now, you can postpone the final migration for an hour.',
    },
    postponeButton: {
      title: 'Postpone button',
      type: 'string',
      format: 'text',
      description: '',
      default: 'Postpone',
    },
    postponeTime: {
      title: 'Postpone time',
      type: 'integer',
      description: 'Postpone time in minutes',
      default: 60,
    },
    postponeCount: {
      title: 'Postpone count',
      type: 'integer',
      description:
        'Postpone up to n times. If empty: no limit, zero: no postpone',
      default: 10,
    },
    idleTimeout: {
      title: 'Idle timeout',
      type: 'integer',
      description:
        'Idle timeout in seconds. If the user did not click continue/postpone, wizard will continue. If empty/zero: no timeout',
      default: 600,
    },
    progressTitle: {
      title: 'Progress title',
      type: 'string',
      format: 'text',
      description: '',
      default: 'Migration in progress',
    },
    progressText: {
      title: 'Progress text',
      type: 'string',
      format: 'textarea',
      description: '',
      default: "Please don't turn off this PC.",
    },
    errorTitle: {
      title: 'Error title',
      type: 'string',
      format: 'text',
      description: '',
      default: 'Errors occured during migration',
    },
    errorText: {
      title: 'Error text',
      type: 'string',
      format: 'textarea',
      description: '',
      default:
        'Migration finished, but some errors occured.<br>Please review the errors and decide how to proceed.<br><br>Press Continue to proceed with errors, or Retry.',
    },
    errorNextButton: {
      title: 'Error next button',
      type: 'string',
      format: 'text',
      description: '',
      default: 'Continue',
    },
    errorRetryButton: {
      title: 'Error retry button',
      type: 'string',
      format: 'text',
      description: '',
      default: 'Retry',
    },
    finishTitle: {
      title: 'Finish title',
      type: 'string',
      format: 'text',
      description: '',
      default: 'Migration finished',
    },
    finishText: {
      title: 'Finish text',
      type: 'string',
      format: 'textarea',
      description: '',
      default: 'Your migration finished.',
    },
    finishButton: {
      title: 'Finish button',
      type: 'string',
      format: 'text',
      description:
        'Text of finish button. Leave empty if button should not be displayed.',
      default: 'Finish',
    },
    migrateUiMode: {
      title: 'Migrate UI Mode',
      type: 'integer',
      required: true,
      options: {
        enum_titles: ['No UI', 'Tray and progress', 'Fullscreen'],
      },
      enum: [0, 1, 2],
      default: 0,
    },
    migrateTitle: {
      title: 'Migrate title',
      type: 'string',
      format: 'text',
      description: '',
      default: 'Preparing your migration to the cloud',
    },
    migrateText: {
      title: 'Migrate text',
      type: 'string',
      format: 'textarea',
      description: '',
      default:
        'We currently prepare your PC for your cloud migration.<br><br>If you have any questions, please ask <a href="https://www.google.de">Your Helpdesk</a>.',
    },
    migrateAppointment: {
      title: 'Migrate appointment',
      type: 'string',
      format: 'textarea',
      description: '',
      default: '<br><br>The final migration is scheduled on:',
    },
    confirmUiMode: {
      title: 'Confirm UI Mode',
      type: 'integer',
      required: true,
      options: {
        enum_titles: ['No UI', 'Tray and progress', 'Fullscreen'],
      },
      enum: [0, 1, 2],
      default: 0,
    },
    confirmTitle: {
      title: 'Confirm title',
      type: 'string',
      format: 'text',
      description: '',
      default: 'Confiramtion Title',
    },
    confirmText: {
      title: 'Confirm text',
      type: 'string',
      format: 'textarea',
      description: '',
      default:
        "Confirmation text<br><br>Click 'Agree' if you have reviewed, understand, and agree to comply with the above policy.",
    },
    confirmButton: {
      title: 'Confirm button',
      type: 'string',
      format: 'text',
      description: '',
      default: 'Agree',
    },
    confirmPostponeButton: {
      title: 'Confirm postpone button',
      type: 'string',
      format: 'text',
      description: '',
      default: 'Later',
    },
    confirmPostponeTime: {
      title: 'Confirm postpone time',
      type: 'integer',
      description: 'Postpone time in minutes',
      default: 60,
    },
    confirmPostponeCount: {
      title: 'Confirm postpone count',
      type: 'integer',
      description:
        'Postpone up to n times. If empty: no limit, zero: no postpone',
      default: 10,
    },
  },
};

const languageCatalogs = {
  type: 'array',
  title: 'Additional Languages',
  required: true,
  options: {
    disable_collapse: true,
    disable_edit_json: true,
    disable_properties: true,
  },
  items: {
    type: 'object',
    title: 'Language',
    headerTemplate: '{{ i1 }} - {{ self.language }}',
    options: {
      disable_collapse: true,
      disable_edit_json: true,
      disable_properties: true,
    },
    properties: (function () {
      var p = uiSettings.properties;
      return { ...p };
    })(),
  },
};

const bandwidthSettings = {
  title: 'Bandwidth Settings',
  type: 'object',
  format: 'grid',
  options: {
    disable_edit_json: true,
    disable_properties: true,
  },
  properties: {
    maxBandWidth: {
      options: {
        grid_columns: 6,
      },
      title: 'Maximum bandwidth',
      type: 'integer',
      description: 'Maximum bandwidth. Default: 2000 kBit/s',
      default: 2000,
    },
    minSleepTime: {
      options: {
        grid_columns: 6,
      },
      title: 'Minimum sleep time',
      type: 'integer',
      description:
        'Time to sleep per request. Default: 1000 ms, 0 means disabled.',
      default: 1000,
    },
    slowNetworkBarrier: {
      options: {
        grid_columns: 6,
      },
      title: 'Slow network barrier',
      type: 'integer',
      description:
        "Will be detected as 'slow' network if network speed is below this value. Default: 800 kBit/s, 0 means disabled.",
      default: 800,
    },
    slowNetworkUsage: {
      options: {
        grid_columns: 6,
      },
      title: 'Slow network usage',
      type: 'number',
      description:
        'Maximum percent of usage on a slow network. Default: 0.25 (25%)',
      default: 0.25,
      minimum: 0.0,
      maximum: 1.0,
    },
    unusableNetworkBarrier: {
      options: {
        grid_columns: 6,
      },
      title: 'Unusable network barrier',
      type: 'integer',
      description:
        "Will be detected as 'unusable' network if network speed is below this value. Default: 200 kBit/s, 0 means disabled.",
      default: 200,
    },
    unusableWaitTime: {
      options: {
        grid_columns: 6,
      },
      title: 'Unusable wait time',
      type: 'integer',
      description:
        'Time to sleep in an unusable network. Default: 900 seconds (15 minutes)',
      default: 900,
    },
    useMeteredConnection: {
      options: {
        grid_columns: 6,
      },
      title: 'Use metered connection',
      type: 'boolean',
      description: 'If to use network connections which are marked as metered',
      format: 'checkbox',
      default: false,
    },
  },
};

const logLevel = {
  title: 'Log level',
  description: '',
  type: 'string',
  required: true,
  options: {
    enum_titles: ['Error', 'Info', 'Debug'],
  },
  enum: ['ll_ERROR', 'll_INFO', 'll_DEBUG'],
};

const optionalLogLevel = {
  title: 'Log level',
  description:
    "Usually don't set a loglevel per module - use the global logLevel instead.",
  type: 'string',
  required: true,
  options: {
    enum_titles: ['Use global value', 'Error', 'Info', 'Debug'],
  },
  enum: ['', 'll_ERROR', 'll_INFO', 'll_DEBUG'],
};

const config = {
  title: 'Config',
  type: 'object',
  options: {
    disable_collapse: true,
    disable_edit_json: true,
    disable_properties: true,
  },
  properties: {
    logLevel: logLevel,
    moduleLoopDelay: {
      title: 'Module loop delay',
      type: 'integer',
      description:
        'Sleeptime after looping through modules. Default: 1800000 ms (30 minutes)',
      default: 1800000,
    },
    resetCode: resetCode,
    powerMode: powerMode,
    bandwidthSettings: bandwidthSettings,
    uiSettings: (function () {
      var o = { ...uiSettings };
      var props = o.properties;
      o.properties = {
        ...{ finalizeUiMode: uiMode },
        ...props,
        ...{ languages: languageCatalogs },
      };
      return o;
    })(),
  },
};

const inventory = {
  title: 'Inventory',
  type: 'object',
  options: {
    disable_collapse: true,
    disable_edit_json: true,
    disable_properties: true,
  },
  properties: {
    wmiQueries: {
      title: 'Run WMI Queries',
      type: 'boolean',
      format: 'checkbox',
      description: '',
      default: true,
    },
    installerRegistry: {
      title: 'Read Installer registry',
      type: 'boolean',
      format: 'checkbox',
      description: '',
      default: true,
    },
    appCount: {
      title: 'Count application starts',
      type: 'boolean',
      format: 'checkbox',
      description: '',
      default: false,
    },
    enumDrives: {
      title: 'Enum drives',
      type: 'boolean',
      format: 'checkbox',
      description: '',
      default: true,
    },
    enumMailAccounts: {
      title: 'Enum mail accounts',
      type: 'boolean',
      format: 'checkbox',
      description: '',
      default: false,
    },
    bandwidthCheck: {
      title: 'Check network bandwidth',
      type: 'boolean',
      format: 'checkbox',
      description: '',
      default: true,
    },
    scriptBreakout: {
      title: 'Execute script breakout',
      type: 'boolean',
      format: 'checkbox',
      description: '',
      default: true,
    },
    logLevel: optionalLogLevel,
    resetCode: resetCode,
    powerMode: powerMode,
    queries: {
      title: 'WMI Queries',
      type: 'array',
      required: true,
      options: {
        collapsed: true,
      },
      items: {
        type: 'object',
        title: 'Query',
        headerTemplate: '{{ i1 }} - {{ self.key }}',
        options: {
          disable_collapse: true,
          disable_edit_json: true,
          disable_properties: true,
        },
        properties: {
          key: {
            title: 'Key',
            type: 'string',
          },
          path: {
            title: 'Path',
            type: 'string',
          },
          query: {
            title: 'Query',
            type: 'string',
          },
        },
      },
      default: [
        {
          key: 'ComputerSystem',
          path: 'ROOT\\CIMV2',
          query:
            'SELECT Name, Domain, PrimaryOwnerContact, PrimaryOwnerName, Manufacturer, NumberOfLogicalProcessors, NumberOfProcessors, TotalPhysicalMemory FROM Win32_ComputerSystem',
        },
        {
          key: 'SystemEnclosure',
          path: 'ROOT\\CIMV2',
          query:
            'SELECT Name, Caption, Description, Name, Manufacturer, Model, SerialNumber FROM Win32_SystemEnclosure',
        },
        {
          key: 'ComputerSystemProduct',
          path: 'ROOT\\CIMV2',
          query:
            'SELECT Name, Caption, Description, Name, Vendor, SKUNumber, Version, IdentifyingNumber FROM Win32_ComputerSystemProduct',
        },
        {
          key: 'OperatingSystem',
          path: 'ROOT\\CIMV2',
          query:
            'SELECT Caption, OSArchitecture, BuildNumber FROM Win32_OperatingSystem',
        },
        {
          key: 'DiskDrive',
          path: 'ROOT\\CIMV2',
          query:
            'SELECT Manufacturer, Model, Partitions, InterfaceType, SerialNumber, Size FROM Win32_DiskDrive',
        },
        {
          key: 'Processor',
          path: 'ROOT\\CIMV2',
          query:
            'SELECT Name, Manufacturer, CurrentClockSpeed, MaxClockSpeed, Family, NumberOfCores, ProcessorId FROM Win32_Processor',
        },
        {
          key: 'VideoController',
          path: 'ROOT\\CIMV2',
          query:
            'Select Name, Description, VideoProcessor, AdapterRAM, VideoModeDescription from Win32_VideoController',
        },
        {
          key: 'Monitor',
          path: 'ROOT\\CIMV2',
          query:
            'Select MonitorManufacturer, MonitorType, Bandwidth, ScreenHeight, ScreenWidth, PixelsPerXLogicalInch, PixelsPerYLogicalInch from Win32_DesktopMonitor',
        },
        {
          key: 'Printer',
          path: 'ROOT\\CIMV2',
          query:
            'Select Attributes, Caption, DeviceID, DriverName, Local, Name, PortName, PrintJobDataType, PrintProcessor, ServerName, ShareName, Shared, SystemName from Win32_Printer',
        },
        {
          key: 'MappdedDrives',
          path: 'ROOT\\CIMV2',
          query: 'SELECT Caption, ProviderName FROM Win32_MappedLogicalDisk',
        },
        {
          key: 'TPM',
          path: 'ROOT\\CIMV2',
          query:
            'Select Name, Present, Status from win32_pnpentity where (classguid="{d94ee5d8-d189-4994-83d2-f68d7d41b0e6}")',
        },
      ],
    },
  },
  required: [
    'wmiQueries',
    'installerRegistry',
    'appCount',
    'enumDrives',
    'enumMailAccounts',
    'bandwidthCheck',
    'scriptBreakout',
    'bandwidthCheck',
    'logLevel',
    'resetCode',
    'powerMode',
    'queries',
  ],
};

const uploadStore = {
  title: 'Upload store',
  type: 'object',
  options: {
    disable_collapse: true,
    disable_edit_json: true,
    disable_properties: true,
  },
  properties: {
    type: {
      title: 'Type',
      type: 'string',
      required: true,
      options: {
        enum_titles: ['File', 'OneDrive', 'Blob Storage'],
      },
      enum: ['file', 'serverGraph', 'serverBlob'],
      default: 'serverGraph',
    },
    rootPath: {
      title: 'Root path',
      type: 'string',
      required: true,
      description: 'Optional path on target store, e.g. "/realmigrator"',
      default: '',
    },
    diffFileSize: {
      title: 'Diff file size',
      type: 'integer',
      required: true,
      description:
        'Files exceeding this size will be uploaded blockwise. Default: 4194304 (4 MB)',
      default: 4194304,
    },
    diffBlockSize: {
      title: 'Diff block size',
      type: 'integer',
      required: true,
      description: 'Block size for blockwise upload. Default: 1048576 (1 MB)',
      default: 1048576,
    },
  },
};

const standardCatalogEntry = {
  type: 'object',
  title: 'standard',
  options: {
    disable_collapse: true,
    disable_edit_json: true,
    disable_properties: true,
  },
  additionalProperties: false,
  properties: {
    name: {
      title: 'Name',
      type: 'string',
    },
    path: {
      title: 'Path',
      type: 'string',
      description:
        "Either local path, NO trailing '/'. Or ':' + well known folder name, e.g. ':Documents'",
    },
    remotePath: {
      title: 'Remote path',
      type: 'string',
    },
    filter: {
      title: 'Filter',
      type: 'string',
    },
    blackList: {
      title: 'Blacklist',
      type: 'boolean',
      format: 'checkbox',
      description: '',
      default: false,
    },
    syncType: {
      title: 'Synchronization Type',
      type: 'string',
      description:
        'One-Way Sync: Copy remote storage and removes them if they are deleted in the source path.\nCopy copies changes, but does not remove deleted files.\nMove removes local file after the were copied to the remote storage.',
      options: {
        enum_titles: ['One-Way Sync', 'Copy', 'Move'],
      },
      enum: ['ow-sync', 'copy', 'move'],
      default: 'ow-sync',
    },
    uploadAgent: uploadAgent,
  },
  required: ['name', 'path', 'remotePath', 'filter', 'blackList', 'syncType'],
};

const standardPstCatalogEntry = {
  type: 'object',
  title: 'standard',
  options: {
    disable_collapse: true,
    disable_edit_json: true,
    disable_properties: true,
  },
  additionalProperties: false,
  properties: {
    name: {
      title: 'Name',
      type: 'string',
    },
    path: {
      title: 'Path',
      type: 'string',
      description:
        "Either local path, NO trailing '/'. Or ':' + well known folder name, e.g. ':Documents'",
    },
    remotePath: {
      title: 'Remote path',
      type: 'string',
    },
    uploadAgent: uploadAgent,
  },
  required: ['name', 'path', 'remotePath'],
};

const customCatalogEntry = {
  type: 'object',
  title: 'custom',
  options: {
    disable_collapse: true,
    disable_edit_json: true,
    disable_properties: true,
  },
  additionalProperties: false,
  properties: {
    name: {
      title: 'Name',
      type: 'string',
    },
    path: {
      title: 'Path',
      type: 'string',
    },
    remotePath: {
      title: 'Remote path',
      type: 'string',
    },
    filterFunction: {
      title: 'Filter function',
      type: 'string',
    },
    uploadAgent: uploadAgent,
  },
  required: ['name', 'path', 'remotePath', 'filterFunction'],
};

const catalogs = {
  type: 'array',
  title: 'Catalog',
  options: {
    disable_collapse: true,
    disable_edit_json: true,
    disable_properties: true,
  },
  items: {
    title: 'Catalog',
    headerTemplate: '{{ i1 }} - {{ self.name }}',
    oneOf: [standardCatalogEntry, customCatalogEntry],
  },
  default: [
    {
      name: 'Documents',
      path: '%userprofile%\\Documents',
      remotePath: '\\files from %COMPUTERNAME%\\Documents',
      filter: '*.docx|*.xlsx|*.ppt',
    },
    {
      name: 'Desktop',
      path: '%userprofile%\\Desktop',
      remotePath: '\\files from %COMPUTERNAME%\\Desktop',
      filter: '.*|~*|*.tmp|*.pst|*.ost|IconCache.db|desktop.ini',
      blackList: true,
    },
  ],
};

const pstCatalogs = {
  type: 'array',
  title: 'Catalog',
  options: {
    disable_collapse: true,
    disable_edit_json: true,
    disable_properties: true,
  },
  items: {
    title: 'Catalog',
    headerTemplate: '{{ i1 }} - {{ self.name }}',
    oneOf: [standardPstCatalogEntry, customCatalogEntry],
  },
  default: [
    {
      name: 'Profile',
      path: '%userprofile%',
      remotePath: '\\PST from %COMPUTERNAME%',
    },
  ],
};

const pathBlacklist = {
  title: 'Path blacklist',
  type: 'string',
  required: true,
  description:
    'Pipe-delimited list of paths that should be excluded from all catalogs.',
  default:
    '%userprofile%\\Documents\\Private|%userprofile%\\Desktop\\Private|?:\\$recycle.bin|?:\\recovery|c:\\$windows.~bt|c:\\windows|c:\\windows.old|c:\\onedrivetemp|c:\\perflogs|c:\\program files|c:\\program files (x86)|c:\\programdata|c:\\system volume information',
};

const file = {
  title: 'File',
  type: 'object',
  options: {
    disable_collapse: true,
    disable_edit_json: true,
    disable_properties: true,
  },
  properties: {
    type: {
      type: 'string',
      required: true,
      enum: ['file'],
      default: 'file',
      options: { hidden: true },
    },
    uploadStore: uploadStore,
    pathBlacklist: pathBlacklist,
    catalogs: catalogs,
    logLevel: optionalLogLevel,
    resetCode: resetCode,
    powerMode: powerMode,
  },
  required: [
    'type',
    'uploadStore',
    'pathBlacklist',
    'catalogs',
    'logLevel',
    'resetCode',
    'powerMode',
  ],
};

const pst = {
  title: 'Pst',
  type: 'object',
  options: {
    disable_collapse: true,
    disable_edit_json: true,
    disable_properties: true,
  },
  properties: {
    type: {
      type: 'string',
      required: true,
      enum: ['pst'],
      default: 'pst',
      options: { hidden: true },
    },
    uploadStore: uploadStore,
    pathBlacklist: pathBlacklist,
    catalogs: pstCatalogs,
    logLevel: optionalLogLevel,
    resetCode: resetCode,
    powerMode: powerMode,
  },
  required: [
    'type',
    'uploadStore',
    'pathBlacklist',
    'catalogs',
    'logLevel',
    'resetCode',
    'powerMode',
  ],
};

const onboardingEnabled = {
  type: 'object',
  title: 'enabled',
  options: {
    disable_collapse: true,
    disable_edit_json: true,
    disable_properties: true,
  },
  additionalProperties: false,
  properties: {
    name: {
      title: 'Username',
      description:
        "Please provide a user with 'User administrator' role assigned and MFA disabled.",
      type: 'string',
    },
    password: {
      title: 'Password',
      type: 'string',
      format: 'password',
    },
    ensureGroup: {
      title: 'Group membership',
      description:
        'Comma-separated list of Azure-AD groups. User will be put into each group.',
      type: 'string',
    },
  },
  required: ['name', 'password', 'ensureGroup'],
};

const serverPstEnabled = {
  type: 'object',
  title: 'enabled',
  options: {
    disable_collapse: true,
    disable_edit_json: true,
    disable_properties: true,
  },
  additionalProperties: false,
  properties: {
    name: {
      title: 'Username',
      description:
        "Please provide a user with 'Mailbox Import Export' role assigned and MFA disabled.",
      type: 'string',
    },
    password: {
      title: 'Password',
      type: 'string',
      format: 'password',
    },
    targetPath: {
      title: 'Target Path',
      description:
        "Specifies the Mailbox folder that PST files are be imported to. Use '/' to import into the mailbox root, leave blank to generate the path from the PST's source path.",
      type: 'string',
      default: '',
    },
    archive: {
      title: 'Archive',
      type: 'boolean',
      format: 'checkbox',
      description:
        "Enable this option to import the PSTs to the user's archive mailbox.",
      default: true,
    },
    multiGeo: {
      title: 'Multi-Geo capabilities',
      type: 'boolean',
      format: 'checkbox',
      description:
        'Enable this option if your Exchange Online tenant is configured for multi-geo support.',
      default: false,
    },
    remove: {
      title: 'Remove completed requests',
      type: 'boolean',
      format: 'checkbox',
      description:
        'Enable this option if you want to remove completed import requests.',
      default: true,
    },
  },
  required: ['name', 'password', 'targetPath', 'archive', 'multiGeo', 'remove'],
};

const serverTeamsEnabled = {
  type: 'object',
  title: 'enabled',
  options: {
    disable_collapse: true,
    disable_edit_json: true,
    disable_properties: true,
  },
  additionalProperties: false,
  properties: {
    url: {
      title: 'Teams URL',
      type: 'string',
      description:
        "Add the 'Incoming Webhook' App to your Teams channel. Paste the webhook url here.",
    },
    clientNew: {
      title: 'New client',
      type: 'boolean',
      format: 'checkbox',
      description: '',
      default: false,
    },
    clientFinish: {
      title: 'Client migration finished',
      type: 'boolean',
      format: 'checkbox',
      description: '',
      default: true,
    },
    pstImport: {
      title: 'PST import finished',
      type: 'boolean',
      format: 'checkbox',
      description: '',
      default: true,
    },
  },
  required: ['url', 'clientNew', 'clientFinish', 'pstImport'],
};

const optionDisabled = {
  type: 'object',
  title: 'disabled',
  options: {
    disable_collapse: true,
    disable_edit_json: true,
    disable_properties: true,
  },
  additionalProperties: false,
  properties: {},
  required: [],
};

const migrationScanEnabled = {
  type: 'object',
  title: 'enabled',
  options: {
    disable_collapse: true,
    disable_edit_json: true,
    disable_properties: true,
  },
  additionalProperties: false,
  properties: {
    scanDepth: {
      title: 'Folder scan depth',
      type: 'integer',
      required: true,
      description: 'Defines how many subfolders levels should be discovered',
      default: 1,
    },
  },
  required: ['scanDepth'],
};

const blobDestination = {
  type: 'object',
  title: 'Blob Store',
  options: {
    disable_collapse: true,
    disable_edit_json: true,
    disable_properties: true,
  },
  additionalProperties: false,
  properties: {
    type: {
      type: 'string',
      required: true,
      enum: ['serverBlob'],
      default: 'serverBlob',
      options: { hidden: true },
    },
    rootPath: {
      title: 'Root path',
      type: 'string',
      required: true,
      description:
        'Optional path on target blob store store, e.g. "/realmigrator"',
      default: '',
    },
    diffFileSize: {
      title: 'Diff file size',
      type: 'integer',
      required: true,
      description:
        'Files exceeding this size will be uploaded blockwise. Default: 4194304 (4 MB)',
      default: 4194304,
    },
    diffBlockSize: {
      title: 'Diff block size',
      type: 'integer',
      required: true,
      description: 'Block size for blockwise upload. Default: 1048576 (1 MB)',
      default: 1048576,
    },
  },
  required: ['type', 'rootPath', 'diffFileSize', 'diffBlockSize'],
};

const sharePointDestination = {
  type: 'object',
  title: 'Teams',
  options: {
    disable_collapse: true,
    disable_edit_json: true,
    disable_properties: true,
  },
  additionalProperties: false,
  properties: {
    type: {
      type: 'string',
      required: true,
      enum: ['serverSharepoint'],
      default: 'serverSharepoint',
      options: { hidden: true },
    },
    sharepointUrl: {
      title: 'Teams URL',
      type: 'string',
      description:
        'Please enter a teams channel url, e.g. "https://teams.microsoft.com/l/channel/..", or the teams group id.',
    },
    rootPath: {
      title: 'Root path',
      type: 'string',
      required: true,
      description:
        'Optional path in the target document library "/realmigrator"',
      default: '',
    },
  },
  required: ['type', 'sharepointUrl', 'rootPath'],
};

const oneDriveDestination = {
  type: 'object',
  title: 'OneDrive',
  options: {
    disable_collapse: true,
    disable_edit_json: true,
    disable_properties: true,
  },
  additionalProperties: false,
  properties: {
    type: {
      type: 'string',
      required: true,
      enum: ['serverGraph'],
      default: 'serverGraph',
      options: { hidden: true },
    },
    oneDriveUpn: {
      title: 'User UPN',
      type: 'string',
      description:
        "UPN of user's OneDrive. You can leave this field empty and use the GetUPNforPath() script breakout for each direct subfolder (home share mode).",
      default: '',
    },
    rootPath: {
      title: 'Root path',
      type: 'string',
      required: true,
      description: 'Optional path in the target OneDrive "/realmigrator"',
      default: '',
    },
  },
  required: ['type', 'oneDriveUpn', 'rootPath'],
};

const serverMigrationCatalogs = {
  title: 'Migration Catalogs',
  type: 'array',
  options: {
    disable_collapse: true,
    disable_edit_json: true,
    disable_properties: true,
  },
  items: {
    title: 'Catalog',
    headerTemplate: 'Catalog - {{ self.name }}',
    options: {
      disable_collapse: true,
      disable_edit_json: true,
      disable_properties: true,
    },
    properties: {
      name: {
        title: 'Name',
        type: 'string',
      },
      source: {
        title: 'Source',
        type: 'object',
        options: {
          disable_collapse: true,
          disable_edit_json: true,
          disable_properties: true,
        },
        properties: {
          type: {
            type: 'string',
            required: true,
            enum: ['local'],
            default: 'local',
            options: { hidden: true },
          },
          paths: {
            type: 'array',
            options: {
              disable_collapse: true,
              disable_edit_json: true,
              disable_properties: true,
            },
            items: {
              title: 'Path',
              headerTemplate: '{{ self.path }}',
              options: {
                disable_collapse: true,
                disable_edit_json: true,
                disable_properties: true,
              },
              properties: {
                source: {
                  type: 'string',
                  required: true,
                  enum: ['manual'],
                  default: 'manual',
                  options: { hidden: true },
                },
                path: {
                  title: 'Path',
                  type: 'string',
                },
                rules: {
                  type: 'object',
                  default: {},
                  options: { hidden: true },
                },
              },
              required: ['source', 'path', 'rules'],
            },
          },
        },
        required: ['type', 'paths'],
      },
      destination: {
        title: 'Destination',
        oneOf: [blobDestination, sharePointDestination, oneDriveDestination],
      },
      filter: {
        title: 'File selector',
        description:
          'Pipe-delimited list of files that should be in- or excluded from being copied.',
        type: 'string',
        default:
          '.*|~*|*.tmp|pagefile.sys|hiberfile.sys|IconCache.db|desktop.ini',
      },
      blackList: {
        title: 'Blacklist',
        type: 'boolean',
        format: 'checkbox',
        description:
          'If enabled, the file selector list is treated as black list',
        default: true,
      },
    },
  },
};

const servermigration = {
  title: 'Server Migration',
  type: 'object',
  options: {
    disable_collapse: true,
    disable_edit_json: true,
    disable_properties: true,
  },
  properties: {
    type: {
      type: 'string',
      required: true,
      enum: ['servermigration'],
      default: 'servermigration',
      options: { hidden: true },
    },
    serviceOnly: {
      title: 'Service only',
      type: 'boolean',
      format: 'checkbox',
      description:
        'If enabled, the server migration module will be executed only on clients running the realmigrator service executable.',
      default: true,
    },
    stageOnedrive: {
      title: 'Stage OneDrive Data',
      type: 'boolean',
      format: 'checkbox',
      description: 'If enabled, files will be staged to Blob storage.',
      default: false,
    },
    stageSharepoint: {
      title: 'Stage Teams Data',
      type: 'boolean',
      format: 'checkbox',
      description:
        'The server module will copy the files to their destination after the client finalized.',
      default: false,
    },
    createTeams: {
      title: 'Allow Teams Creation',
      type: 'boolean',
      format: 'checkbox',
      description:
        'If enabled, Teams can be created during the creation of catalogs.',
      default: false,
    },
    finalizeCatalogs: {
      title: 'Finalize Catalogs',
      type: 'boolean',
      format: 'checkbox',
      description: 'Allow administrators to finazlize distinct catalogs.',
      default: false,
    },
    logLevel: optionalLogLevel,
    resetCode: resetCode,
    pathBlacklist: pathBlacklist,
    sources: {
      title: 'Discover Sources',
      description:
        'Discover Sources are scanned, discovered directories can be selected for migration.',
      type: 'object',
      options: {
        disable_collapse: true,
        disable_edit_json: true,
        disable_properties: true,
      },
      properties: {
        localDrives: {
          title: 'Local drives',
          description: 'If enabled, all local drives are discovered.',
          oneOf: [migrationScanEnabled, optionDisabled],
          default: {},
        },
        localShares: {
          title: 'Local shares',
          description: 'If enabled, all local shares are discovered.',
          oneOf: [migrationScanEnabled, optionDisabled],
          default: {},
        },
        paths: {
          title: 'Path list',
          description: 'List of local file paths to discover.',
          required: true,
          type: 'array',
          options: {
            disable_collapse: true,
            disable_edit_json: true,
            disable_properties: true,
          },
          items: {
            title: 'Path',
            headerTemplate: '{{ self.path }}',
            options: {
              disable_collapse: true,
              disable_edit_json: true,
              disable_properties: true,
            },
            properties: {
              path: {
                title: 'Path',
                type: 'string',
              },
              scanDepth: {
                title: 'Folder scan depth',
                type: 'integer',
                required: true,
                description:
                  'Defines how many subfolders levels should be discovered',
                default: 1,
              },
            },
          },
        },
      },
      required: ['localDrives', 'localShares', 'paths'],
    },
    catalogs: serverMigrationCatalogs,
  },
  required: [
    'type',
    'serviceOnly',
    'stageOnedrive',
    'stageSharepoint',
    'createTeams',
    'finalizeCatalogs',
    'pathBlacklist',
    'sources',
  ],
};

const server = {
  title: 'Server',
  type: 'object',
  options: {
    disable_collapse: true,
    disable_edit_json: true,
    disable_properties: true,
  },
  properties: {
    logLevel: logLevel,
    onboarding: {
      title: 'User Onboarding',
      oneOf: [onboardingEnabled, optionDisabled],
      default: {},
    },
    pstImport: {
      title: 'PST Import',
      oneOf: [serverPstEnabled, optionDisabled],
      default: {},
    },
    teams: {
      title: 'Teams notification',
      oneOf: [serverTeamsEnabled, optionDisabled],
      default: {},
    },
  },
  required: ['logLevel', 'onboarding', 'pstImport', 'teams'],
};

const upload_agent = {
  title: 'Upload Agent',
  type: 'object',
  options: {
    disable_collapse: true,
    disable_edit_json: true,
    disable_properties: true,
  },
  properties: {
    logLevel: logLevel,
    groupsFilter: {
      title: 'Groups Filter',
      description:
        'A comma separated list of groups that will be processed by the upload agent. Wildcards are supported.',
      type: 'string',
    },
    bandwidthSettings: bandwidthSettings,
  },
  required: ['logLevel', 'groupsFilter', 'bandwidthSettings'],
};

export default {
  config,
  inventory,
  file,
  pst,
  server,
  servermigration,
  upload_agent,
};
