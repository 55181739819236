// @flow
import React from 'react';
import { ProgressIndicator } from 'office-ui-fabric-react';
import { DateTime } from 'luxon';
import ReactJson from 'react-json-view';
import API from './API.js';

type ModuleDataProps = {
	type: string,
	userId: string
};
type ModuleDataState = {
	loaded: boolean,
	date: ?string,
	data: ?Blob,
};

export class ModuleData extends React.Component<ModuleDataProps, ModuleDataState> {
	state = {
		loaded: false,
		date: null,
		data: null
	};
	componentDidMount() {
		const { type, userId, data, date } = this.props;

		console.log(data);
		if (data) {
			this.setState({ loaded: true, date: date ? date : new Date(), data: data });
			return;
		}

		API.moduleData(type, userId).then((response) => {
			const date = DateTime.fromHTTP(response.headers ? response.headers['last-modified'] : '');
			this.setState({ loaded: true, date: date, data: response.data });
		}).catch(() => {
			this.setState({ loaded: true });
		});
	}
	render() {
		const { loaded, date, data } = this.state;

		if(loaded) {
			return (
				<>
					<p>Last modified: {date ? date.toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS) : 'Never'}</p>
					<ReactJson name={false} collapsed={3} displayObjectSize={false} displayDataTypes={false} enableClipboard={false} src={data ? data : {} }/>
				</>
			);
		}

		return (
			<ProgressIndicator label='Loading...'/>
		);
	}
}
