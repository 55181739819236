import React from 'react';
import { DateTime } from 'luxon';
import { DetailsList, SelectionMode, IconButton } from 'office-ui-fabric-react';
import { Permissions } from './AddTokenDialog';

const permissionToString = (p: Permissions): string => {
  switch(p) {
    case Permissions.ViewOnlyAdmin: return "View only"
    case Permissions.Admin: return "Admin"
    case Permissions.SuperAdmin: return "Superadmin"
  }
  return "Unknown"
}

export type Token = {
  ObjectID: string;
  Permission: Permissions;
  Name: string;
  Expires: string;
};

export type TokenListProps = {
  tokens: Array<Token>;
  onRevoke: (id: string) => Promise<void>;
};

export const TokenList = ({ tokens, onRevoke }: TokenListProps) => {
  const columns = [
    {
      key: 'colName',
      name: 'Name',
      fieldName: 'Name',
      isRowHeader: true,
      isResizable: true,
      data: 'string',
      minWidth: 250,
    },
    {
      key: 'colPermission',
      name: 'Permission',
      iconName: 'Permissions',
      isRowHeader: false,
      isResizable: true,
      minWidth: 140,
      onRender: (item: Token) => {

        return (
          <span>
            {permissionToString(item.Permission)}
          </span>
        );
      },
    },
    {
      key: 'colExpires',
      name: 'Expires',
      iconName: 'DateTime',
      isRowHeader: false,
      isResizable: true,
      minWidth: 140,
      onRender: (item: Token) => {
        const isExpired = item.Expires ? DateTime.fromISO(item.Expires).diffNow('days').days <= 0 : false;
        const expiry = item.Expires ? DateTime.fromISO(item.Expires).toFormat('yyyy-MM-dd TT') : 'Never';
        return (
          <span style={ { color: isExpired ? '#d13438' : undefined }}>
            {expiry}
          </span>
        );
      },
    },
    {
      key: 'colAction',
      name: '',
      isResizable: false,
      isRowHeader: false,
      minWidth: 10,
      width: 10,
      onRender: (item: Token) => (
        <IconButton
          iconProps={{ iconName: 'Trash' }}
          title='Revoke'
          ariaLabel='Revoke'
          onClick={() => {
            onRevoke(item.ObjectID);
          }}
        />
      ),
    },
  ];

  return (
    <DetailsList
      compact={true}
      columns={columns}
      items={tokens}
      isHeaderVisible={true}
      selectionMode={SelectionMode.none}
    />
  );
};
